import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

@Component({
  selector: 'app-create-roles-header',
  templateUrl: './create-roles-header.component.html',
  styleUrls: ['./create-roles-header.component.scss']
})
export class CreateRolesHeaderComponent {

  constructor(private router: Router) { }

 /**
   * goToRolesView method
   * go back to the roles management view
   * @return {void}
   */
  public goToRolesView(): void {
    this.router.navigateByUrl('manage/roles');
  }

}
