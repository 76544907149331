import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UserLocationFilter } from 'src/app/models/user-location-filter.model';

@Component({
  selector: 'app-employees-shared-list-header',
  templateUrl: './employees-shared-list-header.component.html',
  styleUrls: ['./employees-shared-list-header.component.scss']
})
export class EmployeesSharedListHeaderComponent {

  @Input() inCheckIn: boolean;

  @Output() locationChange: EventEmitter<UserLocationFilter> = new EventEmitter<UserLocationFilter>();
  @Output() search: EventEmitter<HTMLInputElement> = new EventEmitter<HTMLInputElement>();

  public enteringInput: boolean = false;

  constructor() { }

  /**
   * filterUsers method
   * @description Emit an event to its parent component
   * when changes the value in location selector
   * @param {UserLocationFilter} filter - new filter
   * to apply in employees list
   * @returns {void} void
   */
  public filterUsers(filter: UserLocationFilter) {
    this.locationChange.emit(filter);
  }

   /**
   * onInput method
   * send parameter input to employees-shared-list component
   * @param {HTMLInputElement} input input element
   * @return {void}
   */
  public onInput(input: HTMLInputElement): void {
    this.search.emit(input);
  }
}
