import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { SignUp } from 'src/app/models/sign-up/sign-up.model';
import { Response } from 'src/app/models/sign-up/generic-response.model'
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ResponseSignUp } from 'src/app/models/sign-up/response-sign-up.model';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  constructor(private http: HttpClient, private route: Router) { }
  
  /**
   * createUser method
   * services that a user registers
   * @param body object contain necesari data for sign up a user
   * @return {ResponseSignUp} model create for this service
   */
  public createUser(body: SignUp): Observable<ResponseSignUp> {
    return this.http.post<ResponseSignUp>(`${ environment.apiUrl }/users/signUp`, body);
  }

  /**
   * getSignUpGeneric method
   * service that consumes the enpoints of fields,
   * locations and seniority
   * @param route endpoint especific route
   * @return {Observable<Respones>} generic model created for response of equal tables
   */
  public getSignUpGeneric(route): Observable<Response> {
    return this.http.get<Response>(`${ environment.apiUrl }${ route }`)
      .pipe( 
        catchError((err) => {
          return of(err);
        }
      )
    );
  }

  /**
   * validateEmail method
   * @description verify than an email is registered to create an account
   * @param {string }email emails received from the user
   * @return {Observable<Response>} generic model created for response of equal tables
   */
  public validateEmail(email: string): Observable<Response> {
    const body: object = { "email":email };
    return this
      .http
      .post<Response>(`${ environment.apiUrl }/users/validate`, body)
      .pipe(
        catchError((err) => {
        return of(err);
      })
    );
  }
}
