import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DateBeforeMinError } from 'src/app/models/errors/date-before-min-error.model';

import moment, { Moment } from 'moment';

/**
 * Checks if the date is before the minimum required
 * @param {AbstractControl} control form control to be validated
 * @returns {object} object with the key of the error name and a value
 * of true if it's valid or null if not
 */
export function dateBeforeMin(minDate: Moment, precision = null): ValidatorFn {
  return (control: AbstractControl): DateBeforeMinError => {
    const date = control.value.split('/');
    const controlMoment = moment([+date[1], (+date[0] - 1)]);
    
    // if the precision (year, month, day, etc) is provided
    if (precision) {
      if (controlMoment.isBefore(minDate, precision)) {
        return {
          dateBeforeMin: true,
        };
      }
    } else if (controlMoment.isBefore(minDate)) {
      return {
        dateBeforeMin: true,
      };
    }

    return null;
  }
}
