import { Directive, Input, ElementRef, HostListener, OnInit, HostBinding } from '@angular/core';
import { imageDefaults } from 'src/app/config/image-defaults';

@Directive({
  selector: 'img[appImageOnError]'
})
export class ImageOnErrorDirective implements OnInit {

  // This Input must be 'user' or 'project'
  @Input() defaultImage: string;

  constructor(private elementRef: ElementRef) { }

  private image: HTMLImageElement = <HTMLImageElement>this.elementRef.nativeElement;

  ngOnInit() {
    this.image.classList.add('hide-image');
  }

  @HostListener('error')
  loadDefaultImage() {
    this.image.src = imageDefaults[this.defaultImage];
  }

  @HostListener('load')
  imageLoaded() {
    this.image.classList.remove('hide-image');
    this.image.classList.add('show-image');
  }
}
