import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit, AfterViewInit {

  @ViewChild('textArea', { static: true }) textAreaElement: ElementRef;

  @Input() id: string;
  @Input() name: string;
  @Input() labelText: string;
  @Input() parentForm: FormGroup;
  @Input() control: FormControl;
  @Input() validators: ValidatorFn[];
  @Input() errors;
  @Output() onBlur = new EventEmitter;
  @Output() whenInput = new EventEmitter;
  @Output() blurred: EventEmitter<string> = new EventEmitter();

  public errorKeys: string[];
  public errorMessages: string[];
  public displayError: boolean = false;

  constructor() { }

  ngOnInit() {
    // set validators
    this.setValidators();
  }

  ngAfterViewInit() {
    if (!this.textAreaElement.nativeElement.value.trim()) {
      this.textAreaElement.nativeElement.focus();
      this.textAreaElement.nativeElement.value = '';
    }
  }

  /**
   * setValidators method
   * sets the validators for the form control
   * of this component and updates it to be
   * retroactive, emitting an event at the same
   * time
   * @returns {void}
   */
  public setValidators(): void {
    this.control.setValidators(this.validators);

    // make the validation retroactive
    this.control.updateValueAndValidity();
  }

  /**
   * checkForErrors method
   * set displayError flag to true if there are errors
   * @param {boolean} isValidatorUpdate is true if there are first 
   * changes in validators
   * @returns {void}
   */
  public checkForErrors(isValidatorUpdate: boolean): void {
    setTimeout(() => {
      if (!isValidatorUpdate){
        this.onBlur.emit('blur');
      }

      if (this.control.errors) {
        this.displayError = true;
      } else {
        this.displayError = false;
      }
    }, 150);
  }

  /**
   * trimElement method
   * remove spaces from the beginning and end
   * @param {boolean} element input element of HTML
   * @return {void}
   * */
  public trimElement(element: HTMLInputElement): void {
    this.textAreaElement.nativeElement.focus = false;
    element.value = element.value.trim();
    this.control.setValue(element.value);
  }

  /**
   * onInput method
   * send event emiter to father
   * @return {void}
   * */
  public onInput(): void {
    this.whenInput.emit('input');
  }
}
