import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GetCalendarResponse } from 'src/app/models/getCalendarResponse.model';
import { EmptyResponse } from 'src/app/models/responses/empty-response.model';
import { environment } from 'src/environments/environment';
import { UserLocationFilter } from 'src/app/models/user-location-filter.model';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(private http: HttpClient) { }

  /**
   * getCalendarStatus method
   * @description Use the /clients endpoint to get the calendar dates
   * according with the given filters
   * @param {string} startDate - Start Date of the Date Range to find
   * @param {string} endDate - End Date of the Date Range to find
   * @param {UserLocationFilter} locationFilter (optional) - Object that
   * contains if you want filter by region or location and its respective id.
   * If it is not exists you are finding World Wide dates
   * @returns {Observable<GetCalendarResponse>} Observable<GetCalendarResponse>
   */
  public getCalendarStatus(
    startDate: string,
    endDate: string,
    locationFilter?: UserLocationFilter,
  ): Observable<GetCalendarResponse>{
    let queryParams = null;

    if (locationFilter) {
      queryParams = {
        params: new HttpParams()
          .set('startDate', startDate)
          .set('endDate', endDate)
          .set(locationFilter.type, locationFilter.id.toString())
      };
    } else {
      queryParams = {
        params: new HttpParams()
          .set('startDate', startDate)
          .set('endDate', endDate)
      };
    }

    return this.http.get<GetCalendarResponse>(
      `${environment.apiUrl}/calendar`,
      queryParams
    ).pipe(
      catchError((error) => {
        return of (error);
      }),
    );
  }

  /**
   * updateCalendarStatus method
   * @description Update the status at a specific date and segment, do the
   * request to backend and returns the observable of it
   * @param {string} date - Date to update
   * @param {string} status - New status
   * @param {string} segment - Segment to update
   * @param {number | string} segmentId -Id of segment to update
   * @returns {observable<EmptyResponse>} Backend response observable
   */
  public updateCalendarStatus(
    date,
    status,
    segment,
    segmentId,
  ): Observable<EmptyResponse> {
    let queryParams = null;

    if (segment && segmentId) {
      queryParams = {
        params: new HttpParams()
          .set('date', date)
          .set('status', status)
          .set('segment', segment)
          .set('segmentId', segmentId)
      };
    } else {
      queryParams = {
        params: new HttpParams()
          .set('date', date)
          .set('status', status)
      };
    }
    
    return this.http.put<EmptyResponse>(
      `${environment.apiUrl}/calendar`,
      {},
      queryParams,
    ).pipe(
      catchError((error) => {
        return of (error);
      }),
    );
  }
}
