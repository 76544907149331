import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform(imagePath: string): string {
    return `${environment.s3BucketUrl}${imagePath}`;
  }

}
