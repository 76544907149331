import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ClientTeamMember } from 'src/app/models/client-team-member.model';
import { EditClientTeamMemberResponse } from 'src/app/models/edit-client-team-member-response.model'


@Injectable({
  providedIn: 'root'
})
export class ClientTeamMemberService {

  constructor(private http: HttpClient) {}

  /**
   * editClientTeamMemberProject method
   * return an observable with the updated data on the database
   * @param clientTeamMember contain the data necesary to create the 
   * new team member
   * @param {number} id the id of the project wich the client team member belongs
   * @param {number} memberId id of the client team member to update
   * @returns {Observable} add Team Member Response
   */
  public editClientTeamMemberProject(clientTeamMember: ClientTeamMember, id: number, memberId: number)
  : Observable<EditClientTeamMemberResponse> {
    return this.http.put<EditClientTeamMemberResponse>(
      `${environment.apiUrl}/projects/${id}/client-member/${memberId}`,
      clientTeamMember
      ).pipe(
        catchError((error) => {
          return of (error);
        })
      );
  }

}