import { Component, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-databases-company',
  templateUrl: './databases-company.component.html',
  styleUrls: ['./databases-company.component.scss']
})
export class DatabasesCompanyComponent {

  public buttonsDisabled: boolean = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }
  
  /**
   * disableButtons method
   * @description Change the value of buttonsDisabled variable
   * to disable/enable the add buttons
   * @param {boolean} value - Value received from the child component
   * @returns {void} void
   */
  public disableButtons(value: boolean): void {
    this.buttonsDisabled = value;
    this.changeDetectorRef.detectChanges();
  }
}
