import { Pipe, PipeTransform } from '@angular/core';
import { Client } from 'src/app/models/databases/clients.model';

@Pipe({
  name: 'filterClients'
})
export class FilterClientsPipe implements PipeTransform {

  transform(clients: Client[], filter: string): Client[] {
    let filtered: Client[] = [];

    if (!filter) {
      return [];
    }

    filtered = clients.filter(clients => {

      const clientsname = clients.name;

      if (
        clientsname
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .search(
          filter
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, "")
        ) < 0
      ) {
        return false;
      }

      return true;
    });

    // sort the results alphabetically
    filtered.sort((firstClient: Client, secondClient: Client): number => {
      if (
        firstClient
          .name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, "") <
        secondClient
        .name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, "")
      ) {
        return -1;
      }

      return 1;
    });

    return filtered;
  }

}
