import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Pipe({
  name: 'updateImage'
})
export class UpdateImagePipe implements PipeTransform {

  transform(imagePath: string | ArrayBuffer | Observable<string>): string {
    return `${environment.s3BucketUrl}${imagePath}?version=${Date.now()}`;
  }

}
