import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-roles-header',
  templateUrl: './edit-roles-header.component.html',
  styleUrls: ['./edit-roles-header.component.scss']
})
export class EditRolesHeaderComponent {

  @Input() roleName: string;
  constructor(private router: Router) { }

  /**
   * goBack method
   * allows to return to the previous view
   *
   * @returns {void} void
   */
  public goBack(): void {
    this.router.navigateByUrl('manage/roles');
  }

}
