import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, AbstractControl } from '@angular/forms';
import { alphaNumberNoEmojisRegex } from 'src/app/config/regex';
import { EnhancedFormControl } from 'src/app/models/enhanced-form-control.model';
import { UserExperienceService } from 'src/app/services/user-experience.service';

@Component({
  selector: 'app-array-editor',
  templateUrl: './array-editor.component.html',
  styleUrls: ['./array-editor.component.scss']
})
export class ArrayEditorComponent implements OnInit {

  @ViewChild('inputs', { static: false }) inputs: ElementRef;
  @ViewChild('editor', { static: false }) editor: ElementRef;

  @Input() required: boolean = true;
  @Input() arrayName: string;
  @Input() parentForm: FormGroup;
  @Input() arrayControls: EnhancedFormControl[];
  @Input() labelText: string;
  @Input() arrayErrors;
  @Input() errors;

  @Output() addNewInput: EventEmitter<string> = new EventEmitter<string>();
  @Output() removeNewInput: EventEmitter<number> = new EventEmitter<number>();
  @Output() editorClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  public displayArrayError: boolean = false;
  public formArray: AbstractControl;
  private counter: number = 1;
  public editorRef: any;
  private click: boolean = false;

  constructor(private userExperience: UserExperienceService) { }

  ngOnInit() {
    this.formArray = this.parentForm.get(this.arrayName);
  }

  /**
   * checkForErrors method
   * set displayError flag to true if there are errors
   * @param {EnhancedFormControl} control form control to test
   * @returns {void}
   */
  public checkForErrors(control: EnhancedFormControl): void {
    this.displayArrayError = this.formArray.errors ? true : false;

    control.displayError = control.errors ? true : false;
  }

  /**
   * trimElement method
   * trims the value of the control
   * @param {EnhancedFormControl} control form control to trim
   * @param {number} index array index for the corresponding control
   * @returns {void}
   */
  public trimElement(control: EnhancedFormControl, index: number): void {
    // trims value if control has one
    if (control.value) {
      const controlValue = control.value.replace(/&nbsp;(?!=<\/[a-z]>)/gm, '');
      control.setValue(controlValue);
    }
    this.checkForErrors(control);
  }

  /**
   * manageEditors method
   * emits event to parent form to
   * add a new input when the user starts writing
   * or to remove it if the user deletes its content
   * @param {string} event current value of control
   * @param {EnhancedFormControl} control form control to check if has value
   * @param {number} index index in the array of current form control
   * @returns {void}
   */
  public manageEditors(event:string, control: EnhancedFormControl, index: number): void {
    let contentValues: string[] = this.userExperience.arrayWithoutHtmlTags(this.formArray.value)
    /**
     * add a new input when the user starts
     * typing in the LAST input in the array
     */
    event = this.userExperience.deleteHtmlTags(event);
    
    if (
      event.length > 0
      && index === (this.arrayControls.length - 1)
      && new Set (contentValues).size === this.arrayControls.length
    ) {
      this.addNewInput.emit('addInput');
    }
    // if the control has no value, remove it and is not the last
    if (index !== (this.arrayControls.length - 1)) {
      this.removeEditor(event, index);
    }
  }

  /**
   * removeInput method
   * if the user deleted the input, we can delete
   * the control, only if the array has still one more
   * element, or it would be left without
   * inputs
   * @param {string} value value from form control to remove
   * @param {number} index index of the form control to remove
   * @return {void}
   */
  private removeEditor(value: string, index: number): void {
    if (!value) {
      this.counter += 1

      if (this.counter %2 === 0) {
        if (index === (this.arrayControls.length - 2)) {
          this.counter += 1; //
        }
        this.removeNewInput.emit(index);
        this.editorRef.focus();
        this.setFocusOnEmptyInput();
      }

      if (this.required) {
        this.setAsRequired();
      }
    }
  }

  /**
   * setFocusOnEmptyInput method
   * once an input is removed, check for
   * empty inputs and focus on it
   * @returns {void}
   */
  private setFocusOnEmptyInput(): void {
    const inputChildren = this.inputs.nativeElement.querySelectorAll('.editor');
  }

  /**
   * setAsRequired method
   * if only one input is left,
   * set that one as required
   * @return {void}
   */
  private setAsRequired(): void {
    if (this.arrayControls.length === 1) {
      const control = this.arrayControls[0];
      control.setValidators([
        Validators.required,
        Validators.pattern(alphaNumberNoEmojisRegex),
      ]);
      control.updateValueAndValidity();
    }
  }

  /**
   * getEditorReference
   * receives an event from the editor emitters to get the editor reference
   * @param event event from the editor component
   * @returns {void} void
   */
  public getEditorReference(event: any): void {
    this.editorRef = event.editor;
  }

  /**
   * emitClickEvent
   * emits a boolean when detects a click
   * @returns {void} void
   */
  public emitClickEvent(): void {
    this.click = !this.click
    this.editorClicked.emit(this.click);
  }
}

