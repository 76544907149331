import { Injectable } from '@angular/core';

@Injectable({
  'providedIn': 'root',
})
export class PermissionsService {
  /**
   * userHasPermission method
   * determines if the user has the permission to access of view
   * a part of the application
   * @param {string[]} permissions array of permissions to allow the user
   * @returns {boolean}
   */
  public userHasPermission(permissions: string[]): boolean {
    try {
      // get the user permissions
    const userPermissions = JSON.parse(localStorage.getItem('permissions'));

    if (
      userPermissions
      && userPermissions.length
      && Array.isArray(userPermissions)
    ) {
      return userPermissions.some(userPermission => permissions.includes(userPermission.name));
    }

    return false;
    } catch (error) {
      return false
    }
  }
}
