import { Component, OnInit } from '@angular/core';
import { Tab } from 'src/app/models/shared/tab.model';
import { DatabasesService } from 'src/app/services/databases.service';
import { DatabasesRequest } from 'src/app/models/databases-request.model';

@Component({
  selector: 'app-databases-general',
  templateUrl: './databases-general.component.html',
  styleUrls: ['./databases-general.component.scss']
})
export class DatabasesGeneralComponent implements OnInit {

  public contentToDisplay: number = 1;
  public tabs: Tab[] = [];
  
  constructor(private databasesService: DatabasesService) { }
  
  /**
   * ngOnInit hook
   * set tabs content
   */
  ngOnInit() {
    this.tabs = [
      {
        id: 1,
        title: 'Company',
        icon: 'C',
        active: true,
        show: true,
      },
      {
        id: 2,
        title: 'Employees',
        icon: 'D',
        active: false,
        show: false,
      }
    ];
  }

  /**
   * displaySelectedTab method
   * sets the tab to be displayed
   * @param {number} tab - tab to be displayed
   * @return {void}
   */
  public displaySelectedTab(tab: number): void {
    this.contentToDisplay = tab;
  }
  /**
   * sendRequest method
   * @description Send a request to a certain endpoint, with certain method
   * and a certain body (if is required).
   * @param {DatabasesRequest} request - Object that contains
   * the method, endpoint and body to send the request
   * @returns {void} void 
   */
  public sendRequest(request: DatabasesRequest): void {
    this.databasesService.sendRequest(request).subscribe((result) => {

    });
  }
}
