import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employees-list',
  templateUrl: './employees-list.component.html',
  styleUrls: ['./employees-list.component.scss']
})
export class EmployeesListComponent {
  public textButton: string = 'Edit';

  constructor(private router: Router) {}

  /**
   * goToEditEmployee method
   * Listen the goToEmployee EventEmitter of the Employees List
   * Shared Component and navigates to the Edit Employee View
   * with the id received
   * @param {number} employeeId - Employee to be editted
   * @returns {void} 
   */
  public goToEditEmployee(employeeId: number): void {
    this.router.navigateByUrl(`/manage/employees/${employeeId}/edit`);
  }
}
