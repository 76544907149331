const rolesMessages = {
  userAndMoreThanOneInRole: (usersNumber) =>
    `You and ${usersNumber - 1} more people have this role assigned`,
  userAndOneMoreInRole: `You and other person have this role assigned`,
  moreThanOneInRole: (usersNumber) => 
    `${usersNumber} people have this role assigned`,
  userUniqueInRole: 'You are the only person with this role assigned',
  onlyOneInRole: 'Only one person has this role assigned',
  noPeopleInRole: 'No people assigned to this role'
};

export { rolesMessages };