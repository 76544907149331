import { Component } from '@angular/core';

@Component({
  selector: 'app-my-projects-details',
  templateUrl: './my-projects-details.component.html',
  styleUrls: ['./my-projects-details.component.scss']
})
export class MyProjectsDetailsComponent {

  constructor() { }

}
