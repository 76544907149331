import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { Error } from 'src/app/models/errors/error.model';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent implements OnInit {

  @ViewChild('inputField', { static: false }) inputField: ElementRef;

  @Input() id: string;
  @Input() name: string;
  @Input() labelText: string;
  @Input() parentForm: FormGroup;
  @Input() control: FormControl;
  @Input() validators: ValidatorFn[];
  @Input() errors: Error;
  @Input() shouldDisableSpace: boolean = false;
  @Input() relativeErrors: boolean = false;
  @Output() emitErrors: EventEmitter<object> = new EventEmitter<object>();

  public errorKeys: string[];
  public errorMessages: string[];
  public displayError: boolean = false;

  public type: string = 'password';
  public show: boolean = false;
  public triggeredEye: boolean = false;
  public invisibleIcon: string = 'assets/images/icons/visibility-off.svg';
  public visibleIcon: string = 'assets/images/icons/visibility.svg';
  public iconPath: string;

  constructor() { }

  /**
   * ngOnInit hook
   * set the validators at the start of the
   * component
   * @return {void}
   */
  ngOnInit(): void {
    this.iconPath = this.invisibleIcon;

    // set validators
    this.setValidators();
  }

  /**
   * setValidators method
   * sets the validators for the form control
   * of this component and updates it to be
   * retroactive, emitting an event at the same
   * time
   * @returns {void}
   */
  public setValidators(): void {
    this.control.setValidators(this.validators);

    // make the validation retroactive
    this.control.updateValueAndValidity();
  }

  /**
   * checkForErrors method
   * set displayError flag to true if there are errors
   * @param {Event} event blur event
   * @returns {void}
   */
  public checkForErrors(event: Event): void {
    if (this.control.errors) {
      this.displayError = true;
      this.emitErrors.emit(this.control.errors);
    } else {
      this.displayError = false;
    }
  }

  /**
   * disableSpace method
   * disable the spacebar for certain inputs
   * if specified
   * @param {Event} event event triggered when typing
   * @returns {void}
   */
  public disableSpace(event: Event): void {
    if (this.shouldDisableSpace) {
      event.returnValue = false;
      event.preventDefault();
    }
  }

  /**
   * showHide method
   * shows or hides the password written
   * @param {Event} event event triggered
   * @returns {void}
   */
  public showHide(event: Event): void {
    this.show = !this.show;
    this.type = this.show ? 'text' : 'password';
    this.iconPath = this.show ? this.visibleIcon : this.invisibleIcon;

    // prevent the blur after mousedown
    event.preventDefault();
  }

  /**
   * onInput method
   * send event emiter to father
   * @return {void}
   * */
  public onInput(): void {
    if (this.displayError) {
      this.emitErrors.emit(this.control.errors);
    }
  }

  /**
   * focusInput method
   * once the user clicks the label, the
   * input should be focused
   * @return {void}
   */
  public focusInput(): void {
    this.inputField.nativeElement.focus();
  }
}
