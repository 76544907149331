import { Component, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-databases-employees',
  templateUrl: './databases-employees.component.html',
  styleUrls: ['./databases-employees.component.scss']
})
export class DatabasesEmployeesComponent {

  public buttonsDisabled: boolean = false;

  constructor(private detectorRef: ChangeDetectorRef) { }

  /**
   * disableButtons method
   * Change disableButtons variable for disables or enabled buttons
   * and detect changes
   * @param {boolean} value value assigned to the variable
   * @returns {void}
   */
  public disableButtons(value: boolean): void {
    this.buttonsDisabled = value;
    this.detectorRef.detectChanges();
  }

}
