import { Component, Input } from '@angular/core';
import { CompleteDataToWorkingHoursReport } from 'src/app/models/reports/complete-data-to-working-hours-report.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-working-hours-report-preview',
  templateUrl: './working-hours-report-preview.component.html',
  styleUrls: ['./working-hours-report-preview.component.scss']
})
export class WorkingHoursReportPreviewComponent {

  @Input() data: CompleteDataToWorkingHoursReport;

  public currentYear: string = new Date().getFullYear().toString();

  constructor(private router: Router) { }
  
}
