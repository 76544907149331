import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CitiesResponse } from 'src/app/models/responses/cities-response.model';
import { UserExperience } from 'src/app/models/profile/user-experience.model';
import { EmptyResponse } from 'src/app/models/responses/empty-response.model';

@Injectable()
export class ProfileService {

  constructor(private http: HttpClient) {}

  // event emitters
  public showButtons: Subject<boolean> = new Subject<boolean>();

  // personal details-related events
  public editPersonalView: Subject<boolean> = new Subject<boolean>();
  public editPersonalUpdated: Subject<boolean> = new Subject<boolean>();

  // summary-related events
  public editSummaryView: Subject<boolean> = new Subject<boolean>();
  public descriptionUpdated: Subject<boolean> = new Subject<boolean>();

  // skills-related events
  public addSkillView: Subject<boolean> = new Subject<boolean>();

  // experience-related events
  public addExperienceView: Subject<boolean> = new Subject<boolean>();
  public editExperienceView: Subject<UserExperience> = new Subject<UserExperience>();
  public displayExperiencesView: Subject<boolean> = new Subject<boolean>();
  public experienceAdded: Subject<boolean> = new Subject<boolean>();
  public experienceUpdated: Subject<boolean> = new Subject<boolean>();
  public skillAdded: Subject<boolean> = new Subject<boolean>();

  // storage
  public experienceToEdit: UserExperience;

  /**
   * addExperience method
   * add a new experience for the user in the database
   * @param {UserExperience} newExperience experience data
   * @returns {Observable<ExperienceResponse>} response from server
   */
  public addExperience(newExperience: UserExperience): Observable<EmptyResponse> {
    return this.http.post<EmptyResponse>(
      `${environment.apiUrl}/users/experience`,
      newExperience,
    );
  }

  /**
   * updateExperience method
   * edit an existing experience in the database
   * @param {number} id id of the experience to update
   * @param {UserExperience} experience experience data
   * @returns {Observable<EmptyResponse>} response from server
   */
  public updateExperience(id: number, experience: UserExperience): Observable<EmptyResponse> {
    return this.http.put<EmptyResponse>(
      `${environment.apiUrl}/users/experience/${id}`,
      experience,
    );
  }

  /**
   * getCities method
   * retrieves cities data when the user
   * inputs the location of his/her
   * previous experience from a third-party API
   * @param {string} city name of the city to query
   * @return {Observable<CitiesResponse[]>} response from the API
   */
  public getCities(city: string): Observable<CitiesResponse[]> {
    return this.http.get<CitiesResponse[]>(
      `${environment.citiesApiUrl}/places2?term=${city}&locale=en&types[]=city`
    ).pipe(
      catchError((error) => {
        return of(error);
      })
    );
  }

  /**
   * deleteUserExperience
   * receives an user id and an experience id to execute the request and erease
   * the experience form the user profile
   * @param {number} userId the user id
   * @param {number} experienceId the experience id to be removed
   * @returns {Observable} with the response from the API
   */
  public deleteUserExperience(userId: number, experienceId: number): Observable<EmptyResponse> {
    return this.http.delete<EmptyResponse>(`${environment.apiUrl}/users/${userId}/experience/${experienceId}`).pipe(
      catchError((error) => {
        return of (error);
      })
    );
  }
}
