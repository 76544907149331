import { Component, OnInit, AfterViewChecked, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { TeamList } from 'src/app/models/team-list.model';
import { UserService } from 'src/app/services/user.service';
import { UserLocationFilter } from 'src/app/models/user-location-filter.model';
import { RelatedUsersFilters } from 'src/app/models/related-users-filters.model';

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.scss'],
  providers: [UserService],
})
export class TeamListComponent implements OnInit, AfterViewChecked {

  @Output() profileView: EventEmitter<number> = new EventEmitter<number>();

  public teamLoaded: boolean = false;
  public userId: number;
  public teamList: TeamList[] = [];
  public addTeamList: TeamList[] = [];
  public actualFilter: RelatedUsersFilters = {};

  //page to obtain from the database (default 1)
  private page: number = 1;
  private search: string = null;
  private canSearch: boolean = true;

  constructor(
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  /**
   * ngOnInit hook
   * obtain the user id
   * @return {void}
   */
  ngOnInit() {
    const userId = +localStorage.getItem('id');

    // validate if the id is a number
    this.userId = !isNaN(userId) ? userId : 0;
  }

  /**
   * ngAfterViewChecked hook
   * check if the items have been rendered and run the change detection
   * system to tell the pagination directive to run again if necessary
   * @return {void}
   */
  ngAfterViewChecked() {
    if (this.teamList.length) {
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * getUsers method 
   * method that uses the user service to obtain the all 
   * the registered users
   * (20 each time)
   * @returns {void}
   */
  public getUsers(): void {
    this.canSearch = false;
    this.userService.getFilteredUsers(
      this.page,
      this.actualFilter,
      this.userId,
      true,
      this.search,
    ).subscribe(result => {
      this.teamLoaded = true;
      if (result.data.pages >= this.page) {
        this.addTeamList = result.data.items;

        if (this.page === 1) {
          this.teamList = this.addTeamList;
        } else {
          this.teamList = [...this.teamList, ...this.addTeamList];
        }

        this.page += 1;
      }

      this.canSearch = true;
    });
  }

  /**
   * setLocationFilter method
   * @description Reset the team variables and teamList
   * to get again the users aplying the given filter
   * @param {UserLocationFilter} filter - Object that describes
   * if you want filter by region or location and its respective
   * region/location id
   * @returns void {void}
   */
  public setLocationFilter(filter: UserLocationFilter): void {
    this.teamLoaded = false;
    this.teamList = [];
    this.page = 1;
    this.actualFilter = { [filter.type]: filter.id };
    this.getUsers();
  }

  /**
   * onSearch method
   * Changed search variable, reinitializes teamList
   * array and call getUsers method to get list team
   * from back end, only if not exist a current request.
   * @param {string} search - user name content to search
   * @return {void}
   */
  public onSearch(search: string): void {
    this.search = search;
    this.page = 1;
    this.teamList = [];
    this.teamLoaded = false;
    
    if (this.canSearch) {
      this.getUsers();
    }
  }
}
