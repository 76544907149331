import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PermissionsService } from 'src/app/services/permissions.service';
import { permissions } from 'src/app/config/permissions';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit {
  public permissions = permissions;

  constructor(
    private router: Router,
    public permissionsService: PermissionsService,
  ) { }

  ngOnInit() {
    // check if the route is in the manage component to redirect only here
    if (this.router.url === '/manage') {
      this.redirectionByPermissions();
    }
  }

  /**
   * redirectionByPermissions method
   * @description looks for the permissions that are necessaries to get access for a view
   * and redirects the user to that view.
   * First check for permissions to see the employees view, then, permissions for the roles
   * view and at the end redirects you to the reports view if you do not have
   * the other permissions
   * @returns {void} void
   */
  private redirectionByPermissions(): void {
    switch (true) {
      // Redirect to manage/employees
      case this.permissionsService.userHasPermission([
        permissions.editResume,
        permissions.assignRoles,
        permissions.userStatus,
        permissions.companyMembers,
      ]): {
        this.router.navigate(['manage','employees'], { replaceUrl: true });
        break;
      }

      // Redirect to manage/projects
      case this.permissionsService.userHasPermission([
        permissions.projects,
        permissions.management,
      ]): {
        this.router.navigate(['manage', 'projects'], { replaceUrl: true });
        break;
      }

      // Redirect to manage/roles
      case this.permissionsService.userHasPermission([
        permissions.createRoles,
        permissions.management,
      ]): {
        this.router.navigate(['manage', 'roles'], { replaceUrl: true });
        break;
      }

      // Redirect to manage/reports
      case this.permissionsService.userHasPermission([
        permissions.generateReports,
        permissions.management,
      ]): {
        this.router.navigate(['manage','reports'], { replaceUrl: true });
        break;
      }

      // Redirect to manage/databases
      case this.permissionsService.userHasPermission([
        permissions.databaseItems,
        permissions.management,
      ]): {
        this.router.navigate(['manage','databases'], { replaceUrl: true });
        break;
      }
    }
  }
}
