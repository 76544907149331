import { 
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewChecked,
  QueryList,
  ViewChildren,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { UserService } from 'src/app/services/user.service';
import { Employee } from 'src/app/models/employees/employee.model';
import { ContentModal } from 'src/app/models/content-modal.model';
import { archiveRestoreButtons, archiveUserModal, restoreUserModal } from 'src/app/config/modal-contents';
import { CheckboxComponent } from 'src/app/shared/components/checkbox/checkbox.component';
import { Response } from 'src/app/models/response.model';
import { PermissionsService } from 'src/app/services/permissions.service';
import { permissions } from 'src/app/config/permissions';
import { TeamListResponse } from 'src/app/models/team-list-response.model';
import { Permissions } from 'src/app/models/permissions.model';
import { UserLocationFilter } from 'src/app/models/user-location-filter.model';
import { RelatedUsersFilters } from 'src/app/models/related-users-filters.model';

@Component({
  selector: 'app-employees-shared-list',
  templateUrl: './employees-shared-list.component.html',
  styleUrls: ['./employees-shared-list.component.scss']
})
export class EmployeesSharedListComponent implements OnInit, AfterViewChecked {

  @ViewChildren('users') usersHtml: QueryList<CheckboxComponent>;

  @Input() allUsers: boolean;
  @Input() textButton: string;
  @Input() canSearch: boolean;
  @Output() goToEmployee: EventEmitter<number> = new EventEmitter<number>();

  public employeesLoaded: boolean = false;
  public employees: Employee[] = [];
  public employeePhotos: string[] = [];
  public employeesFormGroups: FormGroup[] = [];
  public showModal: boolean = false;
  public idUser: number = null;
  public statusUser: boolean;
  public indexUser: number;
  public permissions: Permissions = permissions;
  public actualFilter: RelatedUsersFilters = {};

  // Content Modal data to customize
  public contentModal: ContentModal = {
    title: '',
    content: [],
    confirmButton: archiveRestoreButtons.confirmButton,
    cancelButton: archiveRestoreButtons.cancelButton,
  };

  // page of employees to retrieve
  private totalPages: number = 1;
  private page: number = 1;
  private versionPhoto: number = Date.now();
  private userId: number;
  private newEmployees: Employee[] = [];
  private employeesSubs: Subscription;
  private employeeFormSubs: Subscription[] = [];
  private search: string = null;
  private canRequest: boolean = true;

  constructor(
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    public permissionsService: PermissionsService,
  ) { }

  /**
   * ngOnInit hook
   * get the user id
   * @return {void}
   */
  ngOnInit() {
    // get the current user id
    const userId = +localStorage.getItem('id');

    // validate if the id is a number
    this.userId = !isNaN(userId) ? userId : 0;
  }

  /**
   * ngAfterViewChecked hook
   * check if the items have been rendered and run the change detection
   * system to tell the pagination directive to run again if necessary
   */
  ngAfterViewChecked() {
    if (this.employees.length) {
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * getEmployees method
   * Check the allUsers Input variable to decide between
   * get all the employees or just the actives
   * @return {void}
   */
  public getEmployees(): void {
    if (this.allUsers) {
      this.getAllEmployees();
    } else {
      this.getActiveEmployees();
    }
  }

  /**
   * getAllEmployees method
   * retrieves the users (employees) from the database, omitting those that are
   * no longer active
   * @return {void}
   */
  private getAllEmployees(): void {
    if (this.page <= this.totalPages && this.canRequest) {
      this.employeesSubs = this.userService.getFilteredUsers(
        this.page,
        this.actualFilter,
        this.userId,
        null,
        this.search,
      ).subscribe(result => {
        this.setEmployeesInfo(result);
      });
    }
  }
  
  /**
   * getActiveEmployees method
   * Retrieves just the active users (employees) from the database
   * @returns {void} 
   */
  private getActiveEmployees(): void {
    if (this.page <= this.totalPages) {
      this.employeesSubs = this.userService.getActiveUsersLoggedIncluded(this.page)
        .subscribe(result => {
          this.setEmployeesInfo(result);
        });
    }
  }

  /**
   * setEmployeesInfo method
   * Set the information retrieved from the service
   * to be showed in the view
   * @param result 
   */
  private setEmployeesInfo(result: TeamListResponse): void {
    this.employeesLoaded = true;
    // get the total of pages in the database
    this.totalPages = result.data.pages ? result.data.pages : 1;

    this.newEmployees = result.data.items;

    if (this.newEmployees.length) {
      /**
       * set the alt names of the employees' images and the query
       * param to enable auto refresh
       */
      this.newEmployees.forEach(employee => {
        const employeeNameArray: string[] = employee.fullName.trim().split(' ').filter(name => name);

        // capitalize the full name of the employee just in case
        const employeeName: string = employeeNameArray.map(name => {
          return `${name[0].toUpperCase()}${name.slice(1)}`;
        }).join(' ');

        this.employeePhotos.push(`${employeeName}'s photo`);

        // de-cache the photo updated
        this.versionPhoto = Date.now();
        employee.photo += `?=${this.versionPhoto}`;
      });

      this.employees.push(...this.newEmployees);

      // create forms
      this.createActiveForms();

      this.page += 1;
    }
    
    this.canRequest = true;
  }

  /**
   * createActiveForms method
   * creates the form group for each employee
   * @return {void}
   */
  public createActiveForms(): void {
    this.newEmployees.forEach((newEmployee, index) => {
      this.employeesFormGroups.push(
        new FormGroup({
          'active': new FormControl(null, []),
        })
      );
    });
  }

  /**
   * goToEmployeeView method
   * Emit an event that is listened by the parent component
   * sending the id of the clicked user
   * @param {number} employeeId id of the employee that was clicked
   * @return {void}
   */
  public goToEmployeeView(employeeId: number): void {
    this.goToEmployee.emit(employeeId);
  }

  /**
   * enableDisableUser method
   * Show the modal and initialize valiables for on control method
   * @param {number} index index from array employees
   * @returns {void}
   */
  public enableDisableUser(index: number): void {
    this.statusUser = !this.employeesFormGroups[index].value.active;
    const fullName = this.employees[index].fullName;
    this.idUser = this.employees[index].id;
    this.indexUser = index;

    if (this.statusUser) {
      this.contentModal.title = archiveUserModal.title;
      this.contentModal.content = archiveUserModal.content(fullName);
    } else {
      this.contentModal.title = restoreUserModal.title;
      this.contentModal.content = restoreUserModal.content(fullName);
    }
    this.showModal = true;
  }

  /**
   * onControl Method
   * Listen the PopUpMessage EventEmitter of control
   * If confirmButton was pushed the backend call occurs
   * If cancelButton was pushed the toggle switch status is restored
   * @param {string} message - Can be confirm or cancel, depending of the pushed button
   * @return {void}
   */
  public onControl(message: string): void {
    if (message === 'confirm') {
      this.toogleUserStatus();
    } else {
      const usersArray = this.usersHtml.toArray();
      usersArray[this.indexUser].toggle();
    }
  }

  /**
   * onCloseModal Method
   * Listen the PopUpMessage EventEmitter of close
   * When it is received the showModal variable turns to false and the modal is closed
   * @return {void}
   */
  public onCloseModal(): void {
    this.showModal = false;
  }

  /**
   * toogleStatus method
   * Change the selected user status
   * @returns {void}
   */
  private toogleUserStatus(): void {
    this.userService.enableDisableUser(this.idUser)
      .subscribe((response: Response) => {
        if (response.status !== 'success' ) {
          this.onControl('cancel');
        }
      });
  }

  /**
   * setLocationFilter method
   * @description Reset the employees variables and employees array
   * to get again the employees aplying the given filter
   * @param {UserLocationFilter} filter - Object that describes
   * if you want filter by region or location and its respective
   * region/location id
   * @returns void {void}
   */
  public setLocationFilter(filter: UserLocationFilter) {
    this.employeesLoaded = false;
    this.employees = [];
    this.employeePhotos = [];
    this.page = 1;
    this.actualFilter = { [filter.type]: filter.id };
    this.getEmployees();
  }

  /**
   * ngOnDestroy hook
   * unsubscribe all the observables
   */
  ngOnDestroy() {
    this.employeesSubs.unsubscribe();
    this.employeeFormSubs.forEach(formSub => formSub.unsubscribe());
  }

  /**
   * onSearch method
   * Changed search variable, reinitializes employees
   * array and call getEmployees
   * method get employees from back end.
   * @param {string} search - project name content to search
   * @returns {void} void
   */
  public onSearch(search: string): void{
    this.search = search;
    this.page = 1;
    this.employees = [];
    this.employeesLoaded = false;
    this.getEmployees();
  }
}
