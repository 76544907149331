import { Directive, Input, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appDatabaseAutoFocus]'
})
export class DatabaseAutoFocusDirective implements AfterViewInit {

  @Input() comeFromButton: boolean;
  @Input() isNewForm: boolean;

  private element: HTMLInputElement;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  /**
   * ngAfterViewInit hook
   * set focus only in spescific input
   */
  ngAfterViewInit() {
    if (this.comeFromButton && !this.isNewForm) {
      this.element.focus();
    }
  }

}
