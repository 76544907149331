import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-login',
  templateUrl: './footer-login.component.html',
  styleUrls: ['./footer-login.component.scss']
})
export class FooterLoginComponent {

  constructor(private route: Router) { }

  /**
   * goToSignUp() method
   * redirect user to sign up form
   * @returns {void}
  */
  public goToSignUp(): void {
    this.route.navigateByUrl('signup');
  };
}
