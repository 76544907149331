import { Component, OnInit, Input } from '@angular/core';
import { NavbarService } from 'src/app/services/navbar.service';
import { ProjectFiltersData } from 'src/app/models/project-filters-data.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-teammate-header',
  templateUrl: './teammate-header.component.html',
  styleUrls: ['./teammate-header.component.scss']
})
export class TeammateHeaderComponent implements OnInit {

  @Input() username: string;
  @Input() photo: string;
  @Input() projectFiltersData: ProjectFiltersData;
  @Input() fromRoute: string;

  public photoAlt: string;

  constructor(
    private router: Router,
    private navbarService: NavbarService,
  ) { }

  /**
   * ngOnInit hook
   * set up the alt attribute of the user photo
   * @return {void} 
   */
  ngOnInit() {
    this.photoAlt = `${this.username}'s photo`;
  }

  /**
   * goBack method
   * goes to the previous route
   * @return {void}
   */
  public goBack(): void {
    this.router.navigate([this.fromRoute], {
      state: {
        data: this.projectFiltersData
      }
    });
  }

  /**
   * toggleNavbar method
   * opens the navbar
   * @return {void}
   */
  public toggleNavbar(): void {
    this.navbarService.navbarRef.nativeElement.checked = !this.navbarService.navbarRef.nativeElement.checked;
  }

}
