import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';

import { Subscription } from 'rxjs';
import moment, { Moment } from 'moment';

import { UserService } from 'src/app/services/user.service';
import { ProfileResponse } from 'src/app/models/profile-response.model';
import { CheckInResponse } from 'src/app/models/responses/check-in-response.model';
import { CheckIn } from 'src/app/models/check-in.model';
import { updateCheckInErrors } from 'src/app/config/error-messages';
import { alphaNumberNoEmojisRegex } from 'src/app/config/regex';
import { UpdateCheckIn } from 'src/app/models/update-check-in.model';
import { EmptyResponse } from 'src/app/models/projects/empty-response.model';

@Component({
  selector: 'app-employee-check-in',
  templateUrl: './employee-check-in.component.html',
  styleUrls: ['./employee-check-in.component.scss']
})
export class EmployeeCheckInComponent implements OnInit {

  public employeeId: number;
  public employeeName: string;
  public checkIns: CheckIn[];
  public period: string[] = [];
  public checkInForms: FormGroup[] = [];
  public arrivalControls: FormControl[] = [];
  public leaveLunchControls: FormControl[] = [];
  public arrivalLunchControls: FormControl[] = [];
  public leaveControls: FormControl[] = [];
  public timeValidators: ValidatorFn[] = [];
  public timeErrors;

  public activePeriod: Moment;
  public year: string;
  public month: string;
  public periodNumber: number;
  // default value for the ordinal
  public ordinal: string = 'th';

  private employeeSubs: Subscription;
  private employeeCheckInSubs: Subscription;
  private updateCheckInSubs: Subscription;

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) { }

  /**
   * ngOnInit hook
   * obtain the employee's id and get his/her data
   * @return {void}
   */
  ngOnInit() {
    // get the employee's id
    const employeeId = +this.activatedRoute.snapshot.paramMap.get('id');

    // validate the id in the router
    if (!isNaN(employeeId) && employeeId % 1 === 0) {
      this.employeeId = employeeId;
      // get employee's data and check ins
      this.getEmployeeData();

      // obtain current pay period (default)
      this.setPayPeriod();

      // set up input validators
      this.timeValidators = [
        Validators.required,
        Validators.pattern(alphaNumberNoEmojisRegex),
      ];

      // set up input errors
      this.timeErrors = updateCheckInErrors.timeError;

      // obtain employee's check ins
      this.getEmployeeCheckIns();
    }
  }

  /**
   * setPayPeriod method
   * sets the start and end dates for a pay period
   * @param {Moment} date get pay period of this date
   * @return {void}
   */
  private setPayPeriod(date: Moment = null): void {
    let fromDate: Moment = null;
    let toDate: Moment = null;

    // get current start and end of pay period (default)
    if (!date) {
      if (moment().date() <= 15) {
        fromDate = moment().startOf('month');
        toDate = moment().date(15);
        this.activePeriod = moment().date(1);
      } else {
        fromDate = moment().date(16);
        toDate = moment().endOf('month');
        this.activePeriod = moment().date(16);
      }

      this.month = moment().format('MMMM');
      this.year = moment().format('YYYY');
    } else {
      if (date.date() <= 15) {
        fromDate = moment(date).startOf('month');
        toDate = moment(date).date(15);
      } else {
        fromDate = moment(date).date(16);
        toDate = moment(date).endOf('month');
      }

      this.month = date.format('MMMM');
      this.year = date.format('YYYY');
      this.activePeriod = moment(date);
    }

    this.setPeriodNumber();

    this.period = [
      fromDate.format('YYYY-MM-DD'),
      toDate.format('YYYY-MM-DD'),
    ];
  }

  /**
   * setPeriodNumber method
   * set the period number (ordinal)
   * @return {void}
   */
  private setPeriodNumber(): void {
    this.periodNumber = (this.activePeriod.month() + 1) * 2;

    if (this.activePeriod.date() <= 15) {
      this.periodNumber -= 1;
    }

    if (Math.floor(this.periodNumber / 10) !== 1) {
      switch (this.periodNumber % 10) {
        case 1:
          this.ordinal = "st";
          break;
        case 2:
          this.ordinal = "nd";
          break;
        case 3:
          this.ordinal = "rd";
          break;
        default:
          this.ordinal = 'th';
          break;
      }
    }
  }

  /**
   * getPreviousPeriod method
   * obtain the previous pay period to the active one
   * @return {void}
   */
  public getPreviousPeriod(): void {
    let date: Moment;

    if (this.activePeriod.date() <= 15) {
      date = moment(this.activePeriod).subtract(1, 'month').date(16);
    } else {
      date = moment(this.activePeriod).date(1);
    }

    this.setPayPeriod(date);
    this.getEmployeeCheckIns();
  }

  /**
   * getNextPeriod method
   * obtain the next pay period to the active one
   * @return {void}
   */
  public getNextPeriod(): void {
    let date: Moment;
    
    if (this.activePeriod.date() <= 15) {
      date = moment(this.activePeriod).date(16);
    } else {
      date = moment(this.activePeriod).add(1, 'month').date(1);
    }

    this.setPayPeriod(date);
    this.getEmployeeCheckIns();
  }

  /**
   * getEmployeeCheckIns method
   * retrieves the check in data for the user within the current
   * pay period
   * @return {void}
   */
  public getEmployeeCheckIns(): void {
    this.employeeCheckInSubs = this.userService
      .getUserCheckIns(this.employeeId, this.period)
      .subscribe((result: CheckInResponse) => {
        // reset all check ins and form group and controls;
        this.checkIns = [];
        this.arrivalControls = [];
        this.leaveLunchControls = [];
        this.arrivalLunchControls = [];
        this.leaveControls = [];
        this.checkInForms = [];

        if (result.data && result.data.length) {
          this.checkIns = result.data;
          // filter out weekends
          this.checkIns = this.checkIns.filter(checkIn => {
            const time = moment(checkIn.date, 'YYYY-MM-DD');
            
            if (time.day() !== 0 && time.day() !== 6) {
              return checkIn;
            }
          });
  
          // set display data for the remaining days and prepare the form groups
          this.checkIns.forEach(checkIn => {
            const time = moment(checkIn.date, 'YYYY-MM-DD');
            checkIn.displayDate = time.format('D');
            checkIn.dayOfWeek = time.format('dddd');

            // set data to uppercase
            this.setCheckInDataToUppercase(checkIn);
          });
  
          this.prepareForms();
        }
      });
  }

  /**
   * setCheckInDataToUppercase method
   * sets the first letter of the data to uppercase
   * @param {CheckIn} checkIn check in data
   * @return {void}
   */
  private setCheckInDataToUppercase(checkIn: CheckIn): void {
    const timeNames: string[] = [
      'arrival', 
      'leaveLunch', 
      'arrivalLunch', 
      'leave'
    ];

    timeNames.forEach(timeName => {
      if (checkIn[timeName]) {
        checkIn[timeName] = `${checkIn[timeName][0].toUpperCase()}${checkIn[timeName].slice(1)}`;
      }
    });
  }

  /**
   * prepareForms method
   * logic to create the form groups and controls necessary for the fields
   * @return {void}
   */
  private prepareForms(): void {
    this.checkIns.forEach((checkIn, index) => {
      // set up all the necessary form controls
      this.arrivalControls.push(
        new FormControl(checkIn.arrival, [])
      );
  
      this.leaveLunchControls.push(
        new FormControl(checkIn.leaveLunch, [])
      );
      
      this.arrivalLunchControls.push(
        new FormControl(checkIn.arrivalLunch, [])
      );
  
      this.leaveControls.push(
        new FormControl(checkIn.leave, [])
      );
  
      // create a new form group for each check in
      this.checkInForms.push(
        new FormGroup({
          'arrival': this.arrivalControls[index],
          'leaveLunch': this.leaveLunchControls[index],
          'arrivalLunch': this.arrivalLunchControls[index],
          'leave': this.leaveControls[index],
        })
      );
    });
  }

  /**
   * getEmployeeData method
   * obtain the data of the user based on the id
   * @return {void}
   */
  private getEmployeeData(): void {
    this.employeeSubs = this.userService
      .getUser(this.employeeId)
      .subscribe((result: ProfileResponse) => {
        const { firstName, lastName } = result.data;

        this.employeeName = `${firstName.trim()} ${lastName.trim()}`;
      });
  }

  /**
   * validateErrors method
   * check if the control has any errors in its value and set the 
   * default value to it
   * @param {FormControl} control control to check for errors and set 
   * to default value if found
   * @param {number} index array index to find the previous value of the control
   * in the check ins array
   * @param {string} name name of the form control
   * @return {void}
   */
  public validateErrors(control: FormControl, index: number, name: string): void {
    if (control.errors) {
      // set previous value
      control.setValue(this.checkIns[index][name]);
    } else {
      // make request only if the new value is different than the one stored
      const oldValue = this.checkIns[index][name].trim().toLowerCase();
      const newValue = control.value.trim().toLowerCase();

      if (oldValue !== newValue) {
        const newData: UpdateCheckIn = {};

        if (name === 'arrivalLunch') {
          name = 'arriveLunch';
        }

        newData[name] = control.value;
        // update the new value
        this.updateEmployeeCheckIn(+this.checkIns[index].id, newData);
      }
    }
  }

  /**
   * updateEmployeeCheckIn method
   * updates the employee check in data
   * @param {number} checkInId id of the check in to edit
   * @param {UpdateCheckIn} newData data to update the check in
   * @return {void}
   */
  private updateEmployeeCheckIn(checkInId: number, newData: UpdateCheckIn): void {
    this.updateCheckInSubs = this.userService
      .updateUserCheckIn(this.employeeId, checkInId, newData)
      .subscribe((result: EmptyResponse) => {
        if (result.status !== 'error') {
          this.getEmployeeCheckIns();
        }
      });
  }


  /**
   * ngOnDestroy hook
   * unsubcribe from observables
   * @return {void}
   */
  ngOnDestroy() {
    this.employeeSubs.unsubscribe();
    this.employeeCheckInSubs.unsubscribe();

    if (this.updateCheckInSubs) {
      this.updateCheckInSubs.unsubscribe();
    }
  }
}
