import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import { validateArray } from 'src/app/shared/custom-validators/array.validator';
import { databasesSenioritiesErrors } from 'src/app/config/error-messages';
import { patternValidator } from 'src/app/shared/custom-validators/custom-pattern';
import { seniorityRegex } from 'src/app/config/regex';
import { SignUpService } from 'src/app/services/sign-up.service';
import { DatabasesService } from 'src/app/services/databases.service';
import { Response } from 'src/app/models/sign-up/generic-response.model';
import { Seniorities } from 'src/app/models/sign-up/seniorities.model';
import { DatabaseItemArray } from 'src/app/models/database-item-array.model';
import { DatabasesRequest } from 'src/app/models/databases-request.model';
import { DatabaseItem } from 'src/app/models/database-item.model';
import { ContentModal } from 'src/app/models/content-modal.model';
import {
  deleteButtons,
  deleteDatabaseItemModal,
  migrateRelatedDataModal,
} from 'src/app/config/modal-contents';
import { UserService } from 'src/app/services/user.service';
import { TeamListResponse } from 'src/app/models/team-list-response.model';
import { SelectorOption } from 'src/app/models/select/selector-option.model';

@Component({
  selector: 'app-seniorities',
  templateUrl: './seniorities.component.html',
  styleUrls: ['./seniorities.component.scss']
})
export class SenioritiesComponent {

  @Input() buttonsDisabled: boolean;
  @Output() disableAllButtons: EventEmitter<boolean> = new EventEmitter<boolean>();

  public showContent: boolean = true;
  public senioritiesForm: FormGroup = new FormGroup({
    seniorities: new FormArray([], validateArray)
  });
  public isNewInput: boolean[] = [];
  public senioritiesErrors: object = databasesSenioritiesErrors.senioritiesErrors;
  public inputRegex: RegExp = seniorityRegex;
  public seniorities: DatabaseItem[] = [];
  public updateContent: boolean = false;
  public showModal: boolean = false;
  public seniorityToDelete: DatabaseItem = null;
  public seniorityOptions: SelectorOption[] = [];
  public contentModal: ContentModal = {
    title: '',
    content: [],
    confirmButton: deleteButtons.confirmButton,
    cancelButton: deleteButtons.cancelButton,
  }

  constructor(
    private signUpService: SignUpService,
    private databaseService: DatabasesService,
    private userService: UserService,
  ) {
    this.getSeniority();
  }

  /**
   * toggleContent method
   * @description toggle the flag showContent to show/hide the content
   * @returns {void} void
   */
  public toggleContent(): void {
    const elementAdded = this.isNewInput.find((input) => {
      return input;
    });

    this.showContent = !this.showContent;
    if (!this.showContent && this.buttonsDisabled && elementAdded) {
      this.disableButtons(false);
    }
  }

  /** 
   * getSeniority method
   * call get endpoint for seniorities, asign data to variable
   * @return {void}
   */
  private getSeniority(): void {
    (this.senioritiesForm.get('seniorities') as FormArray).clear();
    this.seniorities = [];
    this.isNewInput = [];
    this.updateContent = true;

    this.signUpService
      .getSignUpGeneric('/company/seniorities')
      .subscribe((data: Response) => {
        data.data.map((seniority: Seniorities) => {
          const seniorityResponse = {
            id: seniority.id,
            name: seniority.name,
          };

          this.seniorities.push(seniorityResponse);
          this.isNewInput.push(false);
          (this.senioritiesForm.get('seniorities') as FormArray)
            .push(this.createForm(seniorityResponse.name));
        });

        this.updateContent = false;
      });
  }

  /**
   * createForm method
   * Returns a new FormControl with value received by parameter
   * @param {string} formValue the value of new FormControl
   * @returns {FormControl}
   */
  private createForm(formValue: string): FormControl  {
    return new FormControl(formValue, [
      Validators.required,
      patternValidator(seniorityRegex)
    ]);
  }

  /**
   * checkSeniorityContent method
   * verify if the value of the parameter with function trim has value
   * and call save new seniority.
   * @param {DatabaseItemArray} newSeniority position, value, and parent form of the
   * new seniority
   * @returns {void}
   */
  public checkSeniorityContent(newSeniority: DatabaseItemArray): void {
    this.verifyInputs();
    if (newSeniority.control) {
      this.saveSeniority(newSeniority);
    }
  }

  /**
   * saveSeniority method
   * send to back end the new area to save in database
   * if all it's ok, makes read-only the current input
   * @param {DatabaseItemArray} newSeniority position, value, and parent form of the
   * new seniority
   * @returns {void}
   */
  private saveSeniority(newSeniority: DatabaseItemArray): void {
    const content: DatabasesRequest = {
      method: 'POST',
      body: { seniorityName: newSeniority.control.value },
      endpoint: '/company/seniorities',
    }

    this.databaseService.sendRequest(content).subscribe((response) => {
      if (response.status === 'success') {
        this.getSeniority();
      }
    });
  }

  /**
   * verifyInputs method
   * delete empty FormControls
   * @returns {void}
   */
  public verifyInputs(): void {
    this.senioritiesForm.get('seniorities').value.map((val, index) => {
      if (!val) {
        (this.senioritiesForm.get('seniorities') as FormArray).removeAt(index);
        this.isNewInput.splice(index, 1);
      }
    });
  }

  /**
   * getSenioritiesArrayControls method
   * takes the FormGroup values and returns them as FromArray
   * @param {FormControl} senioritiesForm form to get
   * @returns {AbstractControl[]}
   */
  public getSenioritiesArrayControls(senioritiesForm: FormGroup): AbstractControl[] {
    return (senioritiesForm.get('seniorities') as FormArray).controls;
  }

  /**
   * addNewInput method
   * push new form control into senioritiesForm and new position in
   * isNewInput
   * @returns {void}
   */
  public addNewInput(): void {
    (this.senioritiesForm.get('seniorities') as FormArray).push(this.createForm(''));
    this.isNewInput.push(true);
  }

  /**
   * removeNewInput method
   * remove last position of senioritiesForm and then remove
   * a last position from isNewInput 
   * @returns {void}
   */
  public removeNewInput(): void {
    const lastInput = (this.senioritiesForm.get('seniorities') as FormArray).length - 1;
    (this.senioritiesForm.get('seniorities') as FormArray).removeAt(lastInput);
    this.isNewInput.splice(lastInput, 1);
  }

  /**
   * checkLastInput method
   * verify if last input is dont saved to remove if destroy input
   * @returns {void}
   */
  public checkLastInput(): void {
    const lastInput = (this.senioritiesForm.get('seniorities') as FormArray).length - 1;
    if (this.isNewInput[lastInput]) {
      (this.senioritiesForm.get('seniorities') as FormArray).controls[lastInput];
      this.removeNewInput();
    }
  }

  /**
   * disableButtons method
   * change the variable disabled buttons in the father to put
   * enable or disable other buttons
   * @param {boolean} value
   */
  public disableButtons(value: boolean): void {
    this.disableAllButtons.emit(value);
  }

  /**
   * deleteSeniority method
   * @description delete the seniority with the index
   * in seniorities array received in seniority object
   * @param {DatabasesRequest} request - Object with
   * the necessary method and enspoint to delete an specific seniority
   * @returns {void} void
   */
  public deleteSeniority(request: DatabasesRequest): void {
    this.databaseService.sendRequest(request).subscribe((response) => {
      if (response.status === 'success') {
        this.getSeniority();
      }
    });
  }

  /**
   * hasUsersRelated method
   * @description Check if the given seniority
   * has users related
   * @param {DatabaseItemArray} seniority - Object
   * with the index position in seniorities array
   * of the seniority to check
   * @returns {void} void
   */
  public hasUsersRelated(seniority: DatabaseItemArray): void {
    this.seniorityToDelete = {
      id: this.seniorities[seniority.index].id,
      name: this.seniorities[seniority.index].name,
    }

    this.userService.getFilteredUsers(
      1,
      { seniorityId: this.seniorityToDelete.id },
    ).subscribe((response: TeamListResponse) => {
      if (response.data.pages) {
        this.seniorityOptions = this.seniorities.filter((seniority) => {
          return seniority.id !== this.seniorityToDelete.id;
        });

        this.contentModal.title = migrateRelatedDataModal.title('Users');
        this.contentModal.content = migrateRelatedDataModal.content(
          this.seniorityToDelete.name,
          'Seniority',
          'Users',
        );
      } else {
        this.contentModal.title = deleteDatabaseItemModal.title('Seniority'),
        this.contentModal.content = deleteDatabaseItemModal.content(
          this.seniorityToDelete.name,
          'Seniority',
        )
      }

      this.showModal = true;
    });
  }

  /**
   * onControlModal method
   * @description Listen the control event from PopUpMessage
   * when a button was clicked to know which was
   * clicked and if confirm was clicked send to object
   * to make the BE Request
   * @param value - Clicked button ('confirm'/'cancel'/optionId (in case you used selector))
   * @returns {void} void
   */
  public onControlModal(value: string): void {
    let request: DatabasesRequest = null;
    let endpoint: string = `/company/seniorities/${this.seniorityToDelete.id}`;

    // In case you used the selector
    if (parseInt(value, 10)) {
      endpoint += `?seniorityToMigrateId=${value}`;
    }

    request = {
      method: 'DELETE',
      endpoint: endpoint,
    }

    if (value !== 'cancel') {
      this.deleteSeniority(request);
    }

    this.seniorityOptions = [];
  }

  /**
   * onCloseModal method
   * @description Listen the close event from PopUpMessage
   * to know when the modal may be closed
   * @returns {void} void
   */
  public onCloseModal(): void {
    this.showModal = false;
  }
}
