import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EmptyResponse } from 'src/app/models/projects/empty-response.model';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class TokenValidatorService implements Resolve<EmptyResponse> {

  constructor(private userService: UserService) { }

  /**
   * resolve method
   * check the token before loading any view
   * @param {ActivatedRouteSnapshot} route contains information about route
   * of loading component
   * @param {RouterStateSnapshot} state representing the state of the router
   * at a particular moment in time
   * @returns {Observable<EmptyResponse>}
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EmptyResponse> {
    return this.userService.tokenValidator();
  }
}
