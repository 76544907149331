import { Injectable } from '@angular/core';

@Injectable({
  'providedIn': 'root',
})
export class UtilService {
  /**
   * areArraysEqual method
   * check if both arrays have the same number of
   * elements and the same elements
   * @param {string[]} arr1 first array to compare
   * @param {string[]} arr2 second array to compare
   * @return {boolean} arrays are equal or not
   */
  public areArraysEqual(arr1: string[], arr2: string[]): boolean {
    if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
      return false;
    }

    // this doesn't pass the array by reference
    const copyArr1 = arr1.concat().sort();
    const copyArr2 = arr2.concat().sort();

    for (let i = 0; i < arr1.length; i += 1) {
      if (copyArr1[i] !== copyArr2[i]) {
        return false;
      }
    }

    return true;
  }

  /**
   * htmlTagRemover
   * removes the html tags inside an array of strings
   * @param html an array with stringify html tags
   * @returns {string[]} array without html tags, just plain text
   */
  public htmlTagRemover(html: string[]): string[] {
    return html.map(item => item.toString().replace(/<.*?>/gm, ''));
  }
}
