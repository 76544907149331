import { Component, OnInit, AfterViewChecked, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router'
import { ProjectsService } from 'src/app/services/projects.service';
import { ProjectListItem } from 'src/app/models/projects/project-list-item.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
  providers: [ProjectsService],
})
export class ProjectsListComponent implements OnInit, AfterViewChecked, OnDestroy {

  public projectsLoaded: boolean = false;
  public overflow: boolean = false;
  /**
   * obtain the logged user id to check if he/she
   * belongs to any of the projects displayed
   */
  public userId: number;
  public projects: ProjectListItem[] = [];
  public projectPhotos: string[] = [];
  
  private newItems: ProjectListItem[] = [];
  // page to obtain from the database (default 1)
  private page: number = 1;
  private search: string = null; 
   // subscription
   public getProjectsSubscription: Subscription;

  constructor(
    private projectsService: ProjectsService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  /**
   * ngOnInit hook
   * sets the userId by first checking if the data
   * stored in localStorage is a number
   */
  ngOnInit() {
    const userId = +localStorage.getItem('id');

    // validate if the id is a number
    this.userId = !isNaN(userId) ? userId : 0;
  }

  /**
   * ngAfterViewChecked hook
   * check if the items have been rendered and run the change detection
   * system to tell the pagination directive to run again if necessary
   * @return {void}
   */
  ngAfterViewChecked() {
    if (this.projects.length && !this.projectsLoaded) {
      this.projectsLoaded = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * ngOnDestroy hook
   * unsubscribes from the observables
   */
  ngOnDestroy() { 
    this.getProjectsSubscription.unsubscribe();
  }

  /**
   * setProjectsMessage method
   * sets the property that will contain the message that shows how many users
   * are part of each project 
   * @return {void}
   */
  private setProjectsMessage(): void {
    this.newItems.forEach(project => {
      if (project.users.length > 2 && project.userInProject) {
        project.usersInProjectMessage = `You and ${project.users.length - 1} users have this project assigned.`;
      }

      if (project.users.length === 2 && project.userInProject) {
        project.usersInProjectMessage = `You and other user have this project assigned.`;
      }

      if (project.users.length > 1 && !project.userInProject) {
        project.usersInProjectMessage = `${project.users.length} users have this project assigned.`;
      }

      if (project.users.length === 1) {
        if (project.userInProject) {
          project.usersInProjectMessage = 'You are the only user that have this project assigned.';
        } else {
          project.usersInProjectMessage = 'Only one user has this project assigned.';
        }
      }

      if (!project.users.length) {
        project.usersInProjectMessage = 'No user has this project assigned.';
      }
    });
  }

  /**
   * getProjects method 
   * method that uses the projects service to obtain the projects
   * (20 each time)
   * @returns {void}
   */
  public getProjects(search: string = null): void {
    this.getProjectsSubscription = this.projectsService.getProjects(
      this.page, 
      this.search, 
      'active', 
      ''
      ).subscribe(result => {
      if (search !== null) {
        this.projects = []; 
        this.projectPhotos = [];
      }
      this.newItems = result.data.items;

      if (this.newItems.length > 0) {
        this.projects.push(...this.newItems);

        // set the alt names of the projects' images
        this.projects.forEach(project => {
          const projectName = `${project.name[0].toUpperCase()}${project.name.slice(1)}`;

          this.projectPhotos.push(`${projectName}'s logo`);
        });

        // validate if user is part of the project
        this.validateIfUserInProject();

        // set the users messages for each project
        this.setProjectsMessage();

        this.page += 1;
      }
    });
  }

  /**
   * validateIfUserInProject method 
   * method that uses the projects service to obtain the projects
   * (20 each time)
   * @returns {void}
   */
  public validateIfUserInProject(): void {
    this.newItems.forEach(project => {
      project.userInProject = project.users.includes(this.userId);
    });
  }

  /**
   * selectProject method
   * passes the id of the project to display
   * @param {number} id id of the project to display 
   * @returns {void} void
   */
  public selectProject(id: number): void {
    this.router.navigateByUrl(`manage/projects/${id}`);
  }

  /**
   * onSearch method
   * filter projects by name
   * @param {string} search - project name content to search
   * @returns {void} void
   */
  public onSearch(search: string): void{
    this.search = search;
    this.page = 1;
    this.getProjects(search);
  }
}
