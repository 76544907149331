import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-employee-header',
  templateUrl: './edit-employee-header.component.html',
  styleUrls: ['./edit-employee-header.component.scss']
})
export class EditEmployeeHeaderComponent {

  constructor(private router: Router) { }

  /**
   * goBack method
   * goes back to the previous route
   * @return {void}
   */
  public goBack(): void {
    this.router.navigateByUrl('manage/employees');
  }

}
