import {
  Component,
  OnInit,
  Input,
  Output,
  HostListener,
  ElementRef,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  ChangeDetectorRef,
  EventEmitter,
  ViewChildren,
} from '@angular/core';
import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { SelectorOption } from 'src/app/models/select/selector-option.model';

import { CapitalizePipe } from 'src/app/pipes/capitalize.pipe';
import { SelectService } from 'src/app/services/select.service';
import { KeyPressedResults } from 'src/app/models/select/select-key-pressed.model';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  providers: [CapitalizePipe]
})
export class SelectorComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() parentForm: FormGroup;
  @Input() control: FormControl;
  @Input() validators: ValidatorFn[];
  @Input() errors;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() options: SelectorOption[];
  @Input() capitalizeOptions: boolean = true;
  /* flag to determine if the value passed in the form by the
  * component should be the hidden input or the plain text
  */
  @Input() nullId: boolean = false;
  @Input() default: SelectorOption;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChildren('optionsElements') optionsElements;

  public defaultSet: boolean = false;
  public showOptions: boolean = false;
  public setOverflow: boolean = false;
  public selectedOption: SelectorOption = {
    id: null,
    name: '',
  };
  public focusedOption = -1;
  public selectedOptionPosition: number = -1;
  public errorKeys: string[];
  public errorMessages: string[];
  public displayError: boolean = false;

  constructor(
    public capitalize: CapitalizePipe,
    private eRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private selectService: SelectService,
  ) { }

  ngOnInit() {
    if (this.default) {
      this.makeSelection(this.default);
      this.showOptions = false;
    }

    if (this.options) {
      this.options.forEach(element => {
        if (element.id === this.control.value) {
          this.select(element);
        }
      });
    }

    if (this.placeholder) {
      this.placeholder = this.capitalize.transform(this.placeholder);
    }

    this.setValidators();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.default && changes.default.currentValue) {
      if (this.nullId) {
        this.control.setValue(changes.default.currentValue.name);
      } else {
        this.control.setValue(changes.default.currentValue.id);
      }
      this.select(changes.default.currentValue);
    }
  }
  
  ngAfterViewInit(): void {
    if (this.default && !this.defaultSet) {
      if (this.nullId) {
        this.control.setValue(this.default.name);
      } else {
        this.control.setValue(this.default.id);
      }
      this.select(this.default);
      this.defaultSet = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * onGlobalClick method
   * determine if the user clicks outside of the
   * options list
   * @return {void}
   */
  @HostListener('document:click', ['$event'])
  onGlobalClick(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }

  /**
   * openOptions method
   * toggle the options list
   * @return {void}
   */
  public openOptions(): void {
    if (this.options.length > 4) {
      this.setOverflow = true;
    }

    this.focusedOption = -1;
    this.showOptions = !this.showOptions;
  }

  /**
   * makeSelection method
   * select an option from the list and set the value
   * to the control
   * @return {void}
   */
  public makeSelection(option: SelectorOption): void {
    if (this.nullId) {
      this.control.setValue(option.name);
    } else {
      this.control.setValue(option.id);
    }
    this.valueChange.emit('');
    this.select(option);
    this.showOptions = false;
  }

  /**
   * checkForErrors method
   * toggle a flag to display the errors
   * @return {void}
   */
  public checkForErrors(): void {
    if (this.control.errors) {
      this.displayError = true;
    } else {
      this.displayError = false;
    }
  }

  /**
   * setValidators method
   * sets the validators for the form control
   * of this component and updates it to be
   * retroactive, emitting an event at the same
   * time
   * @returns {void}
   */
  public setValidators(): void {
    this.control.setValidators(this.validators);

    // make the validation retroactive
    this.control.updateValueAndValidity();
  }

  /**
   * select method
   * Method to set the values from a specific selected option
   * @param {SelectorOption} option option to be selected
   * @returns {void}
   */
  private select(option: SelectorOption): void {
    this.selectedOption = option;
    this.selectedOptionPosition = this.options.findIndex(
      option => option === this.selectedOption
    );
    this.focusedOption = -1;
  }

  /**
   * onKeyPressed method
   * Function to handle keyboard events and handle the component with keys
   * @param {KeyboardEvent} event Keyboard-Event to be handled
   * @returns {void} false to cancel the event
   */
  public onKeyPressed(event: KeyboardEvent): boolean {
    const results: KeyPressedResults = this.selectService.onKeyPressed(
      event.key,
      this.showOptions,
      this.focusedOption,
      this.optionsElements.toArray(),
      this.selectedOptionPosition,
      this.options,
    );
    
    this.showOptions = results.showOptions;
    this.focusedOption = results.focusedOption;
    if (results.makeSelection.id !== null || results.makeSelection.name !== null) {
      this.makeSelection(results.makeSelection);
    }
    return results.result;
  }

  /**
   * setFocus method
   * Set the focus in an specific element
   * @param {HTMLLIElement} element li element to set the focus
   * @param {number} i index from the element to set the focus
   * @returns {void} 
   */
  public setFocus(element: HTMLLIElement, i: number): void {
    element.focus();
    this.focusedOption = i;
  }
}
