import {
  Component,
  OnInit, 
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewChecked,
} from '@angular/core';
import { FormGroup, AbstractControl, Validators } from '@angular/forms';
import { EnhancedFormControl } from 'src/app/models/enhanced-form-control.model';
import { patternValidator } from 'src/app/shared/custom-validators/custom-pattern';
import { DatabaseItemArray } from 'src/app/models/database-item-array.model';
import { InputErrors } from 'src/app/models/input-errors.model';

@Component({
  selector: 'app-database-input-array',
  templateUrl: './database-input-array.component.html',
  styleUrls: ['./database-input-array.component.scss']
})
export class DatabaseInputArrayComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('inputs', { static: false }) inputs: ElementRef;

  @Input() required: boolean = true;
  @Input() arrayName: string;
  @Input() parentForm: FormGroup;
  @Input() arrayControls: EnhancedFormControl[];
  @Input() errors: InputErrors;
  @Input() inputRegex: RegExp;
  @Input() isNewInput: boolean[];
  @Input() inputAdded: boolean;
  @Input() formAdded?: boolean = false;
  @Input() correctInputContent?: boolean = false;
  @Input() interactiveInputs: boolean = false;
  @Input() buttonsDisabled: boolean;
  @Input() inputsCategory: string;

  @Output() onInput: EventEmitter<DatabaseItemArray> = new EventEmitter<DatabaseItemArray>();
  @Output() onBlur: EventEmitter<DatabaseItemArray> = new EventEmitter<DatabaseItemArray>();
  @Output() pressNew: EventEmitter<AbstractControl> = new EventEmitter<AbstractControl>();
  @Output() pressSave: EventEmitter<DatabaseItemArray> = new EventEmitter<DatabaseItemArray>();
  @Output() pressCancel: EventEmitter<AbstractControl> = new EventEmitter<AbstractControl>();
  @Output() disableAllButtons: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onDestroy: EventEmitter<AbstractControl> = new EventEmitter<AbstractControl>();
  @Output() pressDelete: EventEmitter<DatabaseItemArray> = new EventEmitter<DatabaseItemArray>();

  public displayArrayError: boolean = false;
  public formArray: AbstractControl;
  public saveButton: boolean = false;
  public disabledButton: boolean = true;
  public newForm: boolean = false;
  public isFromButton: boolean = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  /**
   * ngOnInit hook
   * set formArray with the parentForm and arrayName received from the parent
   */
  ngOnInit() {
    this.formArray = this.parentForm.get(this.arrayName);

    if (this.formAdded) {
      this.newForm = true;
      this.addNew();
      this.checkForm();
      this.formAdded = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * ngOnDestroy hook
   * call onDestroy event and disableAllButtons event
   */
  ngOnDestroy() {
    this.onDestroy.emit(this.parentForm);
  }

  ngAfterViewChecked() {
    if (this.formArray && this.formArray.value[0]) {
      this.disabledButton = this.parentForm.invalid;
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * checkForErrors method
   * @description set displayError flag to true if there are errors
   * @param {EnhancedFormControl} control form control to test
   * @returns {void}
   */
  public checkForErrors(control: EnhancedFormControl): void {
    if (this.parentForm.invalid) {
      this.displayArrayError = true;
      this.disabledButton = true;
    } else {
      this.displayArrayError = false;
      this.disabledButton = false;
    }

    if (this.formArray.errors) {
      this.displayArrayError = true;
      this.disabledButton = true;
    } else {
      this.displayArrayError = false;
      this.disabledButton = false;
    }

    if (control.errors) {
      control.displayError = true;
      this.disabledButton = true;
    } else {
      control.displayError = false;
      this.disabledButton = false;
    }
  }

  /**
   * trimElement method
   * @description trims the value of the controland emit onBlur Event
   * @param {HTMLInputElement} target HTML Input element to trim
   * @param {EnhancedFormControl} control form control to trim
   * @param {number} index array index for the corresponding control
   * @returns {void}
   */
  public trimElement(target: HTMLInputElement, control: EnhancedFormControl, index: number): void {
    control.setValue(target.value.trim());
    this.onBlur.emit({
      index: index,
      control: control,
      parentForm: this.parentForm,
    });
  }

  /**
   * manageInputs method
   * @description emits event to parent form to
   * add a new input when the user starts writing
   * or to remove it if the user deletes its content
   * and delete call deleteLastInput if the current control has a
   * patter or not unique error
   * @param {event} event input event
   * @param {EnhancedFormControl} control form control to check if has value
   * @param {number} index index in the array of current form control
   * @returns {void} void
   */
  public manageInputs(event, control: EnhancedFormControl, index: number): void {
    /**
     * add a new input when the user starts
     * typing in the LAST input in the array
     * (backspace counts as input, so it checks it
     * as well)
     */ 
    this.checkForErrors(control);
    this.checkInput(control, index);
    this.checkForm();
    this.onInput.emit({
      index: index,
      control: control,
      parentForm: this.parentForm,
    });
    this.isFromButton = false;
  }

  /**
   * setFocusOnEmptyInput method
   * once an input is removed, check for 
   * empty inputs and focus on it
   * @returns {void}
   */
  private setFocusOnEmptyInput(): void {
    const inputChildren = this.inputs.nativeElement.querySelectorAll('.form-control');

    inputChildren.forEach(input => {
      input.focus();
    });
  }

  /**
   * checkInput method
   * @description If the control is empty and its index is not the last
   * set focus in this input
   * @param {EnhancedFormControl} control form control to check
   * @param {number} index index of the form control to check
   * @return {void} void
   */
  private checkInput(control: EnhancedFormControl, index: number): void {
    // no current value in the control
    if (!control.value) {
      if (index !== this.arrayControls.length - 1) {
        this.setFocusOnEmptyInput();
      }

      if (this.required) {
        this.setAsRequired();
      }
    }
  }

  /**
   * setAsRequired method
   * @description if only one input is left, 
   * set that one as required
   * @return {void} void
   */
  private setAsRequired(): void {
    if (this.arrayControls.length === 1) {
      const control = this.arrayControls[0];
      control.setValidators([
        Validators.required, 
        patternValidator(this.inputRegex),
      ]);
      control.updateValueAndValidity();
    }
  }

  /**
   * emitSave method
   * @description change the saveButton variable to false and emit event 
   * to father
   * @returns {void} void
   */
  public emitSave(): void {
    this.saveButton = false;
    this.pressSave.emit({
      index: this.arrayControls.length - 1,
      control: this.arrayControls[this.arrayControls.length -1],
      parentForm: this.parentForm,
    });
    this.disableAllButtons.emit(false);
    this.disabledButton = true;
  }

  /**
   * addNew method
   * @description change the saveButton variable to true and emit event 
   * to father and set isFromButton variable in true for set focus
   * in new input
   * @returns {void} void
   */
  public addNew(): void {
    this.isFromButton = true;
    this.saveButton = true;
    this.pressNew.emit(this.parentForm);
    this.disableAllButtons.emit(true);
  }

  /**
   * checkForm method
   * @description check if the parentForm is valid
   * @returns {void} void
   */
  private checkForm(): void {
    if (this.parentForm.status === 'VALID') {
      this.disabledButton = false;
    } else {
      this.disabledButton = true;
    }
  }

  /**
   * cancel method
   * @description Emit a pressCancel Event to its parent Component
   * if the cancel button was clicked
   */
  public cancel(): void {
    this.saveButton = false;
    this.pressCancel.emit(this.parentForm);
    this.disableAllButtons.emit(false);
    this.disabledButton = true;
  }

  /**
   * onDelete method
   * @description Emit an Event to parent component
   * when the delete button was clicked and this is enabled
   * @param {number} index - Position index where
   * the item to delete is
   * @returns {void} void
   */
  public onDelete(index: number): void {
    if (!(this.saveButton || this.formAdded || this.buttonsDisabled)) {
      this.pressDelete.emit({
        index: index,
        parentForm: this.parentForm,
      });
    }
  }
}
