import { Component } from '@angular/core';
import { version } from 'package.json';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {

  public version: string = `Version: ${version}`;

  constructor() {}

}