import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef, ViewChildren } from '@angular/core';
import { FilterUserPipe } from 'src/app//pipes/filter-user.pipe';
import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { User } from 'src/app/models/user.model';
import { SuggestionsService } from 'src/app/services/suggestions.service';
import { SuggestionsKeyPressedResults } from 'src/app/models/select/select-key-pressed.model';

@Component({
  selector: 'app-user-suggestions',
  templateUrl: './user-suggestions.component.html',
  styleUrls: ['./user-suggestions.component.scss'],
  providers: [FilterUserPipe],
})
export class UserSuggestionsComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() userControl: FormControl;
  @Input() userValidators: ValidatorFn[];
  @Input() userErrors;
  @Input() idControl: FormControl;
  @Input() idValidators: ValidatorFn[];
  @Input() idErrors;
  @Input() list: User[] = [];

  @Output() error = new EventEmitter();

  @ViewChildren('userSuggestionsInput') userSuggestionsInput;
  @ViewChildren('options') options;

  public focusedOption = -1;
  public idHasError: boolean = false;
  public setOverflow: boolean = false;
  public selectedUser: User;
  public filtered: User[] = [];
  public defaultPhotoPath = 'assets/images/profile/default.png';

  constructor(
    private filter: FilterUserPipe, 
    private eRef: ElementRef,
    private suggestionsService: SuggestionsService,
  ) { }

  ngOnInit() {}

  // listen to clicks outside the component
  @HostListener('document:click', ['$event'])
  onGlobalClick(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.checkForIdErrors();
      this.filtered = [];
    }
  }

  /**
   * filterList method
   * filter the provided list of users with the value
   * @param {object} event event object containing the data concerning
   * the event, like the target
   * @return {void}
   */
  public filterList(event): void {
    // check if the user belongs to the list obtained
    const user = this.list.find(element => {
      const username = `${element.firstName} ${element.lastName}`;

      return username.toLowerCase() === event.target.value.trim().toLowerCase();
    });

    // automatically make the selection if a user with that name exists
    if (user) {
      this.makeSelection(user);
      return;
    } else {
      this.selectedUser = null;
      this.idControl.setValue(null);
    }

    // filter the list of users
    this.filtered = this.filter.transform(this.list, event.target.value);

    // check if there are more than four suggestions so that it gets trimmed
    if (this.filtered.length > 4) {
      this.setOverflow = true;
    } else {
      this.setOverflow = false;
    }

    this.reviewUserData();
  }

  /**
   * reviewUserData method
   * removes the photo and data of the selected user
   * @return {void}
   */
  public reviewUserData(): void {
    if (!this.userControl.value) {
      this.selectedUser = null;
    }
  }

  /**
   * makeSelection method
   * select the user to be added to the form
   * @param {user} user data of the user selected
   * @return {void}
   */
  public makeSelection(user: User): void {
    // get the selected user
    this.selectedUser = user;

    // set the input values
    this.idControl.setValue(this.selectedUser.id);
    this.userControl.setValue(`${this.selectedUser.firstName} ${this.selectedUser.lastName}`);

    this.idHasError = false;

    // empty the filtered list of users
    this.filtered = [];
    this.focusedOption = -1;
  }

  /**
   * checkForIdErrors method
   * check if the id control has errors and set the flag
   * @return {void}
   */
  public checkForIdErrors(): void {
    if (this.idControl.errors) {
      this.idHasError = true;
    } else {
      this.idHasError = false;
    }
  }

  /**
   * onKeyDown method
   * Function to handle keyboard events and handle the component with keys
   * @param {KeyboardEvent} event Keyboard-Event to be handled
   * @returns {void} false to cancel the event
   */
  public onKeyPressed(event): boolean {
    const results: SuggestionsKeyPressedResults = this.suggestionsService.onKeyPressed(
      event.key,
      this.focusedOption,
      this.userSuggestionsInput.toArray()[0].inputField,
      this.options.toArray(),
      this.filtered,
    );
    
    this.focusedOption = results.focusedOption;
    if (results.emptyFiltered) {
      this.filtered = [];
    }
    return results.result;
  }

  /**
   * setFocus method
   * Set the focus in an specific element
   * @param {HTMLLIElement} element li element to set the focus
   * @param {number} i index from the element to set the focus
   * @returns {void} 
   */
  public setFocus(element: HTMLLIElement, i: number): void {
    element.focus();
    this.focusedOption = i;
  }
}
