import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employee-check-in-header',
  templateUrl: './employee-check-in-header.component.html',
  styleUrls: ['./employee-check-in-header.component.scss']
})
export class EmployeeCheckInHeaderComponent {

  @Input() employeeName: string;

  constructor(private router: Router) { }

  /**
   * goBack method
   * goes back to the previous route
   * @return {void}
   */
  public goBack(): void {
    this.router.navigateByUrl('checkin/employees');
  }

}
