import { AbstractControl } from '@angular/forms';
import { InvalidDateError } from 'src/app/models/errors/invalid-date-error.model';

import moment from 'moment';

/**
 * Checks if the date is valid
 * @param {AbstractControl} control form control to be validated
 * @returns {object} object with the key of the error name and a value
 * of true if it's valid or null if not
 */
export function validDate(control: AbstractControl): InvalidDateError {
  const date = control.value.split('/');
  const controlMoment = moment([+date[1], (+date[0] - 1)]);

  // determine if the value is after now
  if (!controlMoment.isValid()) {
    return {
      invalidDate: true,
    };
  }

  return null;
}

/**
 * Checks if the complete date is valid
 * @param {AbstractControl} control form control to be validated
 * @returns {object} object with the key of the error name and a value
 * of true if it's valid or null if not
 */
export function completeValidDate(control: AbstractControl): InvalidDateError {
  const date = control.value.split('/');
  const controlMoment = moment([date[2] && (+date[2] + 2000), (+date[0] - 1), +date[1]]);

  // determine if the value is after now
  if (!controlMoment.isValid() || date[2].length < 2) {
    return {
      invalidDate: true,
    };
  }

  return null;
}

/**
 * startDateValidation
 * @description validates the data in the start date input in the project form
 * @param {AbstractControl} control form control to validate
 * @returns {object} object with the key of the error and value to know if
 * the control is valid or not
 */
export function startDateValidation(control: AbstractControl): InvalidDateError {
  if (control.value) {
    const date = control.value.split('/');
    const controlMoment = moment([+date[1], (+date[0] - 1)]);

  // checking the date to know if is valid
    if (!controlMoment.isValid()) {
      return {
        invalidDate: true,
      }
    }
    return null;
  }
}
