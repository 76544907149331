import { Component, Input, AfterContentChecked, ViewChildren, QueryList, ElementRef, AfterViewChecked, OnInit } from '@angular/core';
import { CompleteDataToWorkingHoursReport } from 'src/app/models/reports/complete-data-to-working-hours-report.model';
import moment from 'moment';
import { ProjectToCompleteReport } from 'src/app/models/reports/project-to-complete-report.model';
import { HoursPerDayToCompleteReport } from 'src/app/models/reports/hours-per-day-to-complete-report.model';
import { UserToCompleteReport } from 'src/app/models/reports/user-to-complete-report.model';
import { PeriodToReport } from 'src/app/models/reports/period-to-report.model';

@Component({
  selector: 'app-working-hours-complete-report-preview',
  templateUrl: './working-hours-complete-report-preview.component.html',
  styleUrls: ['./working-hours-complete-report-preview.component.scss'],
})
export class WorkingHoursCompleteReportPreviewComponent implements OnInit, AfterContentChecked, AfterViewChecked {

  @ViewChildren('rowCount') rows: QueryList<ElementRef>;
  @Input() data: CompleteDataToWorkingHoursReport;

  constructor() {
  }

  public dates: Date[] = [];
  public workDaysMessage: string;
  public totalHoursMessage: string;
  public actualDate: HoursPerDayToCompleteReport;
  public userRows = [];
  private startDate: Date;
  private endDate: Date;
  public cellsToAdd: number = 3;
  private actualPeriod: PeriodToReport;
  public actualSegment: string;

  /**
   * ngOnInit hook
   * @description Load the data when is the first time that the component is used
   */
  ngOnInit() {
    this.setDates();
    this.setRenderVariables();
  }

  /**
   * ngAfterContentChecked hook
   * @description Load the dates according with data.period when a period change is detected
   */
  ngAfterContentChecked() {
    if (
      this.actualPeriod.startDate !== this.data.period.startDate
      || this.actualPeriod.endDate !== this.data.period.endDate
    ) {
      this.setDates();
    }
    this.setRenderVariables();
  }

  /**
   * ngAfterViewChecked hook
   * @description Check all the report's content rows to determine it
   * background color
   */
  ngAfterViewChecked() {
    if (this.data.segment) {
      this.determineRowColors();
    }
  }

  /**
   * setDates method
   * @description Set the dates array elements to define the
   * date columns that the report will have
   * @returns {void} void
   */
  private setDates(): void {
    let actualDate = null;

    this.startDate = new Date(moment(this.data.period.startDate).format());
    this.endDate = new Date(moment(this.data.period.endDate).format());
    this.dates = [];

    actualDate = this.startDate;

    while (actualDate <= this.endDate) {
      this.dates.push(new Date(actualDate));
      actualDate.setDate(actualDate.getDate() + 1);
    }

    this.actualPeriod = this.data.period;
  }

  /**
   * formatDate method
   * @description Send a date with the format required by the date columns
   * @param {Date} date - Date to format
   * @returns {string} string
   */
  public formatDate(date: Date): string {
    return moment(date).format('ddd, DD MMM');
  }

  /**
   * userWorkInProject method
   * @description Find if a user has registered hours
   * in a certain project in a certain date
   * @param {string} date - Date to find worked hours
   * @param {ProjectToCompleteReport} project - Project to find
   * worked hours in the given date
   * @returns {HoursPerDayToCompleteReport} HoursPerDayToCompleteReport
   */
  public userWorkInProject(date: string, project: ProjectToCompleteReport):
  HoursPerDayToCompleteReport {
    this.actualDate = null;
    let dateToFind: HoursPerDayToCompleteReport = null;

    if (project.hoursPerDay) {
      dateToFind = project.hoursPerDay
      .find((day) => {
        return day.date === moment(date).format('YYYY-MM-DD');
      });
    }

    if (!dateToFind) {
      dateToFind = {
        date: date,
        hours: 0,
        isValid: this.isValidDate(date),
      }
    }

    this.actualDate = dateToFind;
    return dateToFind;
  }

  /**
   * calculatedExpectedHours method
   * @description Check if a given date not existent in the user
   * must be expected to work or not
   * @param {string} date - Date to check
   * @returns {boolean} boolean
   */
  public isValidDate(date: string): boolean {
    return (moment(date).day() === 0 || moment(date).day() === 6);
  }

  /**
   * determineRowColors method
   * @description Check all the rows to color the rows in the report
   * @returns {void} void
   */
  private determineRowColors(): void {
    const rowsArray = this.rows.toArray();
    let userCounter = 0;
    rowsArray.map((row) => {
      if (row.nativeElement.cells
        && ((row.nativeElement.cells[0].innerText
        && row.nativeElement.cells[1].innerText)
        || (!row.nativeElement.cells[0].innerText
        && !row.nativeElement.cells[1].innerText)
        || (row.nativeElement.cells[0].innerText
        && !row.nativeElement.cells[1].innerText))) {
          userCounter += 1;
      }

      row.nativeElement.className = userCounter % 2 ? 'gray-row' : '';
    });
  }

  /**
   * setTotalHours method
   * @description Check if a certain project has totalHours
   * If it has return this totalHours, if not return 0
   * @param {ProjectToCompleteReport} project - Project to check
   * @returns {number} number
   */
  public setTotalHours(project: ProjectToCompleteReport): number {
    return project.totalHours ? project.totalHours : 0;
  }

  /**
   * userHasProjects method
   * @description Check if a certain user has projects assigned or not
   * when the user filter is applied
   * @param user - User to check
   * @returns {boolean} boolean
   */
  public userHasProjects(user: UserToCompleteReport): boolean {
    return this.data.segment !== 'user' || !!user.projects[0].projectId;
  }

  /**
   * setRenderVariables method
   * @description Set the necessary variables to render the data
   * in certain filters
   * @returns {void} void
   */
  private setRenderVariables(): void {
    if (this.data.segment === 'user' || this.data.segment === 'project') {
      this.cellsToAdd = 2;
    } else {
      this.cellsToAdd = 3;
    }

    this.workDaysMessage = `${this.data.workDays}
    ${this.data.workDays === 1 ? 'workday' : 'workdays'}`;

    this.totalHoursMessage = `total hours: ${this.data.totalHours}`;
    this.actualSegment = this.data.segment || this.actualSegment;
  }
}
