import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

import { ProfileService } from 'src/app/services/profile.service';
import { UserExperienceService } from 'src/app/services/user-experience.service';
import { UtilService } from 'src/app/services/util.service';
import { UserExperience } from 'src/app/models/profile/user-experience.model';

import moment from 'moment';

@Component({
  selector: 'app-experiences-edit',
  templateUrl: './experiences-edit.component.html',
  styleUrls: ['./experiences-edit.component.scss'],
  providers: [UserExperienceService],
})
export class ExperiencesEditComponent implements OnInit {

  @Input() userId: number;

  public showAlert: boolean = false;
  public errorMessage: string;
  public isNewExperience: boolean = false;
  public close: boolean;
  /**
   * the suggestions input component needs to change
   * a bit depending if it is used for location suggestions
   * or not, as the former requires requests to a third-party
   * API
   */
  public isLocationsInput: boolean = true;
  public experience: UserExperience;
  public experienceForm: FormGroup;

  public userIdControl: AbstractControl;
  public positionControl: AbstractControl;
  public companyControl: AbstractControl;
  public locationControl: AbstractControl;
  public fromDateControl: AbstractControl;
  public toDateControl: AbstractControl;
  public responsibilityArrayControl: AbstractControl;

  // to disable save button
  public hasChanges: boolean = false;

  constructor(
    public userExperienceService: UserExperienceService,
    private profileService: ProfileService,
    private utilService: UtilService
  ) { }

  /**
   * ngOnInit hook
   * set up component and subscribe to events
   * @return {void}
   */
  ngOnInit() {
    // set up form
    this.experienceForm = this.userExperienceService.setUpForm(this.userId);

    this.userIdControl = this.experienceForm.get('userId');
    this.positionControl = this.experienceForm.get('position');
    this.companyControl = this.experienceForm.get('company');
    this.locationControl = this.experienceForm.get('location');
    this.fromDateControl = this.experienceForm.get('fromDate');
    this.toDateControl = this.experienceForm.get('toDate');
    this.responsibilityArrayControl = this.experienceForm.get('responsibilities');

    // get copy of experience object
    if (this.profileService.experienceToEdit) {
      this.experience = JSON.parse(JSON.stringify(this.profileService.experienceToEdit));

      // populate fields
      this.userExperienceService.populateFields(this.experience, this.experienceForm);
      this.dirtyExperienceFrom();
    }
  }

  /**
   * checkDifferences method
   * check the current values of the experience
   * with the new values to see if there was a
   * difference introduced
   * @return {boolean}
   */
  public checkDifferences(): boolean {
    // check for differences between input and actual experience
    const fromDate = moment(this.experience.fromDate).format('MM/YYYY');
    const toDate = moment(this.experience.toDate).format('MM/YYYY');
    const filteredResponsibilities = this.experienceForm.value.responsibilities.filter((responsibility) => responsibility);

    if (
      this.experience.position !== this.experienceForm.value.position ||
      this.experience.company !== this.experienceForm.value.company ||
      this.experience.location !== this.experienceForm.value.location ||
      fromDate !== this.experienceForm.value.fromDate ||
      toDate !== this.experienceForm.value.toDate ||
      !this.utilService.areArraysEqual(this.experience.responsibilities, filteredResponsibilities)
    ) {
      return true;
    }

    return false;
  }

  /**
   * onSubmit method
   * submits the edit form
   * @return {void}
   */
  public onSubmit(): void {
    // remove the user id
    delete this.experienceForm.value.userId;

    const experienceData = this.experienceForm.value;

    /**
     * go back to display experiences if there are no differences
     * in the data provided
     */
    if (!this.checkDifferences()) {
      this.goToExperiencesView();
      return;
    }

    /**
     * if the data is invalid, do not do anything until
     * user fixes the errors
     */
    if (this.experienceForm.invalid) {
      return;
    }

    // get rid of empty values in responsibilities
    experienceData.responsibilities = experienceData.responsibilities.filter(responsibility => responsibility);

    // format dates to what the back end expects
    experienceData.fromDate = moment(experienceData.fromDate, ['MM/YYYY']).format('YYYY-MM');

    experienceData.toDate = moment(experienceData.toDate, ['MM/YYYY']).format('YYYY-MM');

    this.profileService
      .updateExperience(
        this.experience.id,
        this.experienceForm.value
      ).subscribe((success) => {
        // send the success object to define that the experience was updated
        this.goToExperiencesView(true);
      },  (error) => {
        // show an alert with an error
        this.showAlert = true;
        this.errorMessage = 'Something went wrong. Please, try again later.';
      }
    );
  }

  /**
   * goToExperiencesView method
   * goes to the experiences view
   * @param {boolean} experienceUpdated flag that determines if
   * the experience was updated or not
   * @return {void}
   */
  public goToExperiencesView(experienceUpdated: boolean = false): void {
    experienceUpdated ?
      this.profileService.experienceUpdated.next() :
      this.profileService.displayExperiencesView.next();
  }

  /**
   * correctForm method
   * verify if inputs of edit experience are correct
   * @returns {boolean}
   */
  public correctForm(): boolean {
    return this.positionControl.valid &&
      this.companyControl.valid &&
      this.locationControl.valid &&
      this.fromDateControl.valid &&
      this.toDateControl.valid &&
      this.responsibilityArrayControl.valid;
  }

  /**
   * dirtyExperienceFrom method
   * verify if someone input has changes
   * @returns {void}
   */
  private dirtyExperienceFrom(): void {
    const filteredResponsibilities: string[] =
    this.experienceForm.value.responsibilities.filter((responsibility) => responsibility);

    this.experienceForm.valueChanges.subscribe((element) => {
      for (const iterator in element.responsibilities) {
        if (!element.responsibilities[iterator]) {
          element.responsibilities.splice(iterator, 1);
        }
      }

      if (
        element.position !== this.experience.position ||
        element.company !== this.experience.company ||
        element.location !== this.experience.location ||
        element.fromDate !== moment(this.experience.fromDate).format('MM/YYYY') ||
        element.toDate !== moment(this.experience.toDate).format('MM/YYYY') ||
        !this.utilService.areArraysEqual(element.responsibilities, filteredResponsibilities)
      ) {
        this.hasChanges = true;
      } else {
        this.hasChanges = false;
      }
    });
  }

  /**
   * emitClick
   * uses the flag to close the date pucker calendar and the suggestions list
   * @param event {boolean} flag
   */
  public emitClick(event): void {
    this.close = event;
  }
}
