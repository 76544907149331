import { Component, Input, Output, EventEmitter} from '@angular/core';

import { ProfileService } from 'src/app/services/profile.service';
import { UserDescription } from 'src/app/models/profile/user-description.model';

@Component({
  selector: 'app-user-description',
  templateUrl: './user-description.component.html',
  styleUrls: ['./user-description.component.scss']
})
export class UserDescriptionComponent {
  @Input() userId: number;
  @Input() description: UserDescription[];
  @Input() personalInfoInEdit: boolean;

  @Output() onEdit: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  // default view
  public displayDescriptionView: boolean = true;
  public editDescriptionView: boolean = false;

  constructor(private profileService: ProfileService) { }

  /**
   * goToDisplayDescriptionView method
   * sets all the flags for the other views
   * to false except the one for Description list view
   * and emits Subject to show buttons
   * @return {void}
   */
  public goToDisplayDescriptionView(): void {
    this.displayDescriptionView = true;
    this.editDescriptionView = false;
    this.onEdit.emit(false);
    this.profileService.showButtons.next();
  }

  /**
   * goToEditDescriptionView method
   * sets all the flags for the other views
   * to false except the one for the add
   * form and emits the Subject to hide buttons
   * @return {void}
   */
  public goToEditDescriptionView(): void {
    this.displayDescriptionView = false;
    this.editDescriptionView = true;
    this.onEdit.emit(true);
    this.profileService.editSummaryView.next();
  }
}
