import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { version } from 'package.json';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {

  public version: string = `Version: ${version}`;

  //flags to change views
  public viewOne: boolean = true;
  public viewTwo: boolean = false;
  public viewThree: boolean = false;

  constructor() { }

  // form signup
  public formSignUp: FormGroup = new FormGroup({});

  /**
   * switchSecondView method
   * change the first view to the second view
   * @param {FormGroup} form content of the first view form
   */
  public switchSecondView(form: FormGroup): void {
    this.formSignUp.addControl('formOne', form);
    this.viewOne = false;
    this.viewTwo = true;
  }

  /**
   * switchSecondView method
   * change the second view to the third view
   * @param {FormGroup} form content of the second view form
   */
  public switchThirdView(form: FormGroup): void {
    this.formSignUp.addControl('formTwo', form);
    this.viewTwo = false;
    this.viewThree = true;
  }
}
