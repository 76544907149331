import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
} from '@angular/core';
import { Time } from 'src/app/models/shared/time.model';
import moment, { Moment } from 'moment';
import {CalendarService} from 'src/app/services/calendar.service';
import { DatePipe } from '@angular/common';
import { DateStatus } from 'src/app/models/getCalendarResponse.model';
import { PeriodToReport } from 'src/app/models/reports/period-to-report.model';
import { NestedSelectorOption } from 'src/app/models/select/nested-selector-option.model';
import { UserLocationFilter } from 'src/app/models/user-location-filter.model';

@Component({
  selector: 'app-month',
  templateUrl: './month.component.html',
  styleUrls: ['./month.component.scss'],
  providers: [CalendarService, DatePipe],
})
export class MonthComponent implements OnInit {
  @Input() actualPeriod: PeriodToReport;
  @Input() actualLocation: NestedSelectorOption;

  @Output() status: EventEmitter<string> = new EventEmitter<string>();
  @Output() dateChange: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();

  public weekDays: string[] = [];
  public currentDate: Moment = null;
  public selectedMoment: Moment = null;
  public currentMonth: string = null;
  public currentYear: string = null;
  public dates: Moment[] = [];
  public dateStatus: DateStatus[] = [];
  public selectedDate: Moment = null;
  public monthName: string = null;
  public statusObj: object = {};
  public currentTime: Time = null;
  public periodChanged: boolean = false;

  constructor(
    private calendarService: CalendarService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    // set the weekdays to display in calendar
    this.weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

    this.selectedMoment = moment.utc();
    this.currentDate = moment.utc();
    this.selectedDate = moment.utc();
    this.monthName = moment.utc().format('MMMM').toUpperCase();
    this.selectDate(this.currentDate);
    this.getMonthsDates();

    let fromDate = moment(this.dates[0]);
    let toDate = moment(this.dates[this.dates.length-1]);
    this.getCalendarData(fromDate, toDate);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.actualLocation && this.actualPeriod) {

      if (changes.actualPeriod) {
        this.periodChanged = true;
      }

      this.getCalendarData(
        moment(this.actualPeriod.startDate),
        moment(this.actualPeriod.endDate),
      );
    }
  }

  /**
   * getCalendarData method
   * sets the new value of the form control
   * with the month, date and year selected
   * and render the data according with the BE response
   * @param {Moment} startDate - Start the period
   * @param {Moment} endDate - End the period
   * @return {void}
   */
  public getCalendarData(
    startDate: Moment,
    endDate: Moment,
  ): void {
    let location: UserLocationFilter = null;

    if (this.actualLocation.id) {
      location = {
        id: this.actualLocation.id,
        type: this.actualLocation.children ? 'regionId' : 'locationId',
      }
    }
    
    this.calendarService.getCalendarStatus(
      this.datePipe.transform(startDate, "yyyy-MM-dd"),
      this.datePipe.transform(endDate, "yyyy-MM-dd"),
      location,
    ).subscribe(result => {
      this.dateStatus = result.data;
      this.dateStatus.forEach(day => {
        if(day.status === 'holiday'){
          this.statusObj[moment(day.date).format('YYYY-MM-DD')] = day.status;
        } else {
          delete this.statusObj[moment(day.date).format('YYYY-MM-DD')];
        }
      });

      this.getMonthsDates();
    });
  }

  /**
   * selectDate method
   * @description Sets the new value of the form control
   * with the month, date and year selected
   * @param {moment.Moment} date - Date to select
   * @returns {void} void
   */
  public selectDate(date: moment.Moment): void {
    this.selectedDate = date.clone();

    this.dateChange.emit(date);

    if (this.statusObj[moment(this.selectedDate).format('YYYY-MM-DD')]) {
      this.status.emit('Holiday');
    } else {
      this.status.emit('Working Day');
    }
  }

  /**
   * getMonthsDates method
   * @description Get the data from the selected month's dates.
   * By default, it selects the current month's dates
   * @returns {void} void
   */
  private getMonthsDates(): void {
    this.dates = [];
    this.dateStatus = [];
    this.currentTime = {
      year: moment(this.actualPeriod.startDate).year(),
      month: moment(this.actualPeriod.startDate).month(),
    }

    const daysInMonth = moment(
      `${this.currentTime.year}-${this.currentTime.month + 1}`,
      'YYYY-MM').daysInMonth();

    this.getLastDaysOfPastMonth(this.currentTime);

    for (let i = 0; i < daysInMonth; i += 1) {
      const date = moment({ ...this.currentTime, date: i + 1 });
      this.dates.push(date);

      /* When you are not in the current month and period filter was changed
         the selected day will be the first in the month */
      if (!i
        && this.periodChanged
        && !this.currentDate.isBetween(
          this.actualPeriod.startDate,
          this.actualPeriod.endDate,
        )
      ) {
        this.selectDate(this.dates[this.dates.length - 1]);
      }
    }

    /* When you are in the current month and period filter was changed
       the selected day will be today */
    if (this.periodChanged
      && !this.selectedDate.isSame(this.actualPeriod.startDate)) {
      this.selectDate(moment.utc());
    }

    /* When period filter was not changed
       the selected day is the previous selected */
    if (!this.periodChanged) {
      this.selectDate(this.selectedDate);
    }

    this.periodChanged = false;
    this.getFirstDaysOfNextMonth(this.currentTime); 
  }

  /**
   * getLastDaysOfPastMonth method
   * add to the dates array the dates of the past
   * month that get introduced in the first week
   * of the current month
   * @param {Time} time month and year to work with
   * @return {void}
   */
  private getLastDaysOfPastMonth(time: Time): void {
    const startOfMonth = moment.utc()
      .year(time.year)
      .month(time.month)
      .startOf('month');

    const weekDay = startOfMonth.day();

    for (let i = 0; i < weekDay; i += 1) {
      // add them at the beginning of the array
      this.dates.unshift(
        moment(startOfMonth.subtract(1, 'd'))
      );
    }
  }

    /**
   * getFirstDaysOfNextMonth method
   * add to the dates array the dates of next week
   * that are part of the last week of the
   * current month
   * @param {Time} time month and year to work with
   * @return {void}
   */
  private getFirstDaysOfNextMonth(time: Time): void {
    const endOfMonth = moment.utc()
      .year(time.year)
      .month(time.month)
      .endOf('month');

    const weekDay = endOfMonth.day();

    /**
     * should the last day not be saturday
     * as it is the last column in the calendar
     */
    if (weekDay !== 6) {
      for (let i = 0; i < (6 - weekDay); i += 1) {
        // add them at the end of the array
        this.dates.push(
          moment(endOfMonth.add(1, 'd'))
        );
      }
    }
  }
}
