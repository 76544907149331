import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  ViewChildren,
} from '@angular/core';
import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { NestedSelectorOption } from 'src/app/models/select/nested-selector-option.model';

import { CapitalizePipe } from 'src/app/pipes/capitalize.pipe';
import { SelectService } from 'src/app/services/select.service';
import { KeyPressedResults } from 'src/app/models/select/select-key-pressed.model';

@Component({
  selector: 'app-nested-selector',
  templateUrl: './nested-selector.component.html',
  styleUrls: ['./nested-selector.component.scss'],
  providers: [CapitalizePipe]
})
export class NestedSelectorComponent implements OnInit, OnChanges {

  @Input() parentForm: FormGroup;
  @Input() control: FormControl;
  @Input() validators: ValidatorFn[];
  @Input() errors;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() options: NestedSelectorOption[] = [];
  @Input() capitalizeOptions: boolean; 
  @Input() isInHeader: boolean = false;
  /* flag to determine if the value passed in the form by the
  * component should be the hidden input or the plain text
  */
  @Input() nullId: boolean = false;
  @Input() default: NestedSelectorOption;
  
  @ViewChildren('optionsElements') optionsElements;

  public defaultSet: boolean = false;
  public showOptions: boolean = false;
  public setOverflow: boolean = false;
  public selectedOption: NestedSelectorOption = {
    id: null,
    name: '',
  };
  public focusedOption = -1;
  public selectedOptionPosition: number = -1;
  public errorKeys: string[];
  public errorMessages: string[];
  public displayError: boolean = false;

  constructor(
    public capitalize: CapitalizePipe,
    private eRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private selectService: SelectService,
  ) { }

  ngOnInit() {
    if (this.default) {
      this.makeSelection(this.default);
      this.showOptions = false;
    }

    if (this.options) {
      this.options.forEach(element => {
        if (element === this.control.value) {
          this.select(element);
        }
      });
    }

    if (this.placeholder) {
      this.placeholder = this.capitalize.transform(this.placeholder);
    }

    this.setValidators();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.default && changes.default.currentValue) {
      this.control.setValue(changes.default.currentValue);
      this.select(changes.default.currentValue);
    }
  }

  /**
   * onGlobalClick method
   * determine if the user clicks outside of the
   * options list
   * @return {void}
   */
  @HostListener('document:click', ['$event'])
  onGlobalClick(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }

  /**
   * openOptions method
   * toggle the options list
   * @return {void}
   */
  public openOptions(): void {
    if (this.options.length > 4) {
      this.setOverflow = true;
    }

    this.showOptions = !this.showOptions;
    this.focusedOption = -1;
  }

  /**
   * makeSelection method
   * select an option from the list and set the value to the control
   * @param {NestedSelectorOption} option set this value in the control and 
   * in the select
   * @return {void}
   */
  public makeSelection(option: NestedSelectorOption): void {
    this.control.setValue(option);
    this.select(option);
    this.showOptions = false;
  }

  /**
   * checkForErrors method
   * toggle a flag to display the errors
   * @return {void}
   */
  public checkForErrors(): void {
    if (this.control.errors) {
      this.displayError = true;
    } else {
      this.displayError = false;
    }
  }

  /**
   * setValidators method
   * sets the validators for the form control
   * of this component and updates it to be
   * retroactive, emitting an event at the same
   * time
   * @returns {void}
   */
  public setValidators(): void {
    this.control.setValidators(this.validators);

    // make the validation retroactive
    this.control.updateValueAndValidity();
  }

  /**
   * select method
   * Method to set the values from a specific selected option
   * @param {NestedSelectorOption} option option to be selected
   * @returns {void} 
   */
  private select(option: NestedSelectorOption): void {
    this.selectedOption = option;
    this.selectedOptionPosition = this.getNestedOptionIndex(option);
    this.focusedOption = -1;
  }

  /**
   * onKeyDown method
   * Function to handle keyboard events and handle the component with keys
   * @param {KeyboardEvent} event Keyboard-Event to be handled
   * @returns {void} false to cancel the event
   */
  public onKeyPressed(event: KeyboardEvent): boolean {
    const results: KeyPressedResults = this.selectService.onKeyPressed(
      event.key,
      this.showOptions,
      this.focusedOption,
      this.optionsElements.toArray(),
      this.selectedOptionPosition,
      this.getFlatOptions(),

    );

    this.showOptions = results.showOptions;
    this.focusedOption = results.focusedOption;
    if (results.makeSelection.id !== null || results.makeSelection.name !== null) {
      this.makeSelection(results.makeSelection);
    }
    return results.result;
  }

  /**
   * setFocus method
   * Set the focus in an specific element
   * @param {HTMLLIElement} element li element to set the focus
   * @param {number} i index from the element to set the focus
   * @returns {void} 
   */
  public setFocus(element: HTMLLIElement, option): void {
    element.focus();
    this.focusedOption = this.getNestedOptionIndex(option);
  }

  /**
   * getNestedOptionIndex method
   * get index of an specific option
   * @param {NestedSelectionOption} option - option (or nested option) to get index
   * @returns {number} index of the option
   */
  public getNestedOptionIndex(option: NestedSelectorOption): number {
    return this.getFlatOptions().findIndex(element => element === option);
  }

  /**
   * getFlatOptions method
   * get all the options from the array whit its children
   * @returns {NestedSelectorOption}
   */
  public getFlatOptions(): NestedSelectorOption[] {
    return this.options.reduce((options, option) => {
      options.push(option);
      if (option.children) {
        option.children.forEach(child => options.push(child));
      }
      return options;
    }, []);
  }
}
