import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NestedSelectorOption } from 'src/app/models/select/nested-selector-option.model';
import { WorkingHoursReportService } from 'src/app/services/reports/working-hours-report.service';
import { RegionLocationsResponse, RegionLocations } from 'src/app/models/region.model';
import { UserLocationFilter } from 'src/app/models/user-location-filter.model';

@Component({
  selector: 'app-user-filters',
  templateUrl: './user-filters.component.html',
  styleUrls: ['./user-filters.component.scss']
})

export class UserFiltersComponent implements OnInit {
  @Output() locationChange: EventEmitter<UserLocationFilter> = new EventEmitter<UserLocationFilter>();

  public locationControl: FormControl = new FormControl('', []);
  public emptyValidators: Validators[] = [];
  public emptyErrors = {};

  public filtersForm: FormGroup = new FormGroup({
    location: this.locationControl
  });

  public locations: NestedSelectorOption[] = [
    { id: null, name: 'All locations' },
  ];

  constructor(
    private workingHoursReportService: WorkingHoursReportService
  ) { }

  ngOnInit() {
    this.putLocationsSelectOptions();

    /*
      When selector value changes emit an event
      with the id and if is region or location
    */
    this.locationControl.valueChanges.subscribe(
      (value: NestedSelectorOption) => {
        this.locationChange.emit({
          id: value.id,
          type: value.children ? 'regionId' : 'locationId',
        });
      }
    );
  }

  /**
   * putLocationsSelectOptions method
   * @description Put the options for the locations in the select
   * @returns {void} void
   */
  private putLocationsSelectOptions(): void {
    this.workingHoursReportService.getRegions().subscribe(
      (response: RegionLocationsResponse) => {
        response.data.forEach((region: RegionLocations) => {
          this.locations.push({
            id: region.id,
            name: region.name,
            children: region.locations,
          });
        });
      }
    );
  }
}
