import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginCredentials } from '../models/login-credentials.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginResponse } from '../models/login-response.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) {}

  /**
   * login method returns an Observable 
   * with data of the user credentials to the database
   * @param {LoginCredentials} credentials are the email and password
   * @returns {Observable} login response
   */
  public login(credentials: LoginCredentials): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${environment.apiUrl}/users/login`, credentials)
  }

  /**
   *isLoggedIn method returns access token
   * @returns {boolean} boolean
   */
  public isLoggedIn(): boolean {
    const token = localStorage.getItem('auth');
    return !!token;
  }
}
