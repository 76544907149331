import { Component, OnInit, AfterViewInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';

import { MyProjectsService } from 'src/app/services/my-projects.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { ProjectListItem } from 'src/app/models/projects/project-list-item.model';
import { Router } from '@angular/router';
import { ProjectFiltersData } from 'src/app/models/project-filters-data.model';

@Component({
  selector: 'app-my-projects',
  templateUrl: './my-projects.component.html',
  styleUrls: ['./my-projects.component.scss']
})
export class MyProjectsComponent implements OnInit, AfterViewInit, AfterViewChecked {

  public projectsLoaded: boolean = false;
  public userId: number;
  public projects: ProjectListItem[] = [];
  public projectPhotos: string[] = [];
  public usersInProjectMessage: string;
  
  private newItems: ProjectListItem[] = [];
  // page to obtain from the database (default 1)
  private page: number = 1;
  private search: string;
  // search for active projects by default
  private status: string;
  // search for user projects by default
  private involved: string;
  public filtersData: ProjectFiltersData = null;

  constructor(
    private myProjectsService: MyProjectsService,
    private projectsService: ProjectsService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  /**
   * ngOnInit hook
   * set the user id and the default parameters to search
   * for the projects
   * @return {void}
   */
  ngOnInit() {
    const userId = +localStorage.getItem('id');

    // validate if the id is a number
    this.userId = !isNaN(userId) ? userId : 0;

    if (history.state.data) {
      this.status = history.state.data.status;
      this.involved = history.state.data.involved;
      this.filtersData = history.state.data;
    } else {
      // set default filters
      this.status = 'active';
      this.involved = this.userId.toString();
    }
  }

  /**
   * ngAfterViewInit hook
   * subscribe to changes in the filter dropdowns to change the
   * parameters to search for projects
   * @return {void}
   */
  ngAfterViewInit() {
    // subscribe to changes in the form controls
    this.myProjectsService.statusControl.valueChanges.subscribe((value) => {
      this.status = value === 'Active' ? 'active' : 'inactive';
      this.prepareRequest();
    });

    this.myProjectsService.involvedControl.valueChanges.subscribe((value) => {
      this.involved = value === 'My Projects' ? this.userId.toString() : 'all';
      this.prepareRequest();
    });
  }

  /**
   * ngAfterViewChecked hook
   * check if the items have been rendered and run the change detection
   * system to tell the pagination directive to run again if necessary
   * @return {void}
   */
  ngAfterViewChecked() {
    if (this.projects.length && !this.projectsLoaded) {
      this.projectsLoaded = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * prepareRequest method
   * make the preparations before obtaining a new list
   * of projects based on new changes to the parameters
   * @return {void}
   */
  private prepareRequest(): void {
    // reset to page one in projects pagination
    this.page = 1;
    // empty the current projects displayed
    this.projects = [];
    // get the new projects
    this.getProjects();
  }

  /**
   * setProjectsMessage method
   * sets the property that will contain the message that shows how many users
   * are part of each project 
   * @return {void}
   */
  private setProjectsMessage(): void {
    this.newItems.forEach(project => {
      if (project.users.length > 2 && project.userInProject) {
        project.usersInProjectMessage = `You and ${project.users.length - 1} users have this project assigned.`;
      }

      if (project.users.length === 2 && project.userInProject) {
        project.usersInProjectMessage = `You and other user have this project assigned.`;
      }

      if (project.users.length > 1 && !project.userInProject) {
        project.usersInProjectMessage = `${project.users.length} users have this project assigned.`;
      }

      if (project.users.length === 1) {
        if (project.userInProject) {
          project.usersInProjectMessage = 'You are the only user that have this project assigned.';
        } else {
          project.usersInProjectMessage = 'Only one user has this project assigned.';
        }
      }

      if (!project.users.length) {
        project.usersInProjectMessage = 'No user has this project assigned.';
      }
    });
  }

  /**
   * getProjects method 
   * method that uses the projects service to obtain the projects
   * (20 each time)
   * @returns {void}
   */
  public getProjects(): void {
    this.projectsService.getProjects(
      this.page, 
      this.search, 
      this.status, 
      this.involved
    ).subscribe(result => {
      this.newItems = result.data.items;
      this.projectsLoaded = true;
      if (this.newItems.length > 0) {
        this.projects.push(...this.newItems);

        // set the alt names of the projects' images
        this.projects.forEach(project => {
          const projectName = `${project.name[0].toUpperCase()}${project.name.slice(1)}`;

          this.projectPhotos.push(`${projectName}'s logo`);
        });

        // validate if user is part of the project
        this.validateIfUserInProject();

        // set the users messages for each project
        this.setProjectsMessage();

        this.page += 1;
      }
    });
  }

  /**
   * validateIfUserInProject method 
   * method that uses the projects service to obtain the projects
   * (20 each time)
   * @returns {void}
   */
  public validateIfUserInProject(): void {
    this.newItems.forEach(project => {
      project.userInProject = project.users.includes(this.userId);
    });
  }

  /**
   * selectProject method
   * passes the id of the project to display
   * @param {number} id id of the project to display 
   * @returns {void} void
   */
  public selectProject(id: number): void {
    // this.router.navigateByUrl(`dashboard/projects/${id}`);
    this.router.navigate([`dashboard/projects/${id}`], {
      state: {
        data: {
          status: this.status,
          involved: this.involved,
        }
      }
    });
  }
}
