import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TheOneDateCheckInsResponse } from 'src/app/models/responses/check-in-response.model';
import { environment } from 'src/environments/environment';
import { Response } from 'src/app/models/response.model';
import { UpdateCheckIn } from 'src/app/models/update-check-in.model';


@Injectable({
  providedIn: 'root'
})

export class CheckInService {

  constructor(private http: HttpClient) {}

  /**
   * getTheOneDateCheckIns method
   * get the registers (check in) from one date
   * @param {string} eventFilter event to filter
   * @param {number} locationId location to filter
   * @param {string} order way to sort the data (asc or desc)
   * @param {string} columnToOrder by which column to sort the data
   * @param {string} date - date to get the registers
   * @returns {void}
   */
  public getTheOneDateCheckIns(
    eventFilter: string = null,
    locationId: number,
    order: string,
    columnToOrder: string = null,
    date: string
  ): Observable<TheOneDateCheckInsResponse> {
    const queryParams = {
      params: new HttpParams()
        .set('locationId', locationId.toString())
        .set('order', order)
        .set('date', date),
    };

    // an event filter was applied
    if (eventFilter) {
      queryParams.params = queryParams.params.set('filter', eventFilter);
    }

    // a column to order filter was applied
    if (columnToOrder) {
      queryParams.params = queryParams.params.set('column', columnToOrder);
    }

    return this.http.get<TheOneDateCheckInsResponse>(
      `${environment.apiUrl}/check-ins`,
      queryParams
    ).pipe(
      catchError((error) => {
        return of (error);
      }),
    );
  }

  /**
   * updateUserCheckIn method
   * call to backend to update the user check in in a specific date
   * @param {number} userId id of user to update your check in
   * @param {UpdateCheckIn} data data to set in the check in of the date
   * @param {string} date check in date
   * @returns {void}
   */
  public updateUserCheckIn(userId: number, data: UpdateCheckIn, date: string): Observable<Response> {
    return this.http.post<Response>(
      `${environment.apiUrl}/check-ins/${userId}/`,
      { ...data, date },
    ).pipe(
      catchError(error => {
        return of (error);
      }),
    );
  }

}
