import { Directive, AfterViewInit, Input, ElementRef, ChangeDetectorRef } from '@angular/core';

@Directive({
  selector: '[appDinamicInputs]'
})
export class DinamicInputsDirective implements AfterViewInit {
  
  @Input() nextFocus: boolean;
  @Input() nameSkills: string;
  private element: HTMLInputElement;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }
  
  /**
   * ngAfterViewInit method
   * set focus to last input created dinamicly
   * @returns {void}
   */
  ngAfterViewInit(): void {
    if (this.nextFocus) {
      setTimeout(() => {
        if (this.nameSkills !== 'skill1') {
          this.element.focus();
        }
      }, 0);
    }
  }
}
