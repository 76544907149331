import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { dateBeforeMin } from 'src/app/shared/custom-validators/date-before-min.validator';

import { Time } from 'src/app/models/shared/time.model';
import { Error } from 'src/app/models/errors/error.model';

import moment, { Moment } from 'moment';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent implements OnInit {

  @Input() isNew: boolean = true;
  @Input() parentForm: FormGroup;
  @Input() control: FormControl;
  @Input() validators: ValidatorFn[];
  @Input() inputName: string;
  @Input() errors: Error;
  @Input() minDate: Moment = null;
  @Input() maxDate: Moment = null;

  @Output() monthError: EventEmitter<string> = new EventEmitter<string>();
  @Output() errorSolved: EventEmitter<string> = new EventEmitter<string>();

  public currentDate: Moment;
  public currentDateString: string;
  public currentMonth: string;
  public currentYear: string;
  public selectedMoment: Moment;
  public dates: Moment[] = [];
  public weekDays: string[];
  public open: boolean = false;
  public displayError: boolean = false;
  public errorMessage: string;

  constructor(private elementRef: ElementRef) { }

  /**
   * onGlobalClick method
   * determine if the user clicks outside
   * the months selector
   * @param {object} event click event listened
   * @return {void}
   */
  @HostListener('document:click', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.open = false;
    }
  }

  /**
   * ngOnInit hook
   * set up the necessary data to create
   * the component, like the dates and weekdays
   * @return {void}
   */
  ngOnInit(): void {

    // set the weekdays to display in calendar
    this.weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    
    /**
     * if the user is editing the experience,
     * set the selected moment to what the experience
     * data specifies
     */
    this.selectedMoment = this.isNew ? moment() : moment(this.control.value);

    // set the value of the control based on the selected moment
    this.control.setValue(this.selectedMoment.format('MM/DD/YY'));

    this.currentDate = moment();

    // set the current date to display in calendar
    this.currentDateString = this.currentDate.format('L');

    // set the current and year to display in calendar
    this.currentMonth = this.selectedMoment.format('MMM');
    this.currentYear = this.selectedMoment.format('YYYY');

    this.getMonthsDates();

    // set the validators
    this.setValidators();

    this.control.valueChanges.subscribe(() => {
      // if there are errors with the input, hide the calendar
      if (this.control.errors) {
        this.checkForErrors();

        /**
         * but still get the dates if the error is not because of
         * a wrongly formatted date
         */
        if (!this.control.errors.invalidDate) {
          this.getDates();
        }
      } else {
        this.getDates();

        this.checkForErrors();
      }
    });
  }

  /**
   * ngOnChanges hook
   * detects changes on input values to respond to them
   * @param {SimpleChanges} changes object containing the
   * changes of the input properties
   * @return {void}
   */
  ngOnChanges (changes: SimpleChanges) {
    
    // change the minimum value
    if (changes.minDate && this.control.value) {
      const validatorIndex = this.validators.findIndex(validator => !validator.name);

      if (validatorIndex !== -1) {
        this.validators[validatorIndex] = dateBeforeMin(changes.minDate.currentValue);
      }

      this.control.setValidators(this.validators);
      this.control.updateValueAndValidity();
    }
  }

  /**
   * setValidators method
   * sets the validators for the form control
   * of this component and updates it to be
   * retroactive, emitting an event at the same
   * time
   * @returns {void}
   */
  public setValidators(): void {
    this.control.setValidators(this.validators);
    
    // make the validation retroactive
    this.control.updateValueAndValidity();
  }

  /**
   * toggleCalendar method
   * sets the flag that opens the
   * calendar to either true or false
   * @return {void}
   */
  public toggleCalendar(): void {
    if (this.control.errors) {
      this.dates = [];
      this.selectedMoment = moment();
      this.getMonthsDates();
    }

    this.open = !this.open;
  }

  /**
   * formatDateInput method
   * formats the input the user is entering to a proper
   * date the component can accept
   * @param {Event} event input event triggered
   * @return {void}
   */
  public formatDateInput(event): void {
    // set the format of the month input field
    let dateInput = this.control.value;

    // replace anything that is not a number or slash
    dateInput = dateInput.replace(/[^/\d]+/g, '');

    // format the input
    if (
      event.inputType !== 'deleteContentBackward'
      && event.inputType !=='deleteContentForward'
    ) {
      // add a slash between more than 2 joined digits
      dateInput = dateInput.replace(/(\d{2})(\d)/gm, '$1/$2').replace(/(\d{2})(\d)/gm, '$1/$2');

      // add a zero at the beginning of the month
      dateInput = dateInput.replace(/(^)([1-9])(?!$|\d)/gm, '0$2');
      // add a zero at the beginning of the month and date
      if (dateInput.split('/').length === 1) {
        // month
        dateInput = dateInput.replace(/(^)([2-9])(\/|$)/gm, '0$2/');
        dateInput = dateInput.replace(/(\d{2})(?=$)/gm, '$1/');
      } else if (dateInput.split('/').length === 2) {
        // day
        dateInput = dateInput.replace(/(\/)([4-9])(\/|$)/gm, '/0$2');
        dateInput = dateInput.replace(/(\d{2})(?=$)/gm, '$1/');
      } else{
        // add zero at the beginning of the incomplete day
        dateInput = dateInput.replace(/(\/)([1-9])(?!$|\d)/gm, '/0$2');
      }
    }

    // delimit input to 8 characters
    if (dateInput.length > 8) {
      dateInput = dateInput.substring(0, 8);
    }

    if (this.control.value !== dateInput) {
      this.control.setValue(dateInput);
    }

    this.dates = [];

    this.open = false;

    // if there are errors with the input, hide the calendar
    if (this.control.errors) {
      this.checkForErrors();

      /**
       * but still get the dates if the error is not because of
       * a wrongly formatted date
       */
      if (!this.control.errors.invalidDate) {
        this.getDates();
      }
    } else {
      this.getDates();

      this.checkForErrors();
    }
  }

  /**
   * getDates method
   * gets the dates established by the input
   * @return {void}
   */
  private getDates(): void {
    this.selectedMoment = moment(this.control.value, ['MM/DD/YY']);

    // get the month's dates
    this.dates = [];
    this.getMonthsDates();
  }

  /**
   * addToMonthIndex method
   * adds 1 to the month index
   * to move to the next month,
   * while emptying the dates array
   * @return {void}
   */
  public addToMonthIndex(): void {

    // let user select a month only if it is before the max value
    if (!this.maxDate || this.selectedMoment.isBefore(this.maxDate, 'month')) {
      this.dates = [];
      this.getMonthsDates(1);
    }
  }

  /**
   * subtractToMonthIndex method
   * subtracts 1 to the month index
   * to move to the previous month,
   * while emptying the dates array
   * @return {void}
   */
  public subtractToMonthIndex(): void {

    // let the user select a month only if it is after the min value
    if (!this.minDate || this.selectedMoment.isAfter(this.minDate, 'month')) {
      this.dates = [];
      this.getMonthsDates(-1);
    }
  }

  /**
   * selectDate method
   * sets the new value of the form control
   * with the month, date and year selected
   * @return {void}
   */
  public selectDate(date: moment.Moment): void {
    this.selectedMoment = date.clone();
    this.control.setValue(this.selectedMoment.format('MM/DD/YY'));
    this.toggleCalendar();
    this.checkForErrors();
  }

  /**
   * checkForErrors method
   * set displayError flag to true if there are errors
   * changes in validators
   * @returns {void}
   */
  private checkForErrors(): void {
    if (this.control.errors) {
      this.displayError = true;

      if (this.errors) {
        for (let [key, value] of Object.entries(this.errors).sort()) {
          if (this.control.errors[key]) {
            this.errorMessage = value;
          }
        }
      }

      this.monthError.emit(this.inputName);
    } else {
      this.displayError = false;
      this.errorSolved.emit(this.inputName);
    }
  }

  /**
   * getMonthsDates method
   * get the data from the selected month's dates.
   * By default, it selects the current month's dates
   * @param {number} monthIndex number of the month to obtain dates from
   */
  private getMonthsDates(monthIndex: number = 0): void {
    const currentTime = this.calculateMonthAndYear(monthIndex);
    
    const daysInMonth = moment(`${currentTime.year}-${currentTime.month + 1}`, 'YYYY-MM').daysInMonth();

    this.getLastDaysOfPastMonth(currentTime);

    for (let i = 0; i < daysInMonth; i += 1) {
      const date = moment({ ...currentTime, date: i + 1 });
      this.dates.push(date);
    }

    this.getFirstDaysOfNextMonth(currentTime);
  }

  /**
   * getLastDaysOfPastMonth method
   * add to the dates array the dates of the past
   * month that get introduced in the first week
   * of the current month
   * @param {Time} time month and year to work with
   * @return {void}
   */
  private getLastDaysOfPastMonth(time: Time): void {
    const startOfMonth = moment()
      .year(time.year)
      .month(time.month)
      .startOf('month');

    const weekDay = startOfMonth.day();

    for (let i = 0; i < weekDay; i += 1) {

      // add them at the beginning of the array
      this.dates.unshift(
        moment(startOfMonth.subtract(1, 'd'))
      );
    }
  }

  /**
   * getFirstDaysOfNextMonth method
   * add to the dates array the dates of next week
   * that are part of the last week of the
   * current month
   * @param {Time} time month and year to work with
   * @return {void}
   */
  private getFirstDaysOfNextMonth(time: Time): void {
    const endOfMonth = moment()
      .year(time.year)
      .month(time.month)
      .endOf('month');

    const weekDay = endOfMonth.day();

    /**
     * should the last day not be saturday
     * as it is the last column in the calendar
     */
    if (weekDay !== 6) {
      for (let i = 0; i < (6 - weekDay); i += 1) {
        
        // add them at the end of the array
        this.dates.push(
          moment(endOfMonth.add(1, 'd'))
        );
      }
    }
  }

  /**
   * calculateMonthAndYear method
   * calculate which month and year the amount of months
   * refers to. If the month surpasses 11 (december index), we need to
   * figure out which month and year we are obtaining
   * @param {number} amount number of the month to obtain dates from
   * @return {Time} object containing the calculated year and month
   */
  private calculateMonthAndYear(amount: number = 0): Time {
    let newMoment: Moment;

    // determine if the month should go up or down depending on the amount
    if (amount >= 0) {
      newMoment = moment(this.selectedMoment).add(amount, 'M');
    } else {
      newMoment = moment(this.selectedMoment).subtract(Math.abs(amount), 'M');
    }

    const month = newMoment.month();
    const year = newMoment.year();

    // set the selectedMonth
    this.selectedMoment = newMoment;

    // set the new month name to display
    this.currentMonth = newMoment.month(month).format('MMM');
    this.currentYear = newMoment.year(year).format('YYYY');

    return {
      year: newMoment.year(),
      month,
    };
  }

}
