import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnChanges {

  constructor(private route: Router) { }

  @Input() seconds: number = 5;
  @Input() mainText: string = 'Your password has been changed!';
  @Input() secondaryText: string = 'You will be automatically redirected to Login';
  @Input() navigateTo: string = 'login';

  public countDown: {
    val:number;
  } = { val: 5 };

  /**
   * ngOnChanges method
   * funtion to start the count down if the changes contains a rute to navigate
   * @param changes changes in the inputs
   * @returns {void}
   */
  ngOnChanges(changes: SimpleChanges): void{
    if (changes.navigateTo.currentValue) {
      this.countDown = { val: this.seconds }
      this.startCountDown();
    }
  }

  /**
   * startCountDown method
   * make the count down and redirect to the target (navigateTo)
   * @returns {void}
   */
  public startCountDown(): void {
    const navigateTo = this.navigateTo;
    const route = this.route;
    const countDownInterval = setInterval(function(countDown){
      countDown.val -= 1;
      if(countDown.val <= 0){
        clearInterval(countDownInterval);
        route.navigateByUrl(navigateTo);
      }
    }, 1000, this.countDown);
  }
}
