import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-roles-header',
  templateUrl: './manage-roles-header.component.html',
  styleUrls: ['./manage-roles-header.component.scss']
})
export class ManageRolesHeaderComponent {

  constructor(private router: Router) { }

  /**
   * goToCreateNewRole
   * Navigates to create new role view
   * @returns {void} void
   */
  public goToCreateNewRole(): void {
    this.router.navigateByUrl('manage/roles/create');
  }

}
