import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProjectRoleResponse } from 'src/app/models/projects/project-role-response.model';
import { EmptyResponse } from 'src/app/models/responses/empty-response.model';
import { UsersUnassignedResponse } from 'src/app/models/users-unassigned-response.model';
import { environment } from 'src/environments/environment';
import { NewTeamMember } from 'src/app/models/team-member/new-team-member.model';
import { AddTeamMemberResponse } from 'src/app/models/team-member/add-team-member-response.model';
import { GetTeamMemberProjectResponse } from 'src/app/models/team-member/get-team-member-project-response.model';
import { Response } from 'src/app/models/response.model';
import { TeamMemberProjectRelation } from 'src/app/models/team-member/team-member-project-relation.model';

@Injectable({
  providedIn: 'root'
})
export class TeamMemberService {

  constructor(private http: HttpClient) {}

  /**
   * getProjectRoles method
   * gets the project roles from the backend
   * @returns {Observable} array containing all the approved project roles
   */
  public getProjectRoles(): Observable<ProjectRoleResponse> {
    return this.http.get<ProjectRoleResponse>(
      `${environment.apiUrl}/projects/roles`
      ).pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  /**
   * getUsersUnassigned method
   * gets the users that has not been assigned to an specific project
   * it shows as option to assigned
   * @param id contain the id of the project which want assign users
   * @returns {Observable} array containing all the users that has not been
   * assigned to the required project
   */
  public getUsersUnassigned(id: number): Observable<UsersUnassignedResponse> {
    return this.http.get<UsersUnassignedResponse>(
      `${environment.apiUrl}/projects/${id}/unassigned-users`
    ).pipe(
      catchError((error) => {
        return of(error);
      })
    );
  }

  /**
   * addTeamMemberProject method
   * return an observable with the data created on the database
   * @param newTeamMember contain the data necesary to create the
   * new team member
   * @returns {Observable} add Team Member Response
   */
  public addTeamMemberProject(newTeamMember: NewTeamMember): Observable<AddTeamMemberResponse> {
    return this.http.post<AddTeamMemberResponse>(
      `${environment.apiUrl}/projects/team`,
      newTeamMember
      ).pipe(
        catchError((error) => {
          return of (error);
        })
      );
  }

  /**
   * getTeamMemberProjectRelation method
   * return an observable with the relation between a user and a project
   * @param userId The user id
   * @param projectId The project id
   * @returns {Observable<GetTeamMemberProjectResponse>}
   */
  public getTeamMemberProjectRelation(
    userId: number,
    projectId: number
  ): Observable<GetTeamMemberProjectResponse> {
    return this.http.get<GetTeamMemberProjectResponse>(
      `${environment.apiUrl}/projects/${projectId}/team-members/${userId}`,
    ).pipe(
      catchError(error => of (error.error))
    );
  }

  /**
   * editTeamMemberProjectRelation method
   * return an observable with the backend response (status = 'success' | 'error')
   * @param {TeamMemberProjectRelation} relation teamMember-project relation
   * @returns {Observable<TeamMemberProjectRelation>} backend response
   */
  public editTeamMemberProjectRelation(
    relation: TeamMemberProjectRelation,
  ): Observable<Response> {
    let dat = {
      'roleId': relation.projectRoleId,
      'isLeader': relation.isLeader,
      'weeklyHours': +relation.expectedHoursPerWeek,
      'variability': relation.variability,
    }
    return this.http.put<Response>(
      `${environment.apiUrl}/projects/team/${relation.id}`,
      {
        'roleId': relation.projectRoleId,
        'isLeader': relation.isLeader,
        'weeklyHours': +relation.expectedHoursPerWeek,
        'variability': relation.variability,
      },
    ).pipe(
      catchError(error => of (error.error))
    );
  }

  /**
   * archiveTeamMember method
   * Archives an existent team-member in an existent project
   * @param {number} projectID - Project ID of the project in which the member is working
   * @param {number} userID - User ID of the user that will be archived
   * @returns {Observable<EmptyResponse>} endpoint empty response
   */
  public archiveTeamMember(projectID: number, memberID: number): Observable<EmptyResponse> {
    return this.http.put<EmptyResponse>(
      `${environment.apiUrl}/projects/${projectID}/team-members/${memberID}/toggle`, {}
    );
  }
}
