import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { Timesheet } from "src/app/models/timesheet.model";
import { TimesheetService } from "src/app/services/timesheet.service";
import { UserService } from "src/app/services/user.service";
import { UserProject } from "src/app/models/user-projects.model";
import { Subscription } from "rxjs";
import { DatePipe } from "@angular/common";
import { TimesheetDescription } from "src/app/models/timesheet-description.model";
import moment from 'moment';
import { WorkdayJump } from 'src/app/models/workday-jump.model';

@Component({
  selector: "app-update",
  templateUrl: "./update.component.html",
  styleUrls: ["./update.component.scss"],
  providers: [TimesheetService, DatePipe]
})

export class UpdateComponent implements OnInit {
  @ViewChild ('scrollable', { static: true }) scrollContainer: ElementRef;

  public timesheets: Timesheet[] = [];
  public projects: UserProject[] = [];
  public dates: Date[] = [];
  public active: number = 0;
  public timesheetActive = null;
  public totalHoursMessage: string = '';
  public actualStatus: string = 'All';
  public actualPeriod: string = 'Per period';
  public daysCounter: number = 0;
  public isLoaded: boolean = false;
  public onFocus: boolean = false;
  public holidays: string[] = [];
  public firstWorkday: Date = null;
  public lastWorkday: Date = null;

  //Subscriptions
  public projectsSubscription: Subscription;
  public timesheetSubscription: Subscription;

  constructor(
    private timesheetService: TimesheetService,
    private userService: UserService,
    private datePipe: DatePipe,
    private detectorRef: ChangeDetectorRef,
  ) {}

  /**
   * ngOnInit hook
   * @description gets id user from local storage
   * and gets his timesheets
   */
  ngOnInit() {
    // taking the logged user id from local storage
    const id = +localStorage.getItem("id");

    if (!isNaN(id)) {
      this.getUserProjects(id);
    }
  }

  /**
   * ngOnDestroy hook
   * @description unsubscribe of observables
   */
  ngOnDestroy() {
    this.projectsSubscription.unsubscribe();
    this.timesheetSubscription.unsubscribe();
  }

  /**
   * getTimesheet method
   * @description gets timesheets already filled
   * by the user
   * @param {number} userId id of the user
   * @return {void} void
   */
  public getTimesheet(dates: Date[], timesheetActive?: Date): void {
    const userId = +localStorage.getItem("id");
    const fromDate = dates[0];
    const toDate = dates[dates.length - 1];

    this.timesheetSubscription = this.timesheetService
      .getTimesheet(userId,
        this.datePipe.transform(fromDate, "yyyy-MM-dd"),
        this.datePipe.transform(toDate, "yyyy-MM-dd"))
      .subscribe(result => {
        this.timesheets = result.data.timesheets;
        this.holidays = result.data.holidays;
        this.dates = dates;

        if (!timesheetActive) {
          this.timesheetActive = this.defaultActive();
        }

        if (this.timesheets) {
          this.setTotalHoursMessage();
        }

        this.setFirstAndLastWorkday();
      });
  }

  /**
   * searchTimesheet method
   * @description find if the user already
   * filled the timesheet of a date
   * @param {Date} dateSearch date to find
   * @returns {boolean} boolean - is the date filled or not
   */
  public searchTimesheet(dateSearch: Date): boolean {
    let found = false;
    if (this.timesheets) {
      this.timesheets.forEach(element => {
        if (
          +element.date.substr(8,2) === dateSearch.getDate()
        ) {
          found = true;
          return;
        }
      });
    }
    return found;
  }

  /**
   * defaultActive method
   * @description gets the first date without fill
   * @returns {Date} Date - first date without fill
   */
  public defaultActive(): Date {
    let isFilled = true;
    let isFree = true;
    let isHoliday = true;
    let defaultActive = null;

    defaultActive = this.dates.find((element) => {
      isFree = this.isFreeDay(element);
      isFilled = this.searchTimesheet(element);
      isHoliday = this.isHoliday(element);
      return !isFree && !isFilled && !isHoliday;
    });
    
    return defaultActive;
  }

  /**
   * nextActive method
   * @description gets the first date without fill
   * @returns {Date} Date - first date without fill
   */
  public nextActive(date: Date): Date {
    let isFilled = true;
    let isFree = true;
    let isAfterCurrentDate = false;
    let nextActive;

    nextActive = this.dates.find(element => {
      isFree = this.isFreeDay(element);
      isFilled = this.searchTimesheet(element);
      isAfterCurrentDate = element > date;
      return !isFree && !isFilled && isAfterCurrentDate;
    });

    return nextActive;
  }

  /**
   * prevActive method
   * @description Gets the nearest not freeday and not filled
   * date before of given date
   * @param {Date} date - Date to compare and find its previous date
   * @returns {Date} Date - Date found
   */
  public prevActive(date: Date): Date {
    let isFilled = true;
    let isFree = true;
    let isBeforeCurrentDate = false;
    let prevActive = null;

    prevActive = this.dates.reverse().find(element => {
      isFree = this.isFreeDay(element);
      isFilled = this.searchTimesheet(element);
      isBeforeCurrentDate = element < date;

      return !isFree && !isFilled && isBeforeCurrentDate;
    });

    this.dates.reverse();

    return prevActive;
  }

  /**
   * isFreeDay method
   * @description verify if timesheet is corresposding to a
   * free date
   * @param date {Date} day to evaluate
   * @returns {boolean} boolean - is a free date
   */
  public isFreeDay(date: Date): boolean {
    const day = date.getDay();
    return day === 0 || day === 6 || this.isHoliday(date);
  }

  /**
   * updtadeDeleteTimesheet method
   * update timesheets
   * @param {date} date
   * @returns {void}
   */
  public updtadeDeleteTimesheet(date: Date): void {
    this.getTimesheet(this.dates);
  }

  /**
   * getTimesheetDescriptions
   * @description get the descriptions corresposding to a timesheet
   * @param {Date} date date of the timesheet
   * @returns {TimesheetDescription[]} TimesheetDescription[] - descriptions for the timesheet of this date
   */
  public getTimesheetDescriptions(date: Date): TimesheetDescription[] {
    let descriptions: TimesheetDescription[];

    if (this.timesheets) {
      this.timesheets.forEach(element => {
        if (+element.date.substr(8,2) === date.getDate()) {
          descriptions = element.descriptions;
          return;
        }
      });
    }

    if(descriptions) {
      this.formatDescriptions(descriptions)
    }

    return descriptions;
  }

  /**
   * formatDescriptions method
   * @description removes unnecesary attributes of timehseet descriptions
   * @param {TimesheetDescriptions[]} descriptions  descriptions to format
   * @returns {void} void
   */
  public formatDescriptions(descriptions: TimesheetDescription[]): void {
    descriptions.forEach(description => {
      delete description.createdAt;
      delete description.timesheetId;
      delete description.updatedAt;
      description.note = description.note ? description.note : "";
    })
  }

  /**
   * getUserProjects method
   * @description gets projects where an user is assigned
   * @param {number} userId id of the user
   * @return {void} void
   */
  public getUserProjects(userId: number): void {
    this.projectsSubscription = this.userService
      .getUserProjects(userId)
      .subscribe(result => {
        this.projects = result.data;
      });
  }

  /**
   * updateActive method
   * @description Listen the event emitEvent to set the value
   * of the variable timesheetActive
   * @param { Date } active - Date of the active timesheet received
   * @returns {void} void
   */
  public updateActive(active: Date): void {
    this.getTimesheet(this.dates, active);

    if (this.timesheetActive === active) {
      this.timesheetActive = this.nextActive(active);
    } else {
      this.timesheetActive = active;
    }
  }

  /**
   * setTotalHoursMessage method
   * @description Set the message of the hours in the period worked
   * to be showed in the header
   * @returns {void} void
   */
  public setTotalHoursMessage(): void {
    let totalHours = 0;

    this.timesheets.forEach((timesheet) => {
      totalHours += timesheet.descriptions.reduce(
        (sum, description) => sum + (description['dedicatedHours'] || 0), 0);
    });

    this.totalHoursMessage =
      totalHours === 1 ? `${totalHours} hr worked` : `${totalHours} hrs worked`;
  }

  /**
   * setStatus method
   * @description Listen the event status to set the value of the variable status
   * @param { string } string - status - Status received
   */
  public setStatus(status: string): void {
    this.actualStatus = status;
  }

  /**
   * setPeriod method
   * @description Listen the event period to set the value of the variable period
   * @param { string } string - period - Period received
   */
  public setPeriod(period: string): void {
    this.actualPeriod = period;
  }

  /**
   * hasPendingDays method
   * @description Check if the current date range has days pending to be filled
   * @returns {boolean} boolean
   */
  public hasPendingDays(): boolean {
    const pendingInDates = this.dates.find((date) => {
      if (!this.isFreeDay(date)
      && !this.searchTimesheet(date)
      && this.isBeforeToday(date)) {
        return date;
      }
    });

    return !!pendingInDates;
  }

  /**
   * isBeforeToday method
   * @description Check if a certain date is before to today
   * @param {Date} date - Date to check if is before to today
   * @returns {boolean} boolean
   */
  private isBeforeToday(date: Date): boolean {
    return Date.now() >= date.getTime();
  }

  /**
   * hasContent method
   * @description Check if the getting dates will show timesheets
   * according with the applied
   * @returns {boolean} booleanResponse
   */
  public hasContent(): boolean {
    if ((this.actualStatus === 'Updated' && this.timesheets.length)
    || (this.actualStatus === 'Pending' && this.hasPendingDays())
    || (this.actualStatus === 'All')) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * setOnFocus method
   * @description Recives a boolean value to know
   * if some input is in focus
   * @param {boolean} value - Received value
   * (true in focus, false in blur)
   * @returns {void} void
   */
  public setOnFocus(event: boolean): void {
    this.onFocus = event;
    this.detectorRef.detectChanges();
  }

  /**
   * scrollTop method
   * @description Move the scroll to the top
   * @returns {void} void
   */
  public scrollTop(): void {
    this.scrollContainer.nativeElement.click();
    this.scrollContainer.nativeElement.scrollTop = 0;
  }

  /**
   * isHoliday method
   * @description Check if the given date is inthe holidays array
   * @param {Date} date - Date to find in the holidays array
   * @returns {boolean} boolean
   */
  public isHoliday(date: Date): boolean {
    return this.holidays.includes(
      moment(date).format('YYYY-MM-DD'),
    );
  }

  /**
   * setFirstAndLastWorkday method
   * @description Set the First and Last Workday
   * in the current viewed period
   * @returns {void} void
   */
  private setFirstAndLastWorkday(): void {
    this.firstWorkday = null;
    this.lastWorkday = null;

    this.dates.forEach((date: Date) => {
      if (this.firstWorkday && !this.isFreeDay(date) && !this.searchTimesheet(date)) {
        this.lastWorkday = date;
      }

      if (!this.firstWorkday && !this.isFreeDay(date) && !this.searchTimesheet(date)) {
        this.firstWorkday = date;
      }
    });
  }

  /**
   * isLastWorkday method
   * @description Check if the given date is the last workday
   * @param {Date} date - Date to compare
   * @returns {boolean} boolean
   */
  public isLastWorkday(date: Date): boolean {
    return date === this.lastWorkday;
  }

  /**
   * isFirstWorkday method
   * @description Check if the given date is the first workday
   * @param {Date} date - Date to compare
   * @returns {boolean} boolean
   */
  public isFirstWorkday(date: Date): boolean {
    return date === this.firstWorkday;
  }

  /**
   * selectActive method
   * @description Select the corresponding date to set like active
   * @param {WorkdayJump} event - Object that contains the jump to do
   * and the current active date
   * @returns {void} void
   */
  public selectActive(event: WorkdayJump): void {
    let dateToChange = null;

    this.getTimesheet(this.dates, event.active);
    switch (event.jumpTo) {
      case 'first': {
        dateToChange = this.firstWorkday;
        break;
      }

      case 'last': {
        dateToChange = this.lastWorkday;
        break;
      }

      case 'prev': {
        dateToChange = this.prevActive(event.active);
        break;
      }

      case 'next': {
        dateToChange = this.nextActive(event.active);
        break;
      }
    }

    if (this.timesheetActive === event.active) {
      this.timesheetActive = dateToChange;
    } else {
      this.timesheetActive = event.active;
    }
  }
}
