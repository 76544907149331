import { Injectable, ElementRef } from '@angular/core';
import { User } from '../models/user.model';
import { SuggestionsKeyPressedResults } from '../models/select/select-key-pressed.model';
import { Client } from 'src/app/models/databases/clients.model';

@Injectable({
  providedIn: 'root'
})
export class SuggestionsService {

  constructor() { }

  /**
   * onKeyPressed method
   * method to handle a select with weys
   * @param {string} keyPressed key pressed by the user
   * @param {number} focusedOption option with the focus
   * @param {ElementRef[]} optionsElements array of elements to select
   * @param {NestedSelectorOption[]} options array of options to select
   * @returns {SuggestionsKeyPressedResults} results to update the component
   * that called the function
   */
  onKeyPressed(
    keyPressed: string,
    focusedOption: number,
    inputElement: ElementRef,
    optionsElements: ElementRef[],
    filtered: string[] | User[] | Client[],
  ) {
    const results: SuggestionsKeyPressedResults = {
      result: false,
      focusedOption,
      emptyFiltered: false,
    }

    switch (keyPressed) {
      case "ArrowDown":
        if (results.focusedOption + 1 < optionsElements.length) {
          results.focusedOption += 1;
          optionsElements[results.focusedOption].nativeElement.focus();
        }
        return results;

      case "ArrowUp":
        if (results.focusedOption - 1 >= 0) {
          results.focusedOption -= 1;
          optionsElements[results.focusedOption].nativeElement.focus();
        }
        return results;

      case "Tab":
        results.emptyFiltered = true;
      break;

      case "Escape":
        if (filtered.length > 0) {
          results.focusedOption = -1;
          results.emptyFiltered = true;
          inputElement.nativeElement.focus();
          return results;
        }
      break;

      default:
        inputElement.nativeElement.focus();
        results.focusedOption = -1;
    }
    results.result = true;
    return results;
  }
}
