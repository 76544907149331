import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { RolesPermissionList } from 'src/app/models/permission-list.model';
import { RolesPermissions } from 'src/app/mock/permission-list.data.json';
import { patternValidator } from 'src/app/shared/custom-validators/custom-pattern';
import { rolesRegex  } from 'src/app/config/regex';
import { createRolesErrors } from 'src/app/config/error-messages';
import { RolesService } from 'src/app/services/roles.service';
import { PermissionsList } from 'src/app/models/roles-permissions-list.model';
import { GetPermissions } from 'src/app/models/get-permissions.model';
import { Role } from 'src/app/models/role.model';
import { ContentModal } from 'src/app/models/content-modal.model';
import { deleteRoleModal, deleteButtons } from 'src/app/config/modal-contents';
import { TitleCasePipe } from '@angular/common';
import { rolesResponseErrors } from 'src/app/config/error-messages';
@Component({
  selector: 'app-create-roles',
  templateUrl: './create-roles.component.html',
  styleUrls: ['./create-roles.component.scss'],
  providers: [TitleCasePipe]
})
export class CreateRolesComponent implements OnInit, AfterViewInit{
  public permissionList: any;
  public modified: boolean = false;
  public createRolesForm: FormGroup;
  public stringOriginalFormGroupValue: RolesPermissionList;
  public permissionsList: PermissionsList[];
  public category: any;
  public categoryPermissions: PermissionsList[];
  public permissions: GetPermissions[] = [];
  public newRole: Role;
  public showAlert: boolean;
  public message: string = null;
  private previousState: boolean[] = [];
  public newState: boolean = false;
  public nameHasChanged: boolean = false;
  public permissionChanged: boolean = false;
  public allPermissionsOff: boolean = false;
  public isEdit:object;

  // form controls
  public newRoleControl: FormControl = new FormControl(' ', []);
  public selfControl: FormControl = new FormControl('', []);
  public editTimesheetControl: FormControl = new FormControl(false, []);
  public allControl: FormControl = new FormControl(false, []);
  public memberControl: FormControl = new FormControl('', []);
  public editResumeControl: FormControl = new FormControl(false, []);
  public editStatusControl: FormControl = new FormControl(false, []);
  public assignRolesControl: FormControl = new FormControl(false, []);
  public allMembersControl: FormControl = new FormControl(false, []);
  public checkInControl: FormControl = new FormControl('', []);
  public editCheckInControl: FormControl = new FormControl(false, []);
  public allCheckInControl: FormControl = new FormControl(false, []);
  public managementControl: FormControl = new FormControl('', []);
  public createRolesControl: FormControl = new FormControl(false, []);
  public createProjectsControl: FormControl = new FormControl(false, []);
  public generateReportsControl: FormControl = new FormControl(false, []);
  public editCheckinsControl: FormControl = new FormControl(false, []);
  public databaseItemsControl: FormControl = new FormControl(false, []);
  public allManagementControl: FormControl = new FormControl(false, []);

  public showModal = false;

  // Content Modal data to customize
  public contentModal: ContentModal = {
    title: '',
    content: [],
    confirmButton: deleteButtons.confirmButton,
    cancelButton: deleteButtons.cancelButton,
  };

  // Validators
  public newRoleValidators: Validators[] = [
    Validators.required,
    patternValidator(rolesRegex),
  ];

  // Errors
  public newRoleError = createRolesErrors.createRole;

  // Inputs
  @Input() roleToEdit: number;
  @Input() name: string;
  @Input() toggleValues: boolean[];
  @Input() canDelete: boolean;
  @Input() autofocus: boolean;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private rolesService: RolesService,
    private titleCasePipe: TitleCasePipe,
  ) { }

  /**
   * ngOnInit hook method
   * set the initial values to the variable we use
   * to compare the form status to enable or disable the
   * create role button.
   * Checks if the role id exists to switch for the
   * edit role functionality. Also checks for role changes
   */
  ngOnInit() {
    if (this.autofocus === undefined) {
      this.autofocus = true;
    }

    this.isEdit = {
      'new-role-container': !this.roleToEdit,
      'edit-role-container-can-delete': !!this.roleToEdit && this.canDelete,
      'edit-role-container-no-delete': !!this.roleToEdit && !this.canDelete,
      'px-8': true,
    }

    this.showModal = false;
    if (this.name && this.toggleValues.length) {
      // populating form with permissions and role name
      this.newRoleControl.setValue(this.titleCasePipe.transform(this.name));
      this.editTimesheetControl.setValue(this.toggleValues[0]);
      this.allControl.setValue(this.toggleValues[1]);
      this.editResumeControl.setValue(this.toggleValues[2]);
      this.editStatusControl.setValue(this.toggleValues[3]);
      this.assignRolesControl.setValue(this.toggleValues[4]);
      this.allMembersControl.setValue(this.toggleValues[5]);
      this.editCheckInControl.setValue(this.toggleValues[6]);
      this.allCheckInControl.setValue(this.toggleValues[7]);
      this.createRolesControl.setValue(this.toggleValues[8]);
      this.createProjectsControl.setValue(this.toggleValues[9]);
      this.generateReportsControl.setValue(this.toggleValues[10]);
      this.databaseItemsControl.setValue(this.toggleValues[11]);
      this.allManagementControl.setValue(this.toggleValues[12]);
    }

    //Form group
    this.createRolesForm = this.formBuilder.group ({
      'newRole': this.newRoleControl,
      'roles': this.formBuilder.array([]),
    });

    this.addRoleCategory();
    this.permissionList = RolesPermissions;
    this.stringOriginalFormGroupValue = this.createRolesForm.value;
    this.stringOriginalFormGroupValue.newRole = this.createRolesForm.value.newRole;
    this.getPermissions();
  }

  /**
   * addRoleCategory method
   * add role category into the create role formgroup
   * @returns {void} returns void
   */
  public addRoleCategory(): void {
    let category = this.formBuilder.group({
      'name': this.selfControl,
      'permissions': this.formBuilder.array([
        this.editTimesheetControl,
        this.allControl,
      ]),
    });

    (this.createRolesForm.get('roles') as FormArray).push(category);

    let category2 = this.formBuilder.group({
      'name': this.memberControl,
      'permissions': this.formBuilder.array([
        this.editResumeControl,
        this.editStatusControl,
        this.assignRolesControl,
        this.allMembersControl,
      ]),
    });

    (this.createRolesForm.get('roles') as FormArray).push(category2);

    let category3 = this.formBuilder.group({
      'name': this.checkInControl,
      'permissions': this.formBuilder.array([
        this.editCheckInControl,
        this.allCheckInControl,
      ]),
    });

    (this.createRolesForm.get('roles') as FormArray).push(category3);

    let category4 = this.formBuilder.group({
      'name': this.managementControl,
      'permissions': this.formBuilder.array([
        this.createRolesControl,
        this.createProjectsControl,
        this.generateReportsControl,
        this.databaseItemsControl,
        this.allManagementControl,
      ]),
    });

    (this.createRolesForm.get('roles') as FormArray).push(category4);
  }

  /**
   * ngAfterViewInit hook
   * change the form status to modified or original to enable or disable the
   * create role button
   */
  ngAfterViewInit() {
    this.createRolesForm.valueChanges.subscribe((val) => {
      this.showAlert = false;
      for (let i = 0;
        i < this.stringOriginalFormGroupValue.roles.length;
        i += 1) {
        this.stringOriginalFormGroupValue.roles[i].name = this.createRolesForm.value.roles[i].name;
      }

      val.newRole = val.newRole.trim();
      this.stringOriginalFormGroupValue.newRole = this.createRolesForm.value.newRole.trim();

      if (this.createRolesForm.value.newRole) {
        this.modified = JSON.stringify(this.stringOriginalFormGroupValue)
          !== JSON.stringify(val);
      } else {
        this.modified = false;
      }
    });

    if (this.name && this.toggleValues.length) {
      this.checkChanges();
    }
  }

  /**
   * allAccess method
   * activate all the permissions of the current category
   * @return {void}
   */
  public allAccess(i: number, j: number): void {
    let activateCheckboxes = (((this.createRolesForm
      .controls['roles'] as FormArray)
      .controls[i] as FormGroup)
      .controls['permissions'] as FormArray)
      .controls;
  
    if (j === this.permissions[i].rolesPermissions.length - 1
      || (i === 0 && j === 0)
      || (i === 2 && j === 0)
    ){

      if (activateCheckboxes.every(val => val.value === true)) {
        activateCheckboxes.forEach((val) => { val.setValue(
          false,
          {emitEvent: false});
        });
      } else {
        activateCheckboxes.forEach((val) => { val.setValue(
          true,
          {emitEvent: false});
        });
      }
    } else {
      this.createRolesForm.valueChanges.subscribe(() => {
        let counter: number = 0;

        activateCheckboxes.slice(0, activateCheckboxes.length-1)
          .forEach(checkBox => {
            if (checkBox.value === true) {
              counter += 1;
            }
        });

        const isActivated = (counter === activateCheckboxes.length - 1);
        activateCheckboxes[activateCheckboxes.length - 1].setValue(
          isActivated,
          { emitEvent: false }
        );
      });
    }
  }

  /**
   * getPermissions Method
   * This method subscribes to a getPermissions method in Roles Service
   * and retrieves the data of the permissions used to create roles
   * @returns {void}
   */
  public getPermissions(): void {
    this.rolesService.getPermissions().subscribe((permissions) => {
      this.permissionsList = permissions.data;
      this.setUpData();
    });
  }

  /**
   * setUpData Method
   * Set Up the necessary data to render the create roles component
   * @returns {void}
   */
  public setUpData(): void {
    this.category = this.permissionsList.filter(
      (actualValue, actualIndex, actualArray) => {
        return actualArray.findIndex(
          arrayValue => JSON.stringify(arrayValue.name)
          === JSON.stringify(actualValue.name)
        ) === actualIndex;
    });

    for (let i = 0; i < this.category.length; i += 1) {
      (((this.createRolesForm.controls['roles'] as FormArray)
        .controls[i] as FormGroup)
        .controls['name'] as FormArray)
        .setValue(this.category[i].name);

      this.categoryPermissions = this.permissionsList.filter(permissions => {
        return JSON.stringify(permissions.name)
          === JSON.stringify(this.category[i].name);
      });

      this.permissions[i] = { rolesPermissions: [] };
      this.permissions[i].rolesPermissions = this.categoryPermissions;

      if (this.name && this.toggleValues.length) {
        // activate all toggles, setting original state from BE
        this.activateAllToggles(i);
        this.createRolesForm.controls.roles['controls'][i]
          .controls.permissions.controls.forEach(element => {
            this.previousState.push(element.value);
        });
      }
    }
  }

  /**
   * setData method
   * set the necessary data to send for create the new role
   * @returns {void}
   */
  public setData() {
    let i: number = 0;

    this.newRole = {
      name: this.createRolesForm.value.newRole,
      permissions: [],
    };

    this.createRolesForm.value.roles.forEach(role => {
      let j: number = 0;

      role.permissions.forEach(element => {
        if (element === true) {
          this.newRole.permissions.push(
            this.permissions[i]['rolesPermissions'][j].id
          );
        }

        j += 1;
      });

      i += 1;
    });
  }

  /**
   * submit method
   * handle the logic when we create the roles
   * @return {void}
   */
  public submit(): void {
    this.setData();
    //submitting data to edit a role
    if (this.roleToEdit) {
      this.editRole();
    } else {
      this.createRole();
    }
  }

  /**
   * checkPermissionsOff method
   * checks if all the permissions are disabled to disable the button
   * @returns {void} void
   */
  public checkPermissionsOff(): void {
    let permissionsOff: boolean[] = [];

    if (this.name && this.toggleValues.length) {
      const rolesFormValues = this.createRolesForm.controls.roles['controls'];
      // iterating over the form array
      for (let i = 0; i < rolesFormValues.length; i += 1) {
        let activateCheckboxes = (((this.createRolesForm
          .controls['roles'] as FormArray)
          .controls[i] as FormGroup)
          .controls['permissions'] as FormArray)
          .controls;

        // checking the form array
        activateCheckboxes.every((val) => permissionsOff.push(val.value));

        if (permissionsOff.every((val) => val === false)) {
          this.allPermissionsOff = true;
        } else {
          this.allPermissionsOff = false;
        }
      }
    }
  }

  /**
   * activateAllToggles method
   * receives positions from the array form and checks when all the toggles has
   * to be activated.
   * @param {number} i column position of the form array form values
   * @return {void} void
   */
  public activateAllToggles(i: number): void {
    // column of the form array
    let activateCheckboxes = (((this.createRolesForm
      .controls['roles'] as FormArray)
      .controls[i] as FormGroup)
      .controls['permissions'] as FormArray)
      .controls;

    /*
    checking if the all access toggle is active to activate the rest of the
    toggles
    */
    if (activateCheckboxes[activateCheckboxes.length - 1].value === true) {
      activateCheckboxes.forEach(val => { val.setValue(
        true,
        {emitEvent: false});
      });
    }

    // checking the permissions toggles, avoiding the all access toggles
    const permissionsToggles = activateCheckboxes
      .slice(0, activateCheckboxes.length - 1)
      .every(checkBox => checkBox.value === true);

    // activates the allAccess toggle
    if (permissionsToggles) {
      activateCheckboxes[activateCheckboxes.length - 1].setValue(
        true,
        {emitEvent: false},
      );
    }
  }

  /**
   * checkChangesInRole method
   * Iterates over the form array to look for a simple change in the role
   * to activate the update button
   * @returns {Promise<void>} promise with boolean as a flag to know when the
   * permissions have changed
   */
  public async checkChangesInPermissions(): Promise<boolean> {
    const rolesFormValues = this.createRolesForm.controls.roles['controls'];
    const permissionsArr = [];
    let counter = 0;

    for (let i = 0; i < rolesFormValues.length; i += 1) {
      // get the form array column
      const permissionsToggles = (((this.createRolesForm
        .controls['roles'] as FormArray)
        .controls[i] as FormGroup)
        .controls['permissions'] as FormArray);

      // checking if there is data to edit
      if (!this.nameHasChanged && this.previousState.length) {
        permissionsToggles.controls.forEach(val => permissionsArr.push(
          val.value));

        for (let j = 0; j < permissionsToggles.controls.length; j += 1) {
          // comparing the previous version and the current version
          if (permissionsToggles.controls[j].value !== await this.previousState[counter]) {
            return this.permissionChanged = true;
          } else {
            this.permissionChanged = false;
          }

          counter += 1;
        }

        permissionsToggles.valueChanges.subscribe(() => {
          // sending the flag to the role name changes
          this.roleNameChanges(this.permissionChanged);
        });
      }
    }
  }

  /**
   * roleNameChangesMethod
   * checks for changes in role name if the permissions has not changed
   * @param {boolean} permissionChanges flag that allows to know,
   * when the permissions have changed to execute the validation of the name.
   * Sets a flag to notify when the name has changed
   * @returns {void} void
   */
  public roleNameChanges(permissionChanges: boolean): void {
    if (!permissionChanges) {
      this.newRoleControl.valueChanges.subscribe((value) => {
        if (value && this.name
          && value.toLowerCase() !== this.name.toLowerCase()) {
          return this.nameHasChanged = true;
        } else {
          return this.nameHasChanged = false;
        }
      });
    }
  }

  /**
   * checkChanges method
   * validates if the form has some change in his state to able the update
   * button. Calls the other functions to execute then every time that the
   * form has some change
   * @returns {void} void
   */
  private checkChanges(): void {
    this.createRolesForm.valueChanges.subscribe(async () => {
      await this.checkChangesInPermissions();
      this.roleNameChanges(this.permissionChanged);
      this.checkPermissionsOff();

      if (this.nameHasChanged || this.permissionChanged) {
        this.newState = true;
      } else {
        this.newState = false;
      }
    });
  }

  /**
   * onChangeStatus Method
   * Sets the content of the modal and shows it 
   * @return {void}
   */
  public deleteRole(): void {
    let nameModal = null;

    this.showModal = true;
    nameModal = this.name;
    nameModal = nameModal.charAt(0).toUpperCase() + nameModal.slice(1);
    this.contentModal.title = deleteRoleModal.title;
    this.contentModal.content = deleteRoleModal.content(nameModal);
  }

  /**
   * onControl method
   * Listen the PopUpMessage EventEmitter of control
   * If confirmButton was pushed the backend call occurs
   * If cancelButton was pushed the toggle switch status is restored
   * @param {string} message - Can be confirm or cancel,
   * depending of the pushed button
   * @return {void}
   */
  public onControl(message: string): void {
    if (message !== 'cancel') {
      this.rolesService.deleteRole(this.roleToEdit).subscribe(response => {
        if (response.status === 'success') {
          this.router.navigateByUrl('manage/roles');
        }
      });
    }
  }

  /**
   * onCloseModal Method
   * Listen the PopUpMessage EventEmitter of close
   * When it is received the showModal variable 
   * turns to false and the modal is closed
   * @return {void}
   */
  public onCloseModal(): void {
    this.showModal = false;
  }

  /**
   * getAsFormArray method
   * @description Get a certain FormArray in an specific FormGroup
   * @param {FormGroup} form - FormGroup to find the array
   * @returns {FormArray} FormArray
   */
  public getAsFormArray(form: AbstractControl, arrayName: string): FormArray {
    return (form.get(arrayName) as FormArray);
  }

  /**
   * setErrorsMessage nethod
   * @description Set the alert error according
   * with the given statusError code
   * @param {string} statusError - Http status code from the error
   * @returns {void} void
   */
  private setErrorMessage(statusError: string): void {
    this.showAlert = true;
    switch(statusError) {
      case '400': {
        this.message = rolesResponseErrors.invalidName;
        break;
      }
      case '409': {
        this.message = rolesResponseErrors.repeatedName;
        break;
      }
      case '404': {
        this.message = rolesResponseErrors.roleNoExists;
        break;
      }
      default: {
        this.message = rolesResponseErrors.default;
        break;
      }
    }
  }

  /**
   * editRole method
   * @description Use editRole method from rolesService
   * to edit an existent role
   * @returns {void} void
   */
  private editRole(): void {
    this.rolesService.editRole(
      this.roleToEdit, this.newRole).subscribe(response => {
        if (response.status === 'success') {
          this.router.navigateByUrl('manage/roles');
        } else {
          this.setErrorMessage(response.status.toString());
        }
    });
  }
  /**
   * createRole method
   * @description Use createNewRole method from rolesService
   * to create a new role
   * @returns {void} void
   */
  private createRole() {
    this.rolesService.createNewRole(this.newRole).subscribe(result => {
      if (result.status === 'success') {
        this.router.navigateByUrl('manage/roles');
      } else {
        this.setErrorMessage(result.status.toString());
      }
    });
  }
}
