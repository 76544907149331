import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ExistingDataError } from 'src/app/models/errors/existing-data-array.model';
import { EnhancedFormControl } from 'src/app/models/enhanced-form-control.model';

/**
 * Checks if the value is valid
 * @param {AbstractControl} formGroupArray form array to be validated
 * @returns {object} object with the key of the error name and a value
 * of true if it's valid or null if not
 */
export function validateFormGroupArray(formGroupArray: AbstractControl): ExistingDataError {
  const comparisonArray: string[] = formGroupArray.value.reverse()
  .map( formValue => {
    if (typeof formGroupArray === 'object') {
      const stringValue = Object.values(formValue).find((value) => {
        return typeof value === 'string';
      }).toString();

      return stringValue.trim()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, "");
    }
  });

  /**
   * get only the duplicate values of the array and set the notUnique error to
   * their respective control
   */
  comparisonArray.reduce((acc, current, index, array) => {
    const control = Object.values(
      ((formGroupArray as FormArray)
        .controls[comparisonArray.length - index - 1] as FormGroup)
          .controls)[0] as FormControl;

    if (acc.includes(current)) {
      const errors = control.errors;

      // set the duplicate error on the control
      control.setErrors({
        notUnique: true,
        ...errors,
      });

      (control as EnhancedFormControl).displayError = true;
    } else {
      if (control.errors) {
        const errorProps = Object.getOwnPropertyNames(control.errors).length;

        if (errorProps === 1 && control.hasError('notUnique')) {
          control.setErrors(null);
        } else if (errorProps > 1 && control.hasError('notUnique')) {
          delete control.errors.notUnique;
          (control as EnhancedFormControl).displayError = true;
        }        
      }

      acc.push(current);
    }

    return acc;
  }, []);

  return null;
}
