import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'src/app/services/projects.service';
import { Project } from 'src/app/models/projects/project.model';
import { ProjectUser } from 'src/app/models/projects/project-user.model';
import { ClientTeamMember } from 'src/app/models/client-team-member.model';
import { ProjectFiltersData } from 'src/app/models/project-filters-data.model';

@Component({
  selector: 'app-project-members',
  templateUrl: './project-members.component.html',
  styleUrls: ['./project-members.component.scss']
})
export class ProjectMembersComponent implements OnInit {
  public projectId: number;
  public project: Project;

  public projectName: string = '';
  public activeUsers: ProjectUser[] = [];
  public inactiveUsers: ProjectUser[] = [];
  public activeClientMembers: ClientTeamMember[] = [];
  public inactiveClientMembers: ClientTeamMember[] = [];
  public projectFiltersData: ProjectFiltersData = null;

  constructor(
    private projectsService: ProjectsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.projectId = params.id;
      this.getProject(this.projectId);
    });
  }

  ngOnInit() {
    if (history.state.data) {
      this.projectFiltersData = history.state.data;
    }
  }

  /**
   * getProject method
   * get the project's data based on its id
   * @param {number} id id of the project
   * @return {void} 
   */
  public getProject(id: number): void {
    this.projectsService.getProject(id)
      .subscribe((project) => {
        this.project = project.data;
        this.projectName = this.project.name;
        this.activeUsers = this.project.activeUsers;
        this.inactiveUsers = this.project.inactiveUsers;
        this.activeClientMembers = this.project.activeClientMembers;
        this.inactiveClientMembers = this.project.inactiveClientMembers;
      });
  }
}
