import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employees-check-in',
  templateUrl: './employees-check-in.component.html',
  styleUrls: ['./employees-check-in.component.scss']
})

export class EmployeesCheckInComponent {
  public textButton: string = 'View';

  constructor(private router: Router) {}

  /**
   * goToCheckInEmployee method
   * Listen the goToEmployee EventEmitter of the Employees List
   * Shared Component and navigates to the CheckIn Employee View
   * with the id received
   * @param {number} employeeId - Employee to be redirected to
   * him/her CheckIn View
   * @returns {void} 
   */
  public goToCheckInEmployee(employeeId: number): void {
    this.router.navigateByUrl(`/checkin/employees/${employeeId}`);
  }
}
