import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { permissions } from 'src/app/config/permissions';

/**
 * Main Components
 * These are the components that will render the entire application.
 * LoginComponent, ResetPasswordComponent and the soon to be added
 * SignUpComponent will be in charge only of rendering their own respective views.
 * MainComponent is in charge of rendering everything else.
 */
import { MainComponent } from 'src/app/components/main/main.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { ResetPasswordComponent } from 'src/app/components/reset-password/reset-password.component';

/**
 * ResetPassword Components
 * Component to be seen in the Reset Password part of the application, such as
 * Set New Password
 */
import { SetNewPasswordComponent } from 'src/app/components/reset-password/set-new-password/set-new-password.component';

/**
 * Dashboard Components
 * Components to be seen in the Dashboard part of the application, such as
 * Profile, Team, My projects, My items and My days off
 */
import { DashboardComponent } from 'src/app/components/main/dashboard/dashboard.component';
// Dashboard/Profile Components
import { ProfileComponent } from 'src/app/components/main/dashboard/profile/profile.component';
// Dashboard/Team Components
import { TeamComponent } from 'src/app/components/main/dashboard/team/team.component';
import { TeammateComponent } from 'src/app/components/main/dashboard/teammate/teammate.component';
// Dashboard/MyProjects Components
import { MyProjectsComponent } from 'src/app/components/main/dashboard/my-projects/my-projects.component';
import { MyProjectsDetailsComponent } from 'src/app/components/main/dashboard/my-projects/my-projects-details/my-projects-details.component'

/**
 * Check In Components
 * Components to be seen in the Check In part of the application, such
 * Check In, By Employee, Days off Requests
 */
import { CheckInComponent } from 'src/app/components/main/check-in/check-in.component';
import { EmployeeCheckInComponent } from 'src/app/components/main/check-in/employee-check-in/employee-check-in.component';
import { EmployeesCheckInComponent } from 'src/app/components/main/check-in/employees-check-in/employees-check-in.component';
import { CheckInMainComponent } from 'src/app/components/main/check-in/check-in-main/check-in-main.component';

/**
 * Manage Components
 * Components to be seen in the Manage part of the application, such as
 * Employees, Projects, Roles, Reports, Calendar, Databases and Alerts
 */
import { ManageComponent } from 'src/app/components/main/manage/manage.component';
// Manage/Projects Components
import { ProjectsComponent } from 'src/app/components/main/manage/projects/projects.component';

/**
 * Timesheet Components
 * Components to be seen i the Timesheet part of the application, such as
 * Update, Checklist and weekly
 */
import { TimesheetComponent } from 'src/app/components/main/timesheet/timesheet.component';
import { UpdateComponent } from 'src/app/components/main/timesheet/update/update.component';
import { ProjectsListComponent } from 'src/app/components/main/manage/projects/projects-list/projects-list.component';
import { CreateProjectComponent } from 'src/app/components/main/manage/projects/create-project/create-project.component';
import { ProjectComponent } from 'src/app/components/main/manage/projects/project/project.component';
import { ProjectMembersComponent } from 'src/app/components/main/manage/projects/project-members/project-members.component';
import { EditProjectComponent } from 'src/app/components/main/manage/projects/edit-project/edit-project.component';
import { AddTeamMemberComponent } from 'src/app/components/main/manage/projects/add-team-member/add-team-member.component';
import { EditTeamMemberComponent } from 'src/app/components/main/manage/projects/edit-team-member/edit-team-member.component';
import { AddClientMemberComponent } from 'src/app/components/main/manage/projects/add-client-member/add-client-member.component';
import { EditClientMemberComponent } from 'src/app/components/main/manage/projects/edit-client-member/edit-client-member.component';
// Manage/Roles Components
import { ManageRolesComponent } from 'src/app/components/main/manage/roles/manage-roles/manage-roles.component';
import { CreateRolesComponent } from 'src/app/components/main/manage/roles/create-roles/create-roles.component';
import { EditRolesComponent } from 'src/app/components/main/manage/roles/edit-roles/edit-roles.component';
// Manage/Reports components
import { ReportsComponent } from 'src/app/components/main/manage/reports/reports.component';
import { WorkingHoursReportComponent } from 'src/app/components/main/manage/reports/working-hours-report/working-hours-report.component';
import { EmployeesComponent } from 'src/app/components/main/manage/employees/employees.component';
import { EmployeesListComponent } from 'src/app/components/main/manage/employees/employees-list/employees-list.component';
import { EditEmployeeComponent } from 'src/app/components/main/manage/employees/edit-employee/edit-employee.component';
// Manage/Databases components
import { DatabasesComponent } from 'src/app/components/main/manage/databases/databases.component';
import { DatabasesGeneralComponent } from 'src/app/components/main/manage/databases/databases-general/databases-general.component';
// Manage/Calendar components
import {CalendarComponent} from 'src/app/components/main/manage/calendar/calendar.component';
import {CalendarGeneralComponent} from 'src/app/components/main/manage/calendar/calendar-general/calendar-general.component';

// sign up flow
import { SignUpComponent} from 'src/app/components/sign-up/sign-up.component';
import { RolesComponent } from 'src/app/components/main/manage/roles/roles.component';

// auth guard
import { AuthGuardService } from 'src/app/services/guard/auth-guard.service';
import { LoginGuardService } from 'src/app/services/guard/login-guard.service';
import { PermissionGuardService } from 'src/app/services/guard/permission-guard.service';
import { TokenValidatorService } from 'src/app/services/token-validator.service';

const routes: Routes = [
  // default route
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  // main route
  {
    path: '',
    component: MainComponent,
    resolve: { tokenValidator: TokenValidatorService },
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuardService],
        resolve: { tokenValidator: TokenValidatorService },
        component: DashboardComponent,
        children: [
          {
            path: '',
            redirectTo: 'profile',
            resolve: { tokenValidator: TokenValidatorService },
            pathMatch: 'full'
          },
          {
            path: 'profile',
            component: ProfileComponent,
            resolve: { tokenValidator: TokenValidatorService },
          },
          {
            path: 'team',
            component: TeamComponent,
            resolve: { tokenValidator: TokenValidatorService },
          },
          {
            path: 'team/teammate/:id',
            component: TeammateComponent,
            resolve: { tokenValidator: TokenValidatorService },
          },
          {
            path: 'projects',
            component: ProjectComponent,
            resolve: { tokenValidator: TokenValidatorService },
            children: [
              {
                path: '',
                component: MyProjectsComponent,
                resolve: { tokenValidator: TokenValidatorService },
              },
              {
                path: ':id',
                component: MyProjectsDetailsComponent,
                resolve: { tokenValidator: TokenValidatorService },
              },
              {
                path: 'teammate/:id',
                component: TeammateComponent,
                resolve: { tokenValidator: TokenValidatorService },
              }
            ]
          },
          // temporary make it part of dashboard
          {
            path: 'timesheet',
            component: TimesheetComponent,
            resolve: { tokenValidator: TokenValidatorService },
            children: [
              {
                path: '',
                redirectTo: 'update',
                pathMatch: 'full',
                resolve: { tokenValidator: TokenValidatorService },
              },
              {
                path: 'update',
                component: UpdateComponent,
                canActivate: [PermissionGuardService],
                resolve: { tokenValidator: TokenValidatorService },
                data: {
                  permissions: [permissions.timesheet, permissions.self],
                }
              },
            ]
          },
        ]
      },
      {
        path: 'checkin',
        canActivate: [AuthGuardService],
        component: CheckInComponent,
        resolve: { tokenValidator: TokenValidatorService },
        children: [
          {
            path: '',
            redirectTo: 'main',
            pathMatch: 'full',
            resolve: { tokenValidator: TokenValidatorService },
          },
          {
            path: 'main',
            component: CheckInMainComponent,
            canActivate: [PermissionGuardService],
            resolve: { tokenValidator: TokenValidatorService },
            data: {
              permissions: [permissions.editCheckins, permissions.checkIns],
            }
          },
          {
            path: 'employees',
            component: EmployeesCheckInComponent,
            resolve: { tokenValidator: TokenValidatorService },
            canActivate: [PermissionGuardService],
            data: {
              permissions: [permissions.editCheckins, permissions.checkIns],
            }
          },
          {
            path: 'employees/:id',
            component: EmployeeCheckInComponent,
            canActivate: [PermissionGuardService],
            resolve: { tokenValidator: TokenValidatorService },
            data: {
              permissions: [permissions.editCheckins, permissions.checkIns],
            }
          }
        ],
      },
      {
        path: 'manage',
        canActivate: [AuthGuardService],
        component: ManageComponent,
        resolve: { tokenValidator: TokenValidatorService },
        children: [
          {
            path: 'projects',
            component: ProjectsComponent,
            canActivate: [PermissionGuardService],
            resolve: { tokenValidator: TokenValidatorService },
            data: {
              permissions: [permissions.projects, permissions.management]
            },
            children: [
              {
                path: '',
                component: ProjectsListComponent,
                resolve: { tokenValidator: TokenValidatorService },
              },
              {
                path: 'create',
                component: CreateProjectComponent,
                canActivate: [PermissionGuardService],
                resolve: { tokenValidator: TokenValidatorService },
                data: {
                  permissions: [permissions.projects, permissions.management],
                }
              },
              {
                path: 'teammate/:id',
                component: TeammateComponent,
                resolve: { tokenValidator: TokenValidatorService },
              },
              {
                path: ':id',
                component: ProjectComponent,
                resolve: { tokenValidator: TokenValidatorService },
                children: [
                  {
                    path: '',
                    component: ProjectMembersComponent,
                    resolve: { tokenValidator: TokenValidatorService },
                  },
                  {
                    path: 'edit',
                    component: EditProjectComponent,
                    canActivate: [PermissionGuardService],
                    resolve: { tokenValidator: TokenValidatorService },
                    data: {
                      permissions: [
                        permissions.projects,
                        permissions.management
                      ],
                    }
                  },
                  {
                    path: 'team-member',
                    resolve: { tokenValidator: TokenValidatorService },
                    children: [
                      {
                        path: '',
                        redirectTo: 'add',
                        pathMatch: 'full',
                        resolve: { tokenValidator: TokenValidatorService },
                      },
                      {
                        path: 'add',
                        component: AddTeamMemberComponent,
                        canActivate: [PermissionGuardService],
                        resolve: { tokenValidator: TokenValidatorService },
                        data: {
                          permissions: [
                            permissions.projects,
                            permissions.management
                          ],
                        }
                      },
                      {
                        path: ':memberId/edit',
                        component: EditTeamMemberComponent,
                        canActivate: [PermissionGuardService],
                        resolve: { tokenValidator: TokenValidatorService },
                        data: {
                          permissions: [
                            permissions.projects,
                            permissions.management
                          ],
                        }
                      }
                    ]
                  },
                  {
                    path: 'client-member',
                    children: [
                      {
                        path: '',
                        redirectTo: 'add',
                        pathMatch: 'full',
                        resolve: { tokenValidator: TokenValidatorService },
                      },
                      {
                        path: 'add',
                        component: AddClientMemberComponent,
                        canActivate: [PermissionGuardService],
                        resolve: { tokenValidator: TokenValidatorService },
                        data: {
                          permissions: [
                            permissions.projects,
                            permissions.management
                          ],
                        }
                      },
                      {
                        path: ':memberId/edit',
                        component: EditClientMemberComponent,
                        canActivate: [PermissionGuardService],
                        resolve: { tokenValidator: TokenValidatorService },
                        data: {
                          permissions: [
                            permissions.projects,
                            permissions.management
                          ],
                        }
                      }
                    ]
                  }
                ]
              },
            ]
          },
          {
            path: 'roles',
            canActivate: [PermissionGuardService],
            component: RolesComponent,
            resolve: { tokenValidator: TokenValidatorService },
            data: {
              permissions: [
                permissions.createRoles,
                permissions.management,
              ],
            },
            children: [
              {
                path: '',
                component: ManageRolesComponent,
                resolve: { tokenValidator: TokenValidatorService },
              },
              {
                path: 'create',
                component: CreateRolesComponent,
                resolve: { tokenValidator: TokenValidatorService },
              },
              {
                path: 'edit/:id',
                component: EditRolesComponent,
                resolve: { tokenValidator: TokenValidatorService },
              }
            ]
          },
          {
            path: 'reports',
            canActivate: [AuthGuardService, PermissionGuardService],
            component: ReportsComponent,
            resolve: { tokenValidator: TokenValidatorService },
            data: {
              permissions: [
                permissions.generateReports,
                permissions.management,
              ]
            },
            children: [
              {
                path: '',
                redirectTo: 'working-hours',
                pathMatch: 'full',
                resolve: { tokenValidator: TokenValidatorService },
              },
              {
                path: 'working-hours',
                component: WorkingHoursReportComponent,
                resolve: { tokenValidator: TokenValidatorService },
              },
            ],
          },
          {
            path: 'employees',
            canActivate: [AuthGuardService, PermissionGuardService],
            component: EmployeesComponent,
            data: {
              permissions: [
                permissions.editResume,
                permissions.assignRoles,
                permissions.userStatus,
                permissions.companyMembers,
              ],
            },
            resolve: { tokenValidator: TokenValidatorService },
            children: [
              {
                path: '',
                component: EmployeesListComponent,
                resolve: { tokenValidator: TokenValidatorService },
              },
              {
                path: ':employeeId/edit',
                canActivate: [PermissionGuardService],
                component: EditEmployeeComponent,
                data: {
                  permissions: [
                    permissions.editResume,
                    permissions.assignRoles,
                    permissions.companyMembers,
                  ]
                },
                resolve: { tokenValidator: TokenValidatorService },
              },
            ],
          },
          {
            path: 'databases',
            canActivate: [AuthGuardService],
            component: DatabasesComponent,
            resolve: { tokenValidator: TokenValidatorService },
            data: {
              permissions: [
                permissions.databaseItems,
                permissions.management,
              ],
            },
            children: [
              {
                path: '',
                component: DatabasesGeneralComponent,
                resolve: { tokenValidator: TokenValidatorService },
              }
            ]
          },
          {
            path: 'calendar',
            component: CalendarComponent,
            canActivate: [],
            resolve: { tokenValidator: TokenValidatorService },
            data: {
              permissions: [],
            },
            children: [
              {
                path: '',
                component: CalendarGeneralComponent,
                resolve: { tokenValidator: TokenValidatorService },
              }
            ]
          },
        ],
      },
    ],
  },
  // login/sign/reset-password up routes
  {
    path: 'login',
    canActivate: [LoginGuardService],
    component: LoginComponent,
  },
  {
    path: 'reset-password',
    children: [
      {
        path: '',
        component: ResetPasswordComponent,
      },
      {
        path: ':hash',
        component: SetNewPasswordComponent,
      },
    ],
  },
  {
    path: 'signup',
    component: SignUpComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
