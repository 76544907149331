import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

import { ProfileService } from 'src/app/services/profile.service';
import { UserExperienceService } from 'src/app/services/user-experience.service';

import { EmptyResponse } from 'src/app/models/responses/empty-response.model';

import moment from 'moment';

@Component({
  selector: 'app-experiences-add',
  templateUrl: './experiences-add.component.html',
  styleUrls: ['./experiences-add.component.scss'],
  providers: [UserExperienceService],
})
export class ExperiencesAddComponent implements OnInit {

  @Input() userId: number;

  public showAlert: boolean = false;
  public errorMessage: string;
  public close: boolean = false;
   /**
   * the suggestions input component needs to change
   * a bit depending if it is used for location suggestions
   * or not, as the former requires requests to a third-party
   * API
   */
  public isLocationsInput: boolean = true;
  public experienceForm: FormGroup;

  public userIdControl: AbstractControl;
  public positionControl: AbstractControl;
  public companyControl: AbstractControl;
  public locationControl: AbstractControl;
  public fromDateControl: AbstractControl;
  public toDateControl: AbstractControl;
  public responsibilityArrayControl: AbstractControl;

  constructor(
    public userExperienceService: UserExperienceService,
    private profileService: ProfileService
  ) {}

  /**
   * ngOnInit hook
   * set up the component's data before
   * any rendering
   * @return {void}
   */
  ngOnInit(): void {
    this.experienceForm = this.userExperienceService.setUpForm(this.userId);

    this.userIdControl = this.experienceForm.get('userId');
    this.positionControl = this.experienceForm.get('position');
    this.companyControl = this.experienceForm.get('company');
    this.locationControl = this.experienceForm.get('location');
    this.fromDateControl = this.experienceForm.get('fromDate');
    this.toDateControl = this.experienceForm.get('toDate');
    this.responsibilityArrayControl = this.experienceForm.get('responsibilities');
  }

  /**
   * onSubmit method
   * handles the logic after the user
   * submits the data
   * @return {void}
   */
  public onSubmit(): void {
    // get the values from the form
    const experienceData = this.experienceForm.value;

    // get rid of empty values in responsibilities
    experienceData.responsibilities = experienceData.responsibilities.filter(responsibility => responsibility);

    // format dates to what the back end expects
    experienceData.fromDate = moment(experienceData.fromDate, ['MM/YYYY']).format('YYYY-MM');

    experienceData.toDate = moment(experienceData.toDate, ['MM/YYYY']).format('YYYY-MM');

    // create the experience in the database
    this.profileService
      .addExperience(experienceData)
      .subscribe((success) => {
        // send the success object to define that the experience was added
        this.goToExperiencesView(success);
      }, (error) => {
        // show an alert with an error
        this.showAlert = true;
        this.errorMessage = 'Something went wrong. Please, try again later.';
      }
    );
  }

  /**
   * goToExperiencesView method
   * goes to the experiences view
   * @param {EmptyResponse} experienceAdded - optional success object
   * @return {void}
   */
  public goToExperiencesView(experienceAdded?: EmptyResponse): void {
    experienceAdded ?
      this.profileService.experienceAdded.next() :
      this.profileService.displayExperiencesView.next();
  }

  /**
   * emitClick
   * uses the flag to close the date picker calendar and the suggestions list
   * @param event {boolean} flag
   */
  public emitClick(event): void {
    this.close = event;
  }
}
