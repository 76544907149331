import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TimesheetResponse } from "src/app/models/timesheet-response.model";
import { Timesheet } from "../models/timesheet.model";
import { UpdateTimesheetResponse } from "src/app/models/update-timesheet-response.model";
import { SelectorOption } from 'src/app/models/select/selector-option.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { timesheetSelectorsErrors } from 'src/app/config/error-messages';
import { AutofillTimesheetResponse } from "src/app/models/autofill-timesheet-response.model";
import { EmptyResponse } from '../models/projects/empty-response.model';

@Injectable()
export class TimesheetService {
  constructor(private http: HttpClient) {}

  // filter options
  public statusOptions: SelectorOption[] = [
    // default
    {
      id: null,
      name: 'All',
    },
    {
      id: null,
      name: 'Pending'
    },
    {
      id: null,
      name: 'Updated'
    }
  ];
  public periodOptions: SelectorOption[] = [
    // default
    {
      id: null,
      name: 'Per period',
    },
    {
      id: null,
      name: 'Per week',
    },
    {
      id: null,
      name: 'Per month'
    }
  ];

  // form controls
  public statusControl: FormControl;
  public periodControl: FormControl;

  // form validators
  public statusValidators: Validators[];
  public periodValidators: Validators[];

  // errors
  public statusErrors;
  public periodErrors;

  // form group
  public getTimesheetsForm: FormGroup;

  /**
   * getUser method
   * it gets the profile´s user information
   * @param {number} id id from user
   * @return {Observable<ProfileResponse>} observable from response
   */
  public getTimesheet(
    id: number,
    fromDate: string,
    toDate: string
  ): Observable<TimesheetResponse> {
    return this.http
      .get<TimesheetResponse>(
        `${environment.apiUrl}/users/${id}/timesheet?fromDate=${fromDate}&toDate=${toDate}`
      )
      .pipe(
        catchError(error => {
          return of(error);
        })
      );
  }

  /**
   * updateTimesheet method
   * updates a timesheet
   * @param {number} id id of the user
   * @param {Timesheet} timesheet timesheet updated
   */
  public updateTimesheet(
    id: number,
    timesheet: Timesheet
  ): Observable<UpdateTimesheetResponse> {
    return this.http
      .post<UpdateTimesheetResponse>(
        `${environment.apiUrl}/users/${id}/timesheet`,
        timesheet
      ).pipe(
        catchError(error => {
          return of(error);
        })
      );
  }

  /**
   * setUpForm method
   * sets up the form that filters the timesheets
   * @returns {void}
   */
  public setUpForm(): void {
    // set the values of the form controls and group
    this.statusControl = new FormControl('', []);
    this.periodControl = new FormControl('', []);

    this.statusValidators = [];
    this.periodValidators = [];

    this.statusErrors = timesheetSelectorsErrors.status;
    this.periodErrors = timesheetSelectorsErrors.period;

    this.getTimesheetsForm = new FormGroup({
      status: this.statusControl,
      period: this.periodControl,
    });
  }
  
  /**
   * autofillTimesheet method
   * @param {number} id user id
   * @param {string} date date to update timesheet
   */
  public autofillTimesheet(id: number, date: string): Observable<AutofillTimesheetResponse> { 
    return this.http
    .post<AutofillTimesheetResponse>(
      `${environment.apiUrl}/users/${id}/autofillTimesheet`,
      {
        "date": date
      }
    ).pipe(
      catchError(error => {
        return of(error.error);
      })
    );
  }

  /**
   * deleteTimesheetDay method
   * delete all timesheets of the an spescific day
   * @param {string} date date to want delete timesheets
   * @returns {Observable<EmptyResponse>}
   */
  public deleteTimesheetDay(date: string): Observable<EmptyResponse> {
    const id = +localStorage.getItem('id');
    return this.http.delete<EmptyResponse>(
      `${environment.apiUrl}/users/${id}/timesheet?date=${date}`
    ).pipe(
      catchError((error) => {
        return of(error);
      })
    )
  }
}
