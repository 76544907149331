import { Pipe, PipeTransform } from '@angular/core';
import { CitiesResponse } from 'src/app/models/responses/cities-response.model';

@Pipe({
  name: 'formatLocations'
})
export class FormatLocationsPipe implements PipeTransform {

  transform(locations: CitiesResponse[]): string[] {
    return locations.map(location => {
      return `${location.name}, ${location.country_name}`;
    });
  }
  
}
