import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  providers: [ProjectsService],
})
export class ProjectsComponent implements OnInit {

  // default view
  public listView: boolean = true;
  public createView: boolean = false;
  public detailsView: boolean = false;
  public editView: boolean = false;

  // id of the project selected in the list
  public projectId: number;

  constructor() { }

  ngOnInit() {
  }

  /**
   * goToCreateView method
   * sets the createView flag to true
   * and sets the others to false
   * @return {void}
   */
  public goToCreateView(): void {
    this.createView = true;
    this.listView = false;
    this.detailsView = false;
    this.editView = false;
  }

  /**
   * goToListView method
   * sets the listView flag to true
   * and sets the others to false
   * @return {void}
   */
  public goToListView(): void {
    this.listView = true;
    this.createView = false;
    this.detailsView = false;
    this.editView = false;
  }

  /**
   * goToProjectView method
   * sets the createView flag to true
   * and sets the others to false
   * @return {void}
   */
  public goToProjectView(): void {
    this.detailsView = true;
    this.listView = false;
    this.createView = false;
    this.editView = false;
  }

  /**
   * goToEditView method
   * sets the editView flag to true
   * and sets the others to false
   * @return {void}
   */
  public goToEditView() {
    this.detailsView = false;
    this.listView = false;
    this.createView = false;
    this.editView = true;
  }

  /**
   * selectProject method
   * sets the id of the project to display
   * @return {void}
   */
  public selectProject(id: number): void {
    this.projectId = id;
    this.goToProjectView();
  }
}
