import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewChecked,
  OnDestroy
} from '@angular/core';
import { Role } from 'src/app/models/roles/role.model';
import { RolesService } from 'src/app/services/roles.service';
import { Subscription } from 'rxjs';
import { rolesMessages } from 'src/app/config/roles-messages';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.scss']
})
export class RolesListComponent implements OnInit, AfterViewChecked, OnDestroy {

  constructor(
    private rolesService: RolesService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
  ) { }

  public environment = environment;
  public rolesLoaded: boolean = false;
  public hasScrollbar: boolean;
  public roles: Role[] = [];
  public usersAssigned: string[] = [];
  public textButton: string[] = [];
  private newItems: Role[] = [];
  private page: number = 1;
  private userId: number;
  private getRolesSubscription: Subscription;

  /**
   * ngOnInit hook
   * @description Sets the userId by first checking if the data
   * stored in localStorage is a number
   */
  ngOnInit() {
    const userId = +localStorage.getItem('id');

    // validate if the id is a number
    this.userId = !isNaN(userId) ? userId : 0;
  }

  /**
   * ngAfterViewChecked hook
   * @description Check if the items have been rendered and run the change detection
   * system to tell the pagination directive to run again if necessary
   */
  ngAfterViewChecked() {
    if (this.roles.length) {
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * ngOnDestroy hook
   * @description Unsubscribes from the observables
   */
  ngOnDestroy() {
    this.getRolesSubscription.unsubscribe();
  }

  /**
   * validateIfUserInRole method
   * @description Method that validate if the current user is involved
   * in some of the roles
   * @returns {void} void
   */
  private validateIfUserInRole(): void {
    this.newItems.forEach(role => {
      role.userInRole = role.users.includes(this.userId);
    });
  }

  /**
   * setRolesMessage method
   * @description Sets the property that will contain the message that shows how many users
   * are part of each role
   * @return {void} void
   */
  private setRolesMessage(): void {
    this.newItems.forEach(role => {
      if (role.users.length > 2 && role.userInRole) {
        role.usersInRoleMessage = rolesMessages.userAndMoreThanOneInRole(role.users.length);
      }

      if (role.users.length === 2 && role.userInRole) {
        role.usersInRoleMessage = rolesMessages.userAndOneMoreInRole;
      }

      if (role.users.length > 1 && !role.userInRole) {
        role.usersInRoleMessage = rolesMessages.moreThanOneInRole(role.users.length);
      }

      if (role.users.length === 1) {
        if (role.userInRole) {
          role.usersInRoleMessage = rolesMessages.userUniqueInRole;
        } else {
          role.usersInRoleMessage = rolesMessages.onlyOneInRole;
        }
      }

      if (!role.users.length) {
        role.usersInRoleMessage = rolesMessages.noPeopleInRole;
      }
    });
  }

  /**
   * setTextButton method
   * @description Sets the property that will contain the text that the button
   * in each role will have
   * @return {void} void
   */
  private setTextButton(): void {
    this.newItems.forEach((role) => {
      if (role.id === environment.managerIdRole) {
        role.textButton = 'ASSIGNMENT';
        role.name += ' (root)';
      } else {
        role.textButton = 'EDIT';
      }
      role.textButton = role.id ===  environment.managerIdRole ? 'ASSIGNMENT' : 'EDIT';
    });
  }

  /**
   * getRoles method
   * @description Method that uses the roles service to obtain the roles
   * (20 each time)
   * @returns {void} void
   */
  public getRoles(): void {
    this.getRolesSubscription = this.rolesService.getRoles(this.page)
      .subscribe((result) => {
        this.rolesLoaded = true;
        this.newItems = result.data.items;

        if (this.newItems.length) {
          this.roles.push(...this.newItems);

          // validate if user is part of the project
          this.validateIfUserInRole();

          // set the users messages for each project
          this.setRolesMessage();

          // set the text button for each project
          this.setTextButton();

          // Reorder the roles to have the manager role first
          this.managerRoleFirst();
          
          this.page += 1;
        }
      });
  }

  /**
   * goToEditRoles method
   * @description receives a role id to navigate to the edit roles view of an specific role
   * @param {number} id role id
   * @returns {void} void
   */
  public goToEditRoles(id: number): void {
    if (id !== environment.managerIdRole) {
      this.router.navigateByUrl(`manage/roles/edit/${id}`);
    }
  }

  /**
   * managerRoleFirst method
   * @description Change the roles order putting the manager role first
   * @returns {void} void
   */
  private managerRoleFirst() {
    const managerIndex = this.roles.findIndex((role) => {
      return role.id === 1;
    });

    const managerRole = this.roles.splice(managerIndex, 1);
    this.roles.unshift(...managerRole);
  }

}
