import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmptyResponse } from 'src/app/models/projects/empty-response.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientMembersService {

  constructor(private http: HttpClient) {}
  
  /**
   * toggleClientMember method
   * Change the status of an existing client member in an existing project
   * @param {number} projectID - Project ID of the project in which the member is working 
   * @param {number} memberID - member ID of the member that will be change his/her status
   * @returns {Observable<EmptyResponse>} endpoint empty response
   */
  public toggleClientMember(projectID: number, memberID: number): Observable<EmptyResponse> {
    return this.http.put<EmptyResponse>(
      `${environment.apiUrl}/projects/${projectID}/client-members/${memberID}/toggle`, {}
    );
  }
}
