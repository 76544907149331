import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user.model';

@Pipe({
  name: 'filterUser'
})
export class FilterUserPipe implements PipeTransform {

  transform(users: User[], filter: string): User[] {
    let filtered: User[] = [];

    if (!filter) {
      return [];
    }

    filtered = users.filter(user => {

      const username = `${user.firstName} ${user.lastName}`;

      if (
        username
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .search(
          filter
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
        ) < 0
      ) {
        return false;
      }

      return true;
    });

    // sort the results alphabetically
    filtered.sort((firstUser: User, secondUser: User): number => {
      if (
        firstUser
          .firstName
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, "") <
        secondUser
          .firstName
          .toLowerCase()
      ) {
        return -1;
      }

      return 1;
    });

    return filtered;
  }

}
