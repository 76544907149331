import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from 'src/app/app.component';

/**
 * Main Components
 * These are the components that will render the entire application.
 * LoginComponent, ResetPasswordComponent and the soon to be added
 * SignUpComponent will be in charge only of rendering their own respective views.
 * MainComponent is in charge of rendering everything else.
 */
import { MainComponent } from 'src/app/components/main/main.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { SignUpComponent } from 'src/app/components/sign-up/sign-up.component';

// sign up components children
import { SignUpViewOneComponent } from 'src/app/components/sign-up/sign-up-view-one/sign-up-view-one.component';
import { SignUpViewTwoComponent } from 'src/app/components/sign-up/sign-up-view-two/sign-up-view-two.component';
import { SignUpViewThreeComponent } from 'src/app/components/sign-up/sign-up-view-three/sign-up-view-three.component';

/**
 * Login Components
 * Components to be seen in the Login part of the application, such as
 * FooterLogin, Login Form, Forgot Password and Reset Password
 */
import { FooterLoginComponent } from 'src/app/components/login/footer-login/footer-login.component';
import { LoginFormComponent } from 'src/app/components/login/login-form/login-form.component';
import { ResetPasswordComponent } from 'src/app/components/reset-password/reset-password.component';

/**
 * ResetPassword Components
 * Component to be seen in the Reset Password part of the application, such as
 * Set New Password
 */
import { ForgotPasswordComponent } from 'src/app/components/reset-password/forgot-password/forgot-password.component';
import { SetNewPasswordComponent } from 'src/app/components/reset-password/set-new-password/set-new-password.component';


/**
 * Dashboard Components
 * Components to be seen in the Dashboard part of the application, such as
 * Profile, Team, My projects, My items and My days off
 */
import { DashboardComponent } from 'src/app/components/main/dashboard/dashboard.component';
// Dashboard/Profile Components
import { ProfileComponent } from 'src/app/components/main/dashboard/profile/profile.component';
import { UserDescriptionComponent } from 'src/app/shared/components/user-resume/user-description/user-description.component';
import { DescriptionDisplayComponent } from 'src/app/shared/components/user-resume/user-description/user-description-display/description-display.component';
import { DescriptionEditComponent } from 'src/app/shared/components/user-resume/user-description/user-description-edit/description-edit.component';
import { SkillsComponent } from 'src/app/shared/components/user-resume/skills/skills.component';
import { UserExperiencesComponent } from 'src/app/shared/components/user-resume/user-experiences/user-experiences.component';
import { ExperiencesDisplayComponent } from 'src/app/shared/components/user-resume/user-experiences/experiences-display/experiences-display.component';
import { ExperiencesEditComponent } from 'src/app/shared/components/user-resume/user-experiences/experiences-edit/experiences-edit.component';
import { EditPersonalInfoComponent } from 'src/app/shared/components/user-resume/edit-personal-info/edit-personal-info.component';
import { ExperiencesAddComponent } from 'src/app/shared/components/user-resume/user-experiences/experiences-add/experiences-add.component';
// Dashboard/Team Components
import { TeamListComponent } from 'src/app/components/main/dashboard/team-list/team-list.component';
import { TeamComponent } from 'src/app/components/main/dashboard/team/team.component';
import { TeammateComponent } from 'src/app/components/main/dashboard/teammate/teammate.component';
import { TeammateHeaderComponent } from 'src/app/components/main/dashboard/teammate/teammate-header/teammate-header.component';
// Dashboard/MyProjects Components
import { MyProjectsComponent } from 'src/app/components/main/dashboard/my-projects/my-projects.component';
import { MyProjectsHeaderComponent } from 'src/app/components/main/dashboard/my-projects/my-projects-header/my-projects-header.component';
import { MyProjectsDetailsComponent } from 'src/app/components/main/dashboard/my-projects/my-projects-details/my-projects-details.component';

/**
 * Check In Components
 * Components to be seen in the Check In part of the application, such
 * Check In, By Employee, Days off Requests
 */
import { CheckInComponent } from 'src/app/components/main/check-in/check-in.component';
import { EmployeesCheckInComponent } from 'src/app/components/main/check-in/employees-check-in/employees-check-in.component';
import { EmployeeCheckInComponent } from 'src/app/components/main/check-in/employee-check-in/employee-check-in.component';
import { EmployeeCheckInHeaderComponent } from 'src/app/components/main/check-in/employee-check-in/employee-check-in-header/employee-check-in-header.component';
import { CheckInMainComponent } from 'src/app/components/main/check-in/check-in-main/check-in-main.component';

/**
 * Manage Components
 * Components to be seen in the Manage part of the application, such as
 * Employees, Projects, Roles, Reports, Calendar, Databases and Alerts
 */
import { ManageComponent } from 'src/app/components/main/manage/manage.component';
// Manage/Project Components
import { ProjectsComponent } from 'src/app/components/main/manage/projects/projects.component';
import { ProjectMembersHeaderComponent } from 'src/app/components/main/manage/projects/project-members/project-members-header/project-members-header.component';
import { ProjectMembersComponent } from 'src/app/components/main/manage/projects/project-members/project-members.component';
import { ProjectsListHeaderComponent } from 'src/app/components/main/manage/projects/projects-list/projects-list-header/projects-list-header.component';
import { ProjectsListComponent } from 'src/app/components/main/manage/projects/projects-list/projects-list.component';
import { CreateProjectsHeaderComponent } from 'src/app/components/main/manage/projects/create-project/create-project-header/create-projects-header.component';
import { CreateProjectComponent } from 'src/app/components/main/manage/projects/create-project/create-project.component';
import { ProjectComponent } from 'src/app/components/main/manage/projects/project/project.component';
import { ManageProjectsFormComponent } from 'src/app/components/main/manage/projects/manage-projects-form/manage-projects-form.component';
import { EditProjectComponent } from 'src/app/components/main/manage/projects/edit-project/edit-project.component';
import { EditProjectHeaderComponent } from 'src/app/components/main/manage/projects/edit-project/edit-project-header/edit-project-header.component';
import { ProjectDetailsComponent } from 'src/app/components/main/manage/projects/project-details/project-details.component';
import { AddTeamMemberComponent } from 'src/app/components/main/manage/projects/add-team-member/add-team-member.component';
import { AddTeamMemberHeaderComponent } from 'src/app/components/main/manage/projects/add-team-member/add-team-member-header/add-team-member-header.component';
import { EditTeamMemberComponent } from 'src/app/components/main/manage/projects/edit-team-member/edit-team-member.component';
import { EditTeamMemberHeaderComponent } from 'src/app/components/main/manage/projects/edit-team-member/edit-team-member-header/edit-team-member-header.component';
import { AddClientMemberComponent } from 'src/app/components/main/manage/projects/add-client-member/add-client-member.component';
import { TeamListHeaderComponent } from 'src/app/components/main/dashboard/team-list/team-list-header/team-list-header.component';
import { EditClientMemberComponent } from 'src/app/components/main/manage/projects/edit-client-member/edit-client-member.component';
// Manage/Roles Components
import { RolesComponent } from 'src/app/components/main/manage/roles/roles.component';
import { ManageRolesComponent } from 'src/app/components/main/manage/roles/manage-roles/manage-roles.component';
import { ManageRolesHeaderComponent } from 'src/app/components/main/manage/roles/manage-roles/manage-roles-header/manage-roles-header.component';
import { CreateRolesComponent } from 'src/app/components/main/manage/roles/create-roles/create-roles.component';
import { CreateRolesHeaderComponent } from 'src/app/components/main/manage/roles/create-roles/create-roles-header/create-roles-header.component';
import { RolesListComponent } from 'src/app/components/main/manage/roles/roles-list/roles-list.component';
// Manage/Reports components
import { ReportsComponent } from 'src/app/components/main/manage/reports/reports.component';
import { WorkingHoursReportComponent } from 'src/app/components/main/manage/reports/working-hours-report/working-hours-report.component';
import { WorkingHoursReportPreviewComponent } from 'src/app/components/main/manage/reports/working-hours-report/working-hours-report-preview/working-hours-report-preview.component';
// Manage/Employees components
import { EmployeesComponent } from 'src/app/components/main/manage/employees/employees.component';
import { EmployeesListComponent } from 'src/app/components/main/manage/employees/employees-list/employees-list.component';
import { EditEmployeeHeaderComponent } from 'src/app/components/main/manage/employees/edit-employee/edit-employee-header/edit-employee-header.component';
import { EditEmployeeComponent } from 'src/app/components/main/manage/employees/edit-employee/edit-employee.component';
import { EditRolesComponent } from 'src/app/components/main/manage/roles/edit-roles/edit-roles.component';
import { EditRolesHeaderComponent } from 'src/app/components/main/manage/roles/edit-roles/edit-roles-header/edit-roles-header.component';

// Managae/Databases components
import { DatabasesComponent } from 'src/app/components/main/manage/databases/databases.component';
import { DatabasesGeneralComponent } from 'src/app/components/main/manage/databases/databases-general/databases-general.component';
import { DatabasesCompanyComponent } from 'src/app/components/main/manage/databases/databases-company/databases-company.component';
import { RegionsComponent } from 'src/app/components/main/manage/databases/databases-company/regions/regions.component';
import { AreasComponent } from 'src/app/components/main/manage/databases/databases-company/areas/areas.component'
import { DatabasesEmployeesComponent } from 'src/app/components/main/manage/databases/databases-employees/databases-employees.component';
import { SenioritiesComponent } from 'src/app/components/main/manage/databases/databases-employees/seniorities/seniorities.component';
import { ClientsComponent } from 'src/app/components/main/manage/databases/databases-company/clients/clients.component';

// Manage/calendar components
import { CalendarComponent } from 'src/app/components/main/manage/calendar/calendar.component';
import { CalendarGeneralComponent } from 'src/app/components/main/manage/calendar/calendar-general/calendar-general.component';

/**
 * Timesheet components
 */
import { TimesheetComponent } from 'src/app/components/main/timesheet/timesheet.component';
import { UpdateComponent } from 'src/app/components/main/timesheet/update/update.component';
import { UpdateHeaderComponent } from 'src/app/components/main/timesheet/update/update-header/update-header.component';
import { TimesheetEditComponent } from 'src/app/components/main/timesheet/timesheet-edit/timesheet-edit.component';
import { TimesheetDisplayComponent } from 'src/app/components/main/timesheet/timesheet-display/timesheet-display.component';
import { EditTimesheeetDescriptionComponent } from 'src/app/components/main/timesheet/timesheet-edit/edit-timesheeet-description/edit-timesheeet-description.component';

/**
 * Shared Components
 * Components that are meant to be used throughout the application
 * representing common parts like inputs, selectors, checkboxes,
 * autocompletion input, etc.
 */
import { NavbarComponent } from 'src/app/components/main/navbar/navbar.component';
import { PopUpMessageComponent } from 'src/app/shared/components/pop-up-message/pop-up-message.component';
import { CheckboxComponent } from 'src/app/shared/components/checkbox/checkbox.component';
import { SelectorComponent } from 'src/app/shared/components/selector/selector.component';
import { NestedSelectorComponent } from 'src/app/shared/components/nested-selector/nested-selector.component';
import { SuggestionsComponent } from 'src/app/shared/components/suggestions/suggestions.component';
import { UserSuggestionsComponent } from 'src/app/shared/components/user-suggestions/user-suggestions.component';
import { InputComponent } from 'src/app/shared/components/input/input.component';
import { ImageInputComponent } from 'src/app/shared/components/image-input/image-input.component';
import { ArrayInputComponent } from 'src/app/shared/components/array-input/array-input.component';
import { TextAreaComponent } from 'src/app/shared/components/text-area/text-area.component';
import { MonthInputComponent } from 'src/app/shared/components/month-input/month-input.component';
import { DateInputComponent } from 'src/app/shared/components/date-input/date-input.component';
import { SearchInputComponent } from 'src/app/shared/components/search-input/search-input.component';
import { PasswordInputComponent } from 'src/app/shared/components/password-input/password-input.component';
import { TopHeaderComponent } from 'src/app/shared/components/top-header/top-header.component';
import { TimerComponent } from 'src/app/shared/components/timer/timer.component';
import { UserCardComponent } from 'src/app/shared/components/user-card/user-card.component';
import { TabsComponent } from 'src/app/shared/components/tabs/tabs.component';
import { NoInfoComponent } from 'src/app/shared/components/no-info/no-info.component';
import { HiddenInputComponent } from 'src/app/shared/components/hidden-input/hidden-input.component';
import { EmployeesSharedListComponent } from 'src/app/shared/components/employees-shared-list/employees-shared-list.component';
import { EmployeesSharedListHeaderComponent } from 'src/app/shared/components/employees-shared-list/employees-shared-list-header/employees-shared-list-header.component';
import { ArrayEditorComponent } from 'src/app/shared/components/array-editor/array-editor.component';
import { DatabaseSectionHeaderComponent } from 'src/app/shared/components/database-section-header/database-section-header.component';
import { DatabaseInputArrayComponent } from 'src/app/shared/components/database-input-array/database-input-array.component';
import { DatabaseFormArrayComponent } from 'src/app/shared/components/database-form-array/database-form-array.component';
import { WelcomeModalTemplate } from 'src/app/shared/components/welcome-modal-template/welcome-modal-template.component';
import { BlockUIModule } from 'ng-block-ui';
import { ClientsSuggestionsComponent } from 'src/app/shared/components/clients-suggestions/clients-suggestions.component';
import { UserFiltersComponent } from 'src/app/shared/components/user-filters/user-filters.component';

/**
 * Directives
 * Attribute directives that change the appearance or behavior
 * of an element, component, or other directive.
 */
import { PaginateDirective } from 'src/app/directives/paginate.directive';
import { DinamicInputsDirective } from 'src/app/directives/dinamic-inputs.directive';
import { DatabaseAutoFocusDirective } from 'src/app/directives/database-auto-focus.directive';
import { ImageOnErrorDirective } from 'src/app/directives/image-on-error.directive';

/**
 * Pipes
 * Simple functions that transform data into a desirable way
 * to present to the users
 */
import { JoinPipe } from 'src/app/pipes/join.pipe';
import { FilterUserPipe } from 'src/app/pipes/filter-user.pipe';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { ImagePipe } from 'src/app/pipes/image.pipe';
import { UpdateImagePipe } from 'src/app/pipes/update-image.pipe';
import { CapitalizePipe } from 'src/app/pipes/capitalize.pipe';
import { TitleCasePipe } from '@angular/common';
import { FormatLocationsPipe } from 'src/app/pipes/format-locations.pipe';
import { FilterClientsPipe } from 'src/app/pipes/filter-clients.pipe';

/**
 * Providers
 * Classes that intercept HTTP requests and responses to modify
 * them before their intended purpose
 */
import { HeaderInterceptor } from 'src/app/providers/header-interceptor.interceptor';
import { ResponseInterceptor } from 'src/app/providers/response-interceptor.interceptor';
import { WorkingHoursCompleteReportPreviewComponent } from 'src/app/components/main/manage/reports/working-hours-report/working-hours-complete-report-preview/working-hours-complete-report-preview.component';
import { TokenValidatorService } from 'src/app/services/token-validator.service';

//modules
import { EditorModule } from '@tinymce/tinymce-angular';
import { MonthComponent } from 'src/app/components/main/manage/calendar/month/month.component';

@NgModule({
  declarations: [
    AppComponent,
    CreateProjectComponent,
    DashboardComponent,
    NavbarComponent,
    LoginComponent,
    PopUpMessageComponent,
    CheckboxComponent,
    SelectorComponent,
    SuggestionsComponent,
    UserSuggestionsComponent,
    AddTeamMemberComponent,
    ProjectsListComponent,
    ProjectDetailsComponent,
    FilterUserPipe,
    JoinPipe,
    FilterPipe,
    FormatLocationsPipe,
    PaginateDirective,
    ManageComponent,
    InputComponent,
    ManageComponent,
    AddTeamMemberHeaderComponent,
    CreateProjectsHeaderComponent,
    ManageProjectsFormComponent,
    PaginateDirective,
    ManageComponent,
    InputComponent,
    ImageInputComponent,
    MainComponent,
    ProjectsComponent,
    ProjectsListHeaderComponent,
    ProjectMembersComponent,
    ProjectMembersHeaderComponent,
    ProfileComponent,
    UserDescriptionComponent,
    DescriptionDisplayComponent,
    DescriptionEditComponent,
    UserExperiencesComponent,
    ExperiencesDisplayComponent,
    ExperiencesEditComponent,
    ExperiencesAddComponent,
    ArrayInputComponent,
    ForgotPasswordComponent,
    LoginFormComponent,
    FooterLoginComponent,
    ResetPasswordComponent,
    SetNewPasswordComponent,
    TeamComponent,
    TeamListHeaderComponent,
    TeamListComponent,
    AddClientMemberComponent,
    EditTeamMemberComponent,
    SignUpComponent,
    TextAreaComponent,
    DinamicInputsDirective,
    SignUpViewOneComponent,
    SignUpViewTwoComponent,
    SignUpViewThreeComponent,
    EditProjectComponent,
    EditProjectHeaderComponent,
    ImagePipe,
    UpdateImagePipe,
    MonthInputComponent,
    DateInputComponent,
    SearchInputComponent,
    TimerComponent,
    SkillsComponent,
    EditPersonalInfoComponent,
    EditClientMemberComponent,
    EditTeamMemberHeaderComponent,
    MyProjectsComponent,
    MyProjectsHeaderComponent,
    UserCardComponent,
    TeammateComponent,
    TeammateHeaderComponent,
    CapitalizePipe,
    TimesheetComponent,
    UpdateComponent,
    UpdateHeaderComponent,
    TimesheetEditComponent,
    TimesheetDisplayComponent,
    MyProjectsDetailsComponent,
    PasswordInputComponent,
    ProjectComponent,
    EditTimesheeetDescriptionComponent,
    ManageRolesComponent,
    ManageRolesHeaderComponent,
    CreateRolesComponent,
    CreateRolesHeaderComponent,
    RolesComponent,
    RolesListComponent,
    TopHeaderComponent,
    ReportsComponent,
    WorkingHoursReportComponent,
    WorkingHoursReportPreviewComponent,
    NestedSelectorComponent,
    EmployeesComponent,
    EmployeesListComponent,
    EditEmployeeComponent,
    EditEmployeeHeaderComponent,
    TabsComponent,
    EditRolesComponent,
    EditRolesHeaderComponent,
    NoInfoComponent,
    CheckInComponent,
    EmployeeCheckInComponent,
    EmployeeCheckInHeaderComponent,
    CheckInMainComponent,
    HiddenInputComponent,
    EmployeesCheckInComponent,
    EmployeesSharedListComponent,
    EmployeesSharedListHeaderComponent,
    WorkingHoursCompleteReportPreviewComponent,
    ArrayEditorComponent,
    DatabasesComponent,
    DatabasesGeneralComponent,
    DatabasesCompanyComponent,
    RegionsComponent,
    DatabaseSectionHeaderComponent,
    DatabaseInputArrayComponent,
    AreasComponent,
    DatabaseFormArrayComponent,
    DatabaseAutoFocusDirective,
    DatabasesEmployeesComponent,
    SenioritiesComponent,
    WelcomeModalTemplate,
    ImageOnErrorDirective,
    CalendarComponent,
    CalendarGeneralComponent,
    MonthComponent,
    ClientsComponent,
    FormatLocationsPipe,
    ClientsSuggestionsComponent,
    FilterClientsPipe,
    UserFiltersComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    EditorModule,
    BlockUIModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    TokenValidatorService,
    TitleCasePipe,
    FilterClientsPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
